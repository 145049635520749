import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware'


export const useCustomizationStore = create(
    persist(
        ( set ) => ({
           data: [
            {
                rowId: 1151515,
                items: [
                    {
                        type: 'chart',
                        id: 54235,
                        width: 500,
                        height: 400
                    },
                    {
                        type: 'chart',
                        id: 542355,
                        width: 500,
                        height: 400
                    },
                ],
            },
            {
                rowId: 435354,
                items: [
                    {
                        type: 'chart',
                        id: 5423512,
                        width: 500,
                        height: 400
                    }
                ]
            },
            {
                rowId: 64564,
                items: [
                    {
                        type: 'table',
                        id: 5544235,
                        width: 900,
                        height: 800
                    }
                ]
            },
        ],
           setData: elem => set({data: elem})

        }),
        {
            name: 'customization-storage',
            // storage: createJSONStorage(() => localStorage)
        }
    )
);


export default useCustomizationStore;
