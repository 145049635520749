import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthorizationPage } from './pages/AuthorizationPage/AuthorizationPage';
import ServiceAuth from './pages/ServiceAuth/';
import AcceptMail from './components/AcceptMail/';
import { AuthCheck } from './pages/AuthCheck/AuthCheck';
import { RavshanHttps } from './fetchUrls';
import Cookies from 'universal-cookie';
import './index.scss'
const cookies = new Cookies();
const auth = cookies.get('auth')


const root = ReactDOM.createRoot(document.getElementById('root'));

document.addEventListener('DOMContentLoaded', e => {
  if (auth) {
    const sessionId = auth + new Date().getTime() + `${Math.round(Math.random(0, 1) * 1000)}`
    sessionStorage.setItem('session_id', sessionId)
    localStorage.removeItem('cook_id')
  } else {
    const infinite_id = 'infinit-' + new Date().getTime() + `-${Math.round(Math.random(0, 1) * 1000)}`
    localStorage.setItem('infinit_id', infinite_id)
    localStorage.setItem('cook_id', new Date().getTime() + '-' + (Math.random(0, 1) * 1000 * (Math.random(0, 1) * 100)) + `${Math.random(0, 1) * 1000 * (Math.random(0, 1) * 100)}`)
  }
})



document.addEventListener('click', e => {
  if (e.target.tagName === 'A' & !e.target.href?.match('#')) {
    if (!e.target?.getAttribute('download')) {
      e.preventDefault()
    }
    const url = `${RavshanHttps}/api/track/track`

    const timezoneOffset = new Date().getTimezoneOffset() / 60
    const startDate = new Date()
    const GMTdate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours() - timezoneOffset, startDate.getMinutes()).toUTCString()
    const body = JSON.stringify({
      client_info: cookies.get('client_info'),
      date: GMTdate,
      url: e.target?.href,
    })

    const cookie = { 'session-id': auth ? `${sessionStorage.getItem('session_id')}` : `${localStorage.getItem('infinit_id')}` }


    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': auth ? `Bearer ${auth}` : localStorage.getItem('cook_id'),

      ...cookie
    }
    fetch(url, { body, method: 'POST', headers })
      .then(res => res.json())
      .catch(err => console.error(err))
  }
})


const queryParams = new URLSearchParams(window?.location?.search);
const pricePlan = queryParams.get('ref-pricePlan');
const redirect_page = queryParams.get('ref-page');

if(pricePlan) {
  localStorage.setItem('ref-pricePlan', pricePlan)
}
if(redirect_page) {
  localStorage.setItem('ref-page', redirect_page)
}

root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/auth/*' element={< AuthorizationPage />} />

      <Route path='/accept-mail/:id' element={<AcceptMail />} />
      <Route path='/auth-check/:id' element={<AuthCheck />} />

      <Route path='/service_auth/*' element={<ServiceAuth />} />
      <Route path='/*' element={< App />} />
    </Routes>
  </BrowserRouter>
);

