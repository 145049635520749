import React, { useRef, useState } from 'react';
import { MoonLoader } from 'react-spinners';
import customHeaders, { getSpace } from '../../../common/headers';
import useGeneralStore from '../../../store/general';
import './FilePromotionModal.scss';
import ModalTableBox from './ModalTableBox/ModalTableBox';
import { ButtonBasic } from '@lk-gtcom/ecomlab-components';

const FilePromotionModal = ({
  onClose,
  isLoading,
  onUploadFile,
  onAddProducts,
  onRemoveFile,
  fileId,
  onAddProductToAction,
  setCheckedProducts,
  setIsPromoModalOpen,
}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [file, setFile] = useState(null);
  const [isDragOver, setIsDragOver] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [columnSize, setColumnSize] = useState([]);
  const [filters, setFilters] = useState([]);
  const [sort, setSort] = useState({});
  const paginatorRef = useRef();

  const [modalTableBox, setModalTableBox] = useState(false);

  const fetchMarketingActionFileProductTable = () => {
    const url = `${'https://apps1.ecomru.ru:4450'}/api/v2/marketing/action/file/product/table`;

    const body = JSON.stringify({
      file_id: fileId,
      page: 1,
      limit: 10,
    });

    return fetch(url, {
      method: 'POST',
      headers,
      body,
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((error) => {
            throw new Error(
              `Fetching table data failed: ${error.detail || res.statusText}`
            );
          });
        }
        return res.json();
      })
      .then((json) => {
        if (json) setModalTableBox(true);
        const { total, labels, data, sort, filters, column_size } = json;
        if (labels) setTableHeaders(labels);
        if (data) setTableData(data);
        if (sort) setSort(sort);
        if (column_size) setColumnSize(column_size);
        if (filters) setFilters(filters);
        if (total) paginatorRef.current?.setTotal(total);
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      await handleUpload(selectedFile);
    }
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDragOver(false);
    if (e.dataTransfer.files.length) {
      const droppedFile = e.dataTransfer.files[0];
      setFile(droppedFile);
      await handleUpload(droppedFile);
    }
  };

  const handleUpload = async (fileToUpload) => {
    if (!fileToUpload) return;
    try {
      await onUploadFile(fileToUpload);
    } catch (err) {
      console.log(err);
    }
  };

  const handleRemoveFile = async () => {
    if (!fileId) return;

    try {
      await onRemoveFile(fileId);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div
      className="blackout"
      onClick={(e) => {
        e.preventDefault();
        handleRemoveFile();
        onClose();
      }}
    >
      <div className="modal_container" onClick={(e) => e.stopPropagation()}>
        {modalTableBox && (
          <ModalTableBox
            onClose={() => setModalTableBox(false)}
            tableProps={{
              fetchedData: tableData,
              headers: tableHeaders,
              columnSize,
              sort,
              filters,
              ref: paginatorRef,
            }}
            onAddProductToAction={onAddProductToAction}
            setCheckedProducts={setCheckedProducts}
            onAddProducts={onAddProducts}
            setIsPromoModalOpen={setIsPromoModalOpen}
          />
        )}
        <h2>Добавление товаров в Акцию из файла</h2>

        <div
          className={`drop_file_container ${isDragOver ? 'dragover' : ''}`}
          onDragOver={(e) => {
            e.preventDefault();
            setIsDragOver(true);
          }}
          onDragLeave={() => setIsDragOver(false)}
          onDrop={handleDrop}
          onClick={() => document.getElementById('fileInput').click()}
        >
          {file ? (
            <p>{file.name}</p>
          ) : (
            <p>Перетащите файл или нажмите для выбора</p>
          )}
        </div>
        <input
          type="file"
          id="fileInput"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
        <div
          className="loader-container"
          style={{
            height: '60px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {isLoading && (
            <MoonLoader color="#22C55E" speedMultiplier={0.5} size={50} />
          )}
        </div>
        <div className="action_container">
          {<a href='https://dl.ecomru.ru:9001/files/197/promotion_197.xlsx' download={true} style={{ color: 'blue' }}> <ButtonBasic
            width="auto"
            text={'Скачать шаблон файла'}
            size="32px"
            onClick={() => {
              console.log('wait method');
            }}
          /></a>}
          <ButtonBasic
            width="auto"
            text={'Посмотреть файл'}
            size="32px"
            onClick={() => {
              fetchMarketingActionFileProductTable();
            }}
            disabled={!file || isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default FilePromotionModal;
