import { useState, useEffect } from 'react';
import { useRef } from 'react';
import { DenisCrmHttps } from '../../fetchUrls';
import customHeaders, { getSpace } from '../../common/headers';
import { useNavigate, useParams } from 'react-router-dom';
import { useDataTable } from '../../common/hooks';
import useGeneralStore from '../../store/general';
import './CrmTableDetail.scss';

import {
  Tabs,
  TableBox,
  ButtonBasic,
  DropDownSelector,
} from '@lk-gtcom/ecomlab-components';
import { CrmModal } from '../CrmCreateTable/CrmModal';
import { logo } from '../../common/svg_img';
import { CrmModalDetail } from './CrmModalDetail';

const CrmTabs = [
  {
    label: 'Все',
    value: 'all',
  },
  {
    label: 'Активные',
    value: 'notifications',
  },
  {
    label: 'Неактивные',
    value: 'unactiv',
  },
];

const CrmTableDetail = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const navigate = useNavigate();
  const [tableList, setTableList] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [pageValue, setPageValue] = useState(null);
  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { loading, sort, filters } = tableProps;
  const { setLoading, setFetchedData, setHeaders, setSort, setFilters } =
    setTableFuncs;

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const { id } = useParams();

  const contextMenuArr = [
    {
      label: 'Изменить',
      onClick: (e) => {
        setSelectedRowId(e?.row_id);
        setIsModalOpen(true);
        setSelectedRowData(e);
        // onGetField(uid);
        // onGetData(uid);
      },
    },
    {
      label: 'Удалить',
      onClick: (e) => {
        onDeleteRow(id, e?.row_id);
      },
    },
  ];

  const activeTab = useParams()['*'];

  const handleSaveNewText = (e) => {
    onAddData(id, e);
  };

  const handleRowClick = (row) => {
    setSelectedRowId(row?.row_id);
    setIsModalOpen(true);
    setSelectedRowData(row);
  };

  const onGetData = (table_uid) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${DenisCrmHttps}/api/custom_table/get_custom_table_data`;
    } else {
      url = `${DenisCrmHttps}/api/custom_table/get_custom_table_data`;
    }

    const body = JSON.stringify({
      table_uid,
    });

    fetch(url, { method: 'POST', headers, body })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('ERROR: get_tables_attr request');
        }
      })
      .then((json) => {
        setTable(json);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onAddRow = (table_uid) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${DenisCrmHttps}/api/custom_table/add_custom_table_row`;
    } else {
      url = `${DenisCrmHttps}/api/custom_table/add_custom_table_row`;
    }

    const body = JSON.stringify({
      table_uid,
    });

    fetch(url, { method: 'POST', headers, body })
      .then((res) => {
        if (res.ok) {
          onGetData(table_uid);
          return res.json();
        } else {
          throw new Error('ERROR: get_tables_attr request');
        }
      })
      .then((json) => {
        // setAttributes(json);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onDeleteRow = (table_uid, row_id) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${DenisCrmHttps}/api/custom_table/delete_custom_table_row`;
    } else {
      url = `${DenisCrmHttps}/api/custom_table/delete_custom_table_row`;
    }

    const body = JSON.stringify({
      table_uid,
      row_uid: row_id,
    });

    fetch(url, { method: 'DELETE', headers, body })
      .then((res) => {
        if (res.ok) {
          onGetData(table_uid);
          return res.json();
        } else {
          throw new Error('ERROR: get_tables_attr request');
        }
      })
      .then((json) => {
        // setAttributes(json);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onAddData = (table_uid, e) => {
    const url = `${DenisCrmHttps}/api/custom_table/add_custom_table_data`;

    const { inputEvent, actionEvent } = e;

    const body = JSON.stringify({
      data: [
        {
          row_uid: actionEvent[0]?.row_id,
          value: inputEvent?.selected
            ? inputEvent?.selected?.value
            : inputEvent?.value,
          field_name: actionEvent[2],
        },
      ],
      table_uid: table_uid,
    });

    fetch(url, {
      method: 'POST',
      headers,
      body,
    })
      .then((res) =>
        res.ok
          ? res.json()
          : Promise.reject('ERROR: add_custom_table_data request')
      )
      .then((res) => {
        onGetData(id);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (id) {
      // onGetField(tableId);
      onGetData(id);
      // onGetCustomTableName(tableId);
    }
  }, [id]);

  useEffect(() => {
    setPageValue(activeTab);
  }, [activeTab]);

  return (
    <div className="CRM">
      {isModalOpen && (
        <CrmModalDetail
          setIsModal={setIsModalOpen}
          table_uid={id}
          selectedRowId={selectedRowId}
          rowData={selectedRowData}
          onUpdateTable={onGetData}
        />
      )}
      <h2
        className="title_main"
        onClick={(e) => {
          console.log(e);
        }}
      >
        Таблица {id}
      </h2>
      <ButtonBasic
        text={'Добавить строку'}
        onClick={() => {
          onAddRow(id);
        }}
      />

      <div style={{ minWidth: '100wh' }}>
        <TableBox
          {...tableProps}
          ref={paginatorRef}
          paginator={true}
          fetchCallback={(e) => onGetData(e)}
          onAction={(e) => {
            handleRowClick(e);
          }}
          contextMenuArr={contextMenuArr}
          child={
            <button onClick={() => setIsEditing(!isEditing)}>
              {!isEditing ? 'Редактировать' : 'Сохранить'}
            </button>
          }
          isEditing={isEditing}
          tableIdCRM={id}
          fetchHead={headers}
          onInputAction={(e) => handleSaveNewText(e)}
        />
      </div>
    </div>
  );
};

export { CrmTableDetail };
