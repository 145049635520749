import { useState, useEffect } from 'react'
import { TableBox } from "@lk-gtcom/ecomlab-components"
import { CardDynamic } from '../../components/CardDynamic/CardDynamic'
import { OlegHttps, OlegHttp } from '../../fetchUrls'
import customHeaders, { getSpace } from '../../common/headers';
import useGeneralStore from '../../store/general';

import "./ProductCardExtended_Feedbacks.scss"

const ProductCardExtended_Feedbacks = ({
    tableData,
    tableCallback,
    checkFbs,
    nmid,
    apiId,
    date,
    paginatorRef
}) => {

    const [productMetrics, setProductMetrics] = useState([]);
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }

    const fetchProductCardGeneral = (abortController = new AbortController()) => {
        const api_id = `?api_id=${apiId}`
        const offer_id = `&offer_id=${nmid}`
        const fbs = `&fbs=${checkFbs}`

        let url;
        if (!window.location.hostname.match('localhost')) {
            url = `${OlegHttps}/api/v1/analysis/product/card/feedbacks${api_id + offer_id + fbs}`;
        } else {
            url = `${OlegHttp}/api/v1/analysis/product/card/feedbacks${api_id + offer_id + fbs}`;
        }

        const [date_from, date_to] = date;

        const body = JSON.stringify({
            date_from: new Date(date_from + 10)?.toISOString()?.split('T')?.[0],
            date_to: new Date(date_to)?.toISOString()?.split('T')?.[0],
            limit: paginatorRef.current?.limit || 10,
            page: paginatorRef.current?.page || 1,
        });

        fetch(url, {
            body,
            method: 'POST',
            headers,
            signal: abortController.signal,
        })
            .then(async (res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    const err = await res.json();
                    throw new Error(JSON.stringify(err));
                }
            })
            .then((json) => {
                setProductMetrics(json);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                if (abortController.signal.aborted) return;
            });
    };

    useEffect(() => {
        fetchProductCardGeneral()
    }, []);

    return (
        <section className='feedbacks-inside-content'>
            <div className='card-group'>
                {productMetrics?.length > 0
                    ? productMetrics?.map((data, i) => (
                        <CardDynamic key={JSON.stringify(data)} {...data} />
                    ))
                    : [...Array(5)]?.map((e, i) => (
                        <CardDynamic key={i} title={''} dynamic={''} value={''} />
                    ))}
            </div>

            <TableBox
                {...tableData}
                sortingFunc={tableCallback}
                fetchCallback={tableCallback}
                paginator={true}
            />
        </section>
    )
}

export { ProductCardExtended_Feedbacks }