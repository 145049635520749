import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  VladimirHttps,
  VladimirHttp,
  KonstantionAuthHttps,
  KonstantionAuthHttp,
} from '../../fetchUrls';
import Cookies from 'universal-cookie';
import ExternalAuthentication from '../ExternalAuthentication/ExternalAuthentication';
import { validationEmail } from '../../common/validationFunc';
import './AuthorizationBox.scss';
import { Checkbox } from '@lk-gtcom/ecomlab-components';
import { InputDinamycPlaceholder } from '@lk-gtcom/ecomlab-components';
import { PasswordRecoveryPage } from '../../pages/PasswordRecoveryPage/PasswordRecoveryPage';
import { ModalErr } from '../Modal/ModalErr/ModalErr';
import analyticRoutes from '../../pages/AnalyticsPage/routes';
import { processTokenResponse } from '../../common/authHelper';
import { ButtonBasic } from '@lk-gtcom/ecomlab-components';

const AuthorizationBox = ({
  setLoading,
  admin = false,
  requestError,
  setRequestError,
}) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [inValid, setInvalid] = useState(true);
  const [rememberMe, setRememberMe] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [isModalErr, setIsModalErr] = useState(false);

  const [errPassword, setErrPassword] = useState(false);
  const [errEmail, setErrEmail] = useState(false);

  const [isModal, setIsModal] = useState(false);

  console.log(email, password);

  const [areInputsValid, setAreInputsValid] = useState(false);

  const validateInputs = (emailInput, passwordInput) => {
    const regexPassword =
      /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}/;

    let isEmailValid = true;
    let isPasswordValid = true;

    if (emailInput?.length > 1) {
      isEmailValid = validationEmail(emailInput);
    } else {
      isEmailValid = false;
    }

    if (passwordInput?.length > 1) {
      isPasswordValid = regexPassword.test(passwordInput);
    } else {
      isPasswordValid = false;
    }

    setErrEmail(emailInput?.length > 0 ? !isEmailValid : false);
    setErrPassword(passwordInput?.length > 0 ? !isPasswordValid : false);

    setAreInputsValid(isEmailValid && isPasswordValid);
  };

  const checkInputField = () => {
    const regexPassword =
      /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}/;

    if (!regexPassword.test(password)) {
      setErrPassword(true);
    }

    if (!validationEmail(email)) {
      setErrEmail(true);
    }

    if (!regexPassword.test(password) || !validationEmail(email)) {
      return;
    } else {
      handlerSubmit();
    }
  };

  const deleteErrNotofication = () => {
    setRequestError(false);
    setInvalid(false);
    setErrPassword(false);
    setErrEmail(false);
    setInvalid(false);
  };

  const handlerSetEmail = (value) => {
    setEmail(value);
    deleteErrNotofication();
    validateInputs(value, password);
  };

  const handlerSetPassword = (value) => {
    setPassword(value);
    deleteErrNotofication();
    validateInputs(email, value);
  };

  const handlerSetRememberMe = (e) => {
    setRememberMe(e.target.checked);
  };

  const verifyEmail = (e) => {
    // setLoading(true)
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantionAuthHttps}/web/email-verify-token`;
    } else {
      url = `${KonstantionAuthHttp}/web/email-verify-token`;
    }

    // const headers = { 'Content-type': 'application/json' };
    const headers = {
      'Content-type': 'application/json',
      'portal-id': '4',
    };

    const body = JSON.stringify({ email });

    fetch(url, { method: 'POST', body, headers })
      .then((res) => res.json())
      .then(async (json) => {
        setDisplayModal(true);
        setTimeout(() => {
          setDisplayModal(false);
        }, 5000);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  const handlerSubmit = (e) => {
    // setLoading(true)
    const cookies = new Cookies();
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantionAuthHttps}/web/login`;
    } else {
      url = `${KonstantionAuthHttp}/web/login`;
    }

    const device_info = window.navigator.userAgent;

    let body = [];

    const bodyData = {
      username: email,
      password: password,
      device_info,
    };

    for (const key in bodyData) {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(bodyData[key]);
      body.push(encodedKey + '=' + encodedValue);
    }

    body = body.join('&');

    // const headers = { 'Content-type': 'application/x-www-form-urlencoded' };
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'portal-id': '4',
    };

    fetch(url, { method: 'POST', body, headers })
      .then((res) => res.json())
      .then(async (json) => {
        if (json?.detail == 'LOGIN_BAD_CREDENTIALS') {
          setInvalid(true);
          setRequestError(true);
          return;
        } else if (json?.detail == 'LOGIN_USER_NOT_VERIFIED') {
          verifyEmail();
          setInvalid(true);
          return;
        } else if (json?.detail == 'Not Found') {
          setIsModalErr(true);
        } else {
          console.log(json);
          processTokenResponse(json);
          const refreshToken = cookies.get('refresh');
          window.location.assign(`/service_auth/${refreshToken}`);
        }
      })
      .catch((err) => {
        console.error(err);
        setIsModalErr(true);
        setRequestError(true);
      })
      .finally(() => setLoading(false));
  };

  // Сохранение query
  const [query, setQuery] = useState('');

  useEffect(() => {
    const params = new URL(document.location).searchParams;
    let query_params = params.get('utm_ref');
    setQuery(query_params);
    console.log('utm_ref ' + query_params);
  }, [new URL(document.location)]);

  useEffect(() => {
    validateInputs(email, password);
  }, [email, password]);

  return (
    <>
      {isModal && <PasswordRecoveryPage setIsModal={(e) => setIsModal(e)} />}

      {isModalErr && (
        <ModalErr isModal={isModalErr} setIsModal={(e) => setIsModalErr(e)} />
      )}

      <div className="autorization" onSubmit={(e) => e.preventDefault()}>
        <div
          style={{
            position: 'absolute',
            width: '100vw',
            height: '100vh',
            zIndex: 10,
            backgroundColor: 'rgba(0,0,0,.3)',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            display: displayModal ? 'flex' : 'none',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              width: '50vw',
              height: '30vh',
              borderRadius: '1em',
              padding: '1.5em 1em',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <h2
              style={{
                fontSize: 50,
                textAlign: 'center',
                position: 'relative',
              }}
            >
              Подтвердите свою почту, перейдя по ссылке в письме, которое мы Вам
              отправили.
            </h2>
          </div>
        </div>
        <h2 className="title">Войти</h2>
        <ExternalAuthentication />
        <p className="text">
          <span style={{ color: '#94A3B8' }}>Или через </span>e-mail
        </p>

        <InputDinamycPlaceholder
          classname="autorization__input-field"
          placeholder="Введите адрес электронной почты"
          err={errEmail}
          textErr={'Неверный email'}
          type="e-mail"
          onChange={(e) => handlerSetEmail(e)}
        />

        <InputDinamycPlaceholder
          classname="autorization__input-field"
          placeholder="Введите пароль"
          err={errPassword}
          textErr={'Пароль не соответствует требованиям'}
          onChange={(e) => handlerSetPassword(e)}
          type="password"
          tooltip=""
        />

        <div className="checkbox__box">
          {!requestError ? (
            <label style={{ cursor: 'pointer' }}>
              <Checkbox
                value={rememberMe}
                onChange={(e) => handlerSetRememberMe(e)}
              />
              <span className="text" style={{ color: ' 020617' }}>
                Запомнить меня?
              </span>
            </label>
          ) : (
            <span className="text text_red">Неверный логин или пароль</span>
          )}

          <a
            href="#"
            className="text text_blue"
            onClick={(e) => {
              e.preventDefault();
              setIsModal(true);
            }}
          >
            Забыли пароль?
          </a>
        </div>

        <div className="bottom-box-registration">
          <ButtonBasic
            blue
            disabled={!areInputsValid}
            text="Войти"
            size="40px"
            width="100%"
            onClick={(e) => {
              e.preventDefault();
              checkInputField();
            }}
          />
          {admin ? (
            <Link to="/signup" className="link-box-btn">
              <button className="btn__blue">Регистрация</button>
            </Link>
          ) : null}
        </div>

        <div className="bottom-box">
          <p className="text_grey-blue">Еще нет аккаунта?</p>
          <Link to="./signup" className="text_blue">
            Зарегистрироваться
          </Link>
        </div>
      </div>
    </>
  );
};

export { AuthorizationBox };
