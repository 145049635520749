import { Link } from 'react-router-dom';
import { useState } from 'react';
import "./ButtonCopy.scss"

const ButtonCopy = ({ value, icon, style = {}, top }) => {

    const [currentText, setCurrentText] = useState('Скопировать')

    return (
        <div className='button-copy' >
            <button className='btn-copy'
                onBlur={() => setCurrentText('Скопировать')}
                onClick={() => {
                    navigator.clipboard.writeText(value)
                    setCurrentText('Cкопировано')
                }}
                style={{ backgroundImage: icon ? `url(${icon})` : '', ...style }}
            ></button>
            <div className='tooltip_black'
            style={{top: top ? top : ''}}
            >{currentText}</div>
        </div>
    )
}

export { ButtonCopy }