import { useEffect, useRef } from 'react';
import './TitleContentEditable.scss';

const TitleContentEditable = ({
  placeholder,
  disabled = false,
  title,
  setTitle,
  autoFocus = false,
  titleStatic = false,
  maxlength = false,
  requared = false,
  setIsChanges = false,
}) => {
  const contentEditableRef = useRef(null);

  useEffect(() => {
    if (autoFocus && contentEditableRef.current) {
      contentEditableRef.current.focus();
    }
  }, [autoFocus]);

  const handleInput = () => {
    let text = contentEditableRef.current.innerText;

    if (text === '\n') {
      text = '';
    }

    if (maxlength && text.length > maxlength) {
      const truncatedText = text.slice(0, maxlength);
      contentEditableRef.current.innerText = truncatedText;
      return;
    }

    if (!disabled && setIsChanges) {
      setIsChanges(true);
    }
    setTitle(text);
  };

  const handleBlur = () => {
    let text = contentEditableRef.current.innerText.trim();
    if (text === '\n') {
      return (text = '');
    }
    setTitle(text || '');
  };

  const handleFocus = () => {
    if (!disabled && setIsChanges) {
      setIsChanges(true);
    }
  };

  useEffect(() => {
    if (
      contentEditableRef.current &&
      title !== contentEditableRef.current.innerText
    ) {
      contentEditableRef.current.innerText = title;
    }
  }, [title]);

  return (
    <div
      className={`title-content-editable ${
        requared ? 'title-content-editable_requared' : ''
      }`}
    >
      {titleStatic && (
        <h4 className="title-content-editable__title">{titleStatic + ':'}</h4>
      )}
      <div
        className={`title-content-editable__area ${
          disabled ? 'title-content-editable__area_disabled' : ''
        }`}
        ref={contentEditableRef}
        contentEditable={!disabled}
        suppressContentEditableWarning={true}
        onInput={handleInput}
        onBlur={handleBlur}
        onFocus={handleFocus}
        role="textbox"
        tabIndex={0}
        data-placeholder={placeholder}
      ></div>
      {maxlength && (
        <span className="title-content-editable_edit__countSymbol">
          осталось символов - {maxlength - (title?.length || 0)}
        </span>
      )}
    </div>
  );
};

export default TitleContentEditable;
