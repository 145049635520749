import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware'

const btn_tab_1 = [
  {
    label: 'Сотрудники',
    value: 'employee',
  },
  {
    label: 'Подключения',
    value: 'settings',
  },
  {
    label: 'Роли',
    value: 'roles',
  }
];

export const useGeneralStore = create(
    persist(
        ( set ) => ({
            // Пространства
            spaceList: [],
            setSpaceList: (list) => set({ spaceList: list }),

            currentSpace: {},
            setCurrentSpace: (space) => set({ currentSpace: space }),

            selectedSpace: {},
            setSelectedSpace: (value) => {
                set({ selectedSpace: value })
            },

            pageValue1: btn_tab_1[0].value,
            setPageValue1: (value) => {
                set({pageValue1: value})
            },

            // Уведомления
            notificationCounter: 0,
            setNotificationCounter: (value) => set({ notificationCounter: value }),
        
            // Баланс
            balance: 0,
            setBalance: (value) => set({ balance: value }),

            accounList: [],
            setAccountList: (list) => set({ accounList: list }),
        
            employeesList: [],
            setEmployeesList: (list) => set({ employeesList: list }),

            availableEmployeesList: [],
            setAvailableEmployeesList: (list) => set({ availableEmployeesList: list }),

        }),
        {
            name: 'general-storage',
            // storage: createJSONStorage(() => localStorage)
        }
    )
);


export default useGeneralStore;
