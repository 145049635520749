import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import "./SceletonTask.scss"

const SceletonTask = ({ height = '400' }) => {

    return (
        <div className='sceleton-task'>
            <Stack className='sceleton-task__content'>
                <Skeleton  className='sceleton-task__item' variant="rectangular" />
            </Stack>
        </div>
    )
}

export { SceletonTask }