import { useState } from 'react';
import { LineChart } from '../../charts/LineChart/LineChart';
import { TableBox, DropContainer, Tabs } from '@lk-gtcom/ecomlab-components';
import './ProductCardExtended_Position.scss';

const btn_tab = [
  {
    label: 'По ключевым словам',
    value: 'by_keywords',
  },
  {
    label: 'По категориям',
    value: 'by_category',
  },
  {
    label: 'По брендам',
    value: 'by_brand',
  },
  {
    label: 'По продавцу',
    value: 'by_seller',
  },
];

const ProductCardExtended_Position = ({
  tableData,
  chartData,
  tableCallback,
  setPositionPageValue,
  metricsList,
  selectedMetric,
  setSelectedMetric,
  setSelectedSearchQuery,
}) => {
  
  const [showDropBox, setShowDropBox] = useState(true);
  const [pageValue, setPageValue] = useState(btn_tab[0].value);
  const onCheck = (obj) => {
    const querySearch = obj.map((item) => item?.['Ключевое слово']?.[0]);
    setSelectedSearchQuery(querySearch);
  };

  return (
    <section className="position-inside-content">
      <DropContainer
        showDropBox={showDropBox}
        setShowDropBox={(e) => setShowDropBox(e)}
        name={chartData?.title}
      >
        <LineChart {...chartData}
         metricsList={metricsList}
         selectedMetric={selectedMetric}
         setSelectedMetric={setSelectedMetric}
         legend={metricsList} />
      </DropContainer>

      <TableBox
        onUncheckAllDependence={[pageValue]}
        {...tableData}
        paginator={true}
        fetchCallback={tableCallback}
        onCheck={onCheck}
      />
    </section>
  );
};

export { ProductCardExtended_Position };
