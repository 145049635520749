import { useState, useEffect } from 'react'
import customHeaders, { getSpace } from '../../common/headers';
import { LineChart } from '../../charts/LineChart/LineChart'
import { TableBox, DropContainer } from "@lk-gtcom/ecomlab-components"
import { AndreySkuHttps, AndreySkuHttp } from '../../fetchUrls';
import { OlegHttps, OlegHttp } from '../../fetchUrls';
import useGeneralStore from '../../store/general';
import "./ProductCardExtended_GeneralIndicators.scss"

const ProductCardExtended_GeneralIndicators = ({
    tableData,
    tableCallback,
    chartData,
    selectedMetric,
    setSelectedMetric,
    setSelectedTableMetric,
    selectedTableMetric,
}) => {

    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }
    const [showDropBox, setShowDropBox] = useState(true);
    const [metrics, setMetrics] = useState([]);

    const fetchMetrics = (abortController = new AbortController()) => {
        let url;
        if (!window.location.hostname.match('localhost')) {
            url = `${OlegHttps}/api/v1/analysis/product/graph_list`;
        } else {
            url = `${OlegHttp}/api/v1/analysis/product/graph_list`;
        }

        fetch(url, { method: 'GET', headers, signal: abortController.signal })
            .then(async (res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    const err = await res.json();
                    throw new Error(JSON.stringify(err));
                }
            })
            .then((json) => {
                setMetrics(json);
                setSelectedTableMetric(json.map((metric) => metric.value));
            })
            .catch((err) => {
                console.error(err);
            });
    };

    useEffect(() => {
        fetchMetrics();
    }, []);

    return (
        <section className='general-indicators-inside-content'>
            <DropContainer
                showDropBox={showDropBox}
                setShowDropBox={(e) => setShowDropBox(e)}
            >
                <LineChart
                    metricsList={metrics}
                    selectedMetric={selectedMetric}
                    setSelectedMetric={setSelectedMetric}
                    {...chartData}
                />
            </DropContainer>

            {/* <TableBox
                {...tableData}
                paginator={true}
                fetchCallback={tableCallback}
            /> */}
        </section>
    )
}

export { ProductCardExtended_GeneralIndicators }