import customHeaders, { getSpace } from '../../../common/headers';
import { Editor } from '../../Editor/Editor';
import { useNavigate } from 'react-router-dom';
import { RangeBoxSingle } from '../../RangeBoxSingle/RangeBoxSingle';
import { ModalCoverProject } from '../ModalCoverProject/ModalCoverProject';
import { useState, useEffect } from 'react';
import { RavshanHttp, RavshanHttps } from '../../../fetchUrls';
import {
  InputDinamycPlaceholder,
  ButtonBasic,
  SelectExecutor,
  RangeBox,
  TextareaDynamicPlaceholder,
} from '@lk-gtcom/ecomlab-components';
import { ExecutorPlaceholder } from '../../OptionsExecutor/OptionsExecutor';
import { OptionsExecutor } from '../../OptionsExecutor/OptionsExecutor';
import { ButtonActionDrop } from '../../Buttons/ButtonActionDrop/ButtonActionDrop';
import { ReactComponent as StarBkg } from './img/bxs-star.svg';
import { ReactComponent as Star } from './img/bx-star.svg';
import { TabsGreen } from '../../TabsGreen/TabsGreen';
import { useMemo } from 'react';
import useGeneralStore from '../../../store/general';
import './ModalAddProject.scss';

const ModalAddProject = ({
  setIsModal,
  isInfoProject,
  setIsInfoProject,
  selectedProject,
}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const email = localStorage.getItem('email');
  const currentUserColor = localStorage.getItem('currentUserColor');
  const currentUserName = localStorage.getItem('currentUserName');
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const navigate = useNavigate();

  const [executorSearchValue, setExecutorSearchValue] = useState('');
  const [executors, setExecutors] = useState([]);
  const [executorOptions, setExecutorOptions] = useState([]);
  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [isEdit, setIsEdit] = useState(false);

  const [title, setTitle] = useState('');
  const [titleDefault, setTitleDefault] = useState('');
  const [description, setDescription] = useState('');
  const [authorName, setAuthorName] = useState(currentUserName);
  const [authorEmail, setAuthorEmail] = useState(email);
  const [authorColor, setAuthorColor] = useState(currentUserColor);

  const [fieldsdName, setFieldsName] = useState('');
  const [fields, setFilds] = useState([]);
  const [fieldsArr, setFieldsArr] = useState([]);

  const [isFavorite, setIsFavorite] = useState(false);

  const [isDropMenu, setIstDropMenu] = useState(false);

  const [showOption, setShowOptions] = useState(false);
  const img_arr = [
    'https://apps1.ecomru.ru:9001/developerimages/banner_1.svg',
    'https://apps1.ecomru.ru:9001/developerimages/banner_2.svg',
    'https://apps1.ecomru.ru:9001/developerimages/banner_3.svg',
    'https://apps1.ecomru.ru:9001/developerimages/banner_4.svg',
    'https://apps1.ecomru.ru:9001/developerimages/banner_5.svg',
    'https://apps1.ecomru.ru:9001/developerimages/banner_6.svg',
    'https://apps1.ecomru.ru:9001/developerimages/banner_7.svg',
    'https://apps1.ecomru.ru:9001/developerimages/banner_8.svg',
    'https://apps1.ecomru.ru:9001/developerimages/banner_9.svg',
  ];
  const [currentCover, setCurrentCover] = useState(img_arr[0]);
  const [isModalAddCover, setIsModalAddCover] = useState(false);

  const btns_tab = [
    { label: 'Общая информация', value: 'info' },
    { label: 'Участники и заявки', value: 'exexutors' },
    { label: 'Запросы', value: 'requests' },
    { label: 'Создание полей', value: 'fields' },
  ];
  const [status, setStatus] = useState(btns_tab?.[0]?.value);

  const addNewProject = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v2/tasks/post_project`;
    } else {
      url = `${RavshanHttp}/api/v2/tasks/post_project`;
    }

    const [date_from, date_to] = date;
    const img = document.getElementById('img')?.src;

    const fields_arr = fields.map(({ name }) => {
      return {
        name: name,
        field_type: 'string',
      };
    });

    const body = JSON.stringify({
      name: title,
      text: description,
      end_time: new Date(date_to),
      start_time: new Date(date_from),
      previewimage: img ? img : '',
      attendee: executors.map(({ id }) => id),
      is_favorite: isFavorite,
      additional_fields: fields_arr,
    });

    fetch(url, { body, method: 'POST', headers })
      .then((res) => {
        if (res.ok) {
          setIsModal(false);
          sessionStorage.removeItem('selectedProject');
          navigate('/tasks/project-for-tasks/');
        } else {
          throw new Error('bad post project response');
        }
      })
      .catch((err) => console.error(err));
  };

  const editProject = (e) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/tasks/put_project`;
    } else {
      url = `${RavshanHttp}/api/tasks/put_project`;
    }

    const [date_from, date_to] = date;
    const img = document.getElementById('img')?.src;

    const fields_arr = fields.map(({ name }) => {
      return {
        name: name,
        field_type: 'string',
      };
    });

    const body = JSON.stringify({
      name: title,
      text: description,
      end_time: new Date(date_to),
      start_time: new Date(date_from),
      task_id: selectedProject?.taskId,
      previewimage: img ? img : '',
      attendee: executors.map(({ id }) => id),
      is_favorite: isFavorite,
      additional_fields: fields_arr,
    });

    fetch(url, { body, method: 'PUT', headers })
      .then((res) => {
        if (res.ok) {
          setIsModal(false);
          sessionStorage.removeItem('selectedProject');
          navigate('/tasks/project-for-tasks/');
        } else {
          throw new Error('bad put project response');
        }
      })
      .catch((err) => console.error(err));
  };

  const deleteProject = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/tasks/delete_project`;
    } else {
      url = `${RavshanHttp}/api/tasks/delete_project`;
    }

    const body = JSON.stringify({
      task_id: selectedProject?.taskId,
    });

    fetch(url, { body, method: 'DELETE', headers })
      .then((res) => {
        if (res.ok) {
          setIsModal(false);
          sessionStorage.removeItem('selectedProject');
          setIstDropMenu(false);
        } else {
          throw new Error('bad post project response');
        }
      })
      .catch((err) => console.error(err));
  };

  const fetchPossibleAttendees = () => {
    const url = `${RavshanHttps}/api/v2/tasks/get_client_team`;
    const newHeaders = {
      ...headers,
    };
    fetch(url, { headers: newHeaders })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('bad attendees response');
        }
      })
      .then((json) => {
        setExecutorOptions(
          json?.filter((e) => {
            const nameLowerCased = e.name.toLowerCase();
            return !executors
              ?.map((e) => e.name.toLowerCase())
              .includes(nameLowerCased);
          })
        );
      })
      .catch((e) => console.error(e));
  };

  const btns = [
    {
      btn: 'Удалить',
      func: () => {
        deleteProject();
      },
    },
  ];

  const executorList = () => {
    const executor_list = executorOptions
      .filter((e) => {
        const nameLowerCased = e.name.toLowerCase();
        return (
          !executors
            .map((e) => e.name.toLowerCase())
            .includes(nameLowerCased) &&
          (executorSearchValue
            ? nameLowerCased.includes(executorSearchValue.toLowerCase())
            : true)
        );
      })
      ?.map(({ id, name, login, img, color }) => {
        return (
          <OptionsExecutor
            onClick={(e) => setExecutors((prev) => [...prev, e])}
            key={id}
            id={id}
            name={name}
            email={login}
            img={img}
            color={color}
          />
        );
      });

    return executor_list;
  };

  useEffect(() => {
    fetchPossibleAttendees();
    if (isInfoProject) {
      if (selectedProject) {
        console.log(selectedProject);
        const {
          name,
          text,
          attendee,
          author,
          startTime,
          endTime,
          previewImage,
          isFavorite,
        } = selectedProject;
        setIsInfoProject(selectedProject);
        setTitleDefault(name);
        setTitle(name);
        setDescription(text);
        setExecutors(attendee);
        setCurrentCover(previewImage);
        setIsFavorite(isFavorite);
        if (author) {
          const { name, login, color } = author;
          setAuthorColor(color);
          setAuthorEmail(login);
          setAuthorName(name);
        }
        setDate([
          new Date(startTime ? startTime : endTime ? endTime : ''),
          new Date(endTime ? endTime : startTime ? startTime : ''),
        ]);
      }
    }
  }, [isInfoProject, selectedProject, selectedSpace]);

  useEffect(() => {
    if (selectedProject) {
      setIsEdit(true);
    }
  }, [selectedProject]);

  useEffect(() => {
    return () => {
      setIsInfoProject(null);
    };
  }, []);

  useEffect(() => {
    const hideOptionsBox = (e) => {
      if (!e.target.closest('.search-box-executor')) {
        setShowOptions(false);
      }
      setExecutorSearchValue('');

      if (!e.target.closest('.context-more')) {
        setIstDropMenu(false);
      }
    };
    document.addEventListener('click', (e) => hideOptionsBox(e));
    return () =>
      document.removeEventListener('click', (e) => hideOptionsBox(e));
  }, []);

  const fetchGetAdditionalFields = (
    abortController = new AbortController()
  ) => {
    if (selectedProject) {
      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${RavshanHttps}/api/v3/tasks/get_additional_fields_by_project`;
      } else {
        url = `${RavshanHttp}/api/v3/tasks/get_additional_fields_by_project`;
      }

      const body = JSON.stringify({
        task_id: selectedProject?.taskId,
      });

      fetch(url, {
        body,
        method: 'POST',
        headers,
        signal: abortController.signal,
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error('bad events response');
          }
        })
        .then((json) => {
          setFieldsArr(json);
          setFilds(json).map(({ name }) => {
            return { name: name, field_type: 'string' };
          });
        })
        .catch((err) => {
          console.error(err);
        });
      // .finally(() => {
      //     setLoad(false)
      // })
    }
  };

  useEffect(() => {
    fetchGetAdditionalFields();
  }, []);

  const addFields = (fieldsdName) => {

    const field = {
      name: fieldsdName,
      field_type: 'string',
    };
    setFilds((prev) => [
      ...prev.map(({ name, type }) => {
        return { name: name, field_type: 'string' };
      }),
      field,
    ]);
    setFieldsName('');
  };

  const handleInput = (e, index) => {
    const curr = fields.map(({ name, field_type }, ind) => {
      if (ind === index) {
        return { name: e, field_type: 'string' };
      } else {
        return { name: name, field_type: 'string' };
      }
    });
    setFilds(curr);
  };

  const [fieldsList, setFieldsList] = useState([]);

  useMemo(() => {
    setFieldsList([
      ...fields.map(({ name, type, field_id }, ind) => {
        return (
          <div className="modal-add-project__fields-item">
            <InputDinamycPlaceholder
              changeValue={name}
              onChange={(e) => handleInput(e ? e : name, ind)}
            //  placeholder={name}
              size="s"
            />
            <ButtonBasic
              onClick={() => {
                const curr = fields.filter((el, index) => {
                  return ind !== index;
                });
                setFilds([
                  ...curr.map(({ name, type }) => {
                    return { name: name, field_type: 'string' };
                  }),
                ]);
              }}
              red
              text="Удалить"
            />
          </div>
        );
      }),
    ]);
  }, [fields]);

  return (
    <div
      className="blackout"
      onClick={(e) => {
        let el = e.target;
        if (el.classList.contains('blackout')) {
          setIsEdit(false);
          setIsModal(false);
          sessionStorage.removeItem('selectedProject');
          navigate(`/tasks/project-for-tasks`);
        }
      }}
    >
      {isModalAddCover && (
        <ModalCoverProject
          setIsModal={(e) => setIsModalAddCover(e)}
          imgArr={img_arr}
          currentCover={currentCover}
          setCurrentCover={(e) => setCurrentCover(e)}
        />
      )}

      <div className="modal-add-project">
        <div className="modal-add-project__header">
          <h1 className="modal-add-project__title">
            {isEdit ? 'Редактирование проекта' : 'Создание проекта'}
          </h1>

          <InputDinamycPlaceholder
            onChange={(e) => setTitle(e)}
            placeholder="Название проекта"
            changeValue={titleDefault}
            setChangeValue={(e) => setTitleDefault(e)}
            autoComplete={false}
            size="s"
          />

          <ButtonBasic
            grey
            minWidth="48px"
            width="48px"
            style={{ marginLeft: 'auto' }}
            text={isFavorite ? <StarBkg /> : <Star />}
            onClick={(e) => setIsFavorite(!isFavorite)}
          />

          <ButtonBasic
            grey
            width="190px"
            minWidth="190px"
            text={isEdit ? 'Сохранить' : 'Создать'}
            onClick={(e) => {
              if (title) {
                isEdit ? editProject() : addNewProject();
                setIsEdit(true);
              } else return;
            }}
          />

          <ButtonActionDrop id={1} btns={btns} />
        </div>

        <TabsGreen
          btns={btns_tab}
          currentBtn={status}
          setCurrentBtn={(e) => setStatus(e)}
          checkValue={true}
        />

        {status == 'info' && (
          <div className="modal-add-project__content">
            <div className="modal-add-project__section">
              <p className="modal-add-project__label">Обложка</p>
              <div
                className="img-box"
                onClick={(e) => setIsModalAddCover(true)}
              >
                <img
                  className="img"
                  id="img"
                  src={currentCover ? currentCover : img_arr[1]}
                  onError={(e) => (e.target.src = img_arr[1])}
                />
                <div className="img-box_blackout">
                  <span>Изменить обложку</span>
                </div>
              </div>
            </div>

            <div className="modal-add-project__section">
              <p className="modal-add-project__label">
                Введите описание проекта
              </p>
              <TextareaDynamicPlaceholder
                width="100%"
                changeValue={description}
                placeholder="Вопрос или комментарий*"
                onChange={(e) => setDescription(e)}
              />
            </div>

            <div className="modal-add-project__section">
              <p className="modal-add-project__label">Сроки проекта</p>
              <RangeBox
                btn_period={false}
                hideBtnGroup
                date={date}
                setDate={(e) => setDate(e)}
              />
            </div>
          </div>
        )}

        {status == 'exexutors' && (
          <div className="modal-add-project__content">
            <div className="executors">
              <label className="executor__placeholder">Автор:</label>
              <div className="executor-content">
                <ExecutorPlaceholder
                  name={authorName}
                  color={authorColor}
                  email={authorEmail}
                />
                <p className="text_name">{authorName}</p>
              </div>

              <SelectExecutor
                isShowInfo
                executors={executors}
                setExecutors={(e) => setExecutors(e)}
                executorOptions={executorOptions}
                multi
                title="Участники проекта"
                placeholder="Добавить участника"
              />
            </div>
          </div>
        )}

        {status == 'requests' && (
          <div
            className="modal-add-project__content"
            style={{
              flexDirection: 'column',
            }}
          >
            <p className="modal-add-project__label">Запросы в проект</p>
            <div className="modal-add-project__request-list">
              <div className="modal-add-project__request-item">
                <ExecutorPlaceholder
                  isShowInfo
                  hideTooltip
                  name={'Test'}
                  email={'test@mail.ru'}
                  color={'red'}
                />
                <div className="modal-add-project__group-btn">
                  <ButtonBasic
                    green
                    text="Добавить"
                    size="32px"
                    minWidth="50%"
                    width="50%"
                  />

                  <ButtonBasic
                    red
                    text="Удалить"
                    size="32px"
                    minWidth="50%"
                    width="50%"
                  />
                </div>
              </div>
              <div className="modal-add-project__request-item">
                <ExecutorPlaceholder
                  isShowInfo
                  hideTooltip
                  name={'Test'}
                  email={'test@mail.ru'}
                  color={'red'}
                />
                <div className="modal-add-project__group-btn">
                  <ButtonBasic
                    green
                    text="Добавить"
                    size="32px"
                    minWidth="50%"
                    width="50%"
                  />

                  <ButtonBasic
                    red
                    text="Удалить"
                    size="32px"
                    minWidth="50%"
                    width="50%"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {status == 'fields' && (
          <div
            className="modal-add-project__content"
            style={{
              flexDirection: 'column',
            }}
          >
            <p className="modal-add-project__label">Добавление полей</p>
            <div className="modal-add-project__request-list">
              <div className="modal-add-project__fields-row">
                <InputDinamycPlaceholder
                  changeValue={fieldsdName}
                  onChange={(e) => setFieldsName(e)}
                  placeholder="Введите название поля"
                  size="s"
                />
                <ButtonBasic
                  green
                  text="Создать"
                  onClick={() => {
                    if (fieldsdName?.length > 0) {
                      addFields(fieldsdName);
                    }
                  }}
                />
              </div>

              <div className="modal-add-project__fields-group">
                <p className="modal-add-project__label">Готовые поля</p>

                <div className="modal-add-project__fields-content">
                  {fieldsList}
                  {/* {(fields).map(({ name, type, field_id }, ind) => {
                                        return <div
                                            className='modal-add-project__fields-item'
                                        >
                                            <InputDinamycPlaceholder
                                                //  value={name}
                                                onChange={(e) => handleInput(e ? e : name, ind)}
                                                placeholder={name}
                                                size='s'
                                            />
                                            <ButtonBasic
                                                onClick={() => {
                                                    const curr = fields.filter((el, index) => {
                                                        return ind !== index
                                                    })
                                                    setFilds([...curr.map(({ name, type }) => {
                                                        return { name: name, field_type: 'string' }
                                                    })])
                                                }}
                                                red
                                                text='Удалить'
                                            />
                                        </div>
                                    })} */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { ModalAddProject };
