import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { KonstantionAuthHttps, KonstantionAuthHttp } from '../../fetchUrls';
import Cookies from 'universal-cookie';
import { InputDinamycPlaceholder } from '@lk-gtcom/ecomlab-components';
import { ModalErr } from '../Modal/ModalErr/ModalErr';
import { validationEmail } from '../../common/validationFunc';
import './ResetPasswordBox.scss';
import { processTokenResponse } from '../../common/authHelper';

const ResetPasswordBox = ({ email }) => {
  const navigate = useNavigate();
  const path = useParams();
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [inValid, setInvalid] = useState(false);
  const [isModalErr, setIsModalErr] = useState(false);
  const [err, setErr] = useState(false);

  const [errPassword, setErrPassword] = useState(false);
  const [errPasswordRepeat, setErrPasswordRepeat] = useState(false);

  const deleteErrNotofication = () => {
    setErrPassword(false);
    setErrPasswordRepeat(false);
    setErr(false);
    setInvalid(false);
  };

  const handlerSetPassword = (e) => {
    setPassword(e);
    deleteErrNotofication();
  };

  const handlerSetRepeatPassword = (e) => {
    setPasswordRepeat(e);
    deleteErrNotofication();
  };

  let form = {
    token: path['*'].split('/')[1],
    password: password,
    device_info: '',
  };

  const handlerSubmit = (e) => {
    if (password !== passwordRepeat) {
      setInvalid(true);
    } else {
      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${KonstantionAuthHttps}/web/reset-password`;
      } else {
        url = `${KonstantionAuthHttp}/web/reset-password`;
      }

      const headers = {
        'Content-type': 'application/json',
        'portal-id': '4',
      };

      fetch(url, {
        method: 'POST',
        body: JSON.stringify(form),
        headers,
        // headers: { 'Content-type': 'application/json' }
      })
        .then((response) => response.json())
        .then(async (data) => processTokenResponse(data))
        .catch((err) => {
          setIsModalErr(true);
          console.error(err);
        });
    }
  };

  const checkInputField = () => {
    const regexPassword =
      /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}/;

    if (!regexPassword.test(password)) {
      setErrPassword(true);
    }

    if (!regexPassword.test(passwordRepeat)) {
      setErrPasswordRepeat(true);
    }

    if (!regexPassword.test(password) || !regexPassword.test(passwordRepeat)) {
      return;
    } else {
      handlerSubmit();
    }
  };

  return (
    <>
      {isModalErr && (
        <ModalErr isModal={isModalErr} setIsModal={(e) => setIsModalErr(e)} />
      )}
      <div
        className="autorization"
        onSubmit={(e) => e.preventDefault()}
        spellCheck="false"
      >
        <h2 className="title">Придумайте пароль</h2>

        <InputDinamycPlaceholder
          onChange={(e) => handlerSetPassword(e)}
          type="password"
          classname="autorization__input-field"
          placeholder="Введите новый пароль"
          err={errPassword}
          textErr={'Пароль не соответствует требованиям'}
          autoComplete={false}
          passwordTooltip
          tooltip="Пароль должен содержать не менее 8 символов, цифры и заглавные буквы"
        />

        <InputDinamycPlaceholder
          onChange={(e) => handlerSetRepeatPassword(e)}
          type="password"
          err={errPasswordRepeat}
          textErr={'Пароль не соответствует требованиям'}
          classname="autorization__input-field"
          placeholder="Введите пароль еще раз"
          autoComplete={false}
        />

        {inValid ? (
          <Link to="#" className="text text_red">
            Введеные пароли не совпадают
          </Link>
        ) : null}

        {err && (
          <span className="text text_red">Не удалось сбросить пароль</span>
        )}

        <button
          style={{ marginTop: '0px' }}
          onClick={(e) => {
            e.preventDefault();
            checkInputField();
          }}
          className="btn__blue"
        >
          Сохранить пароль
        </button>
      </div>
    </>
  );
};

export { ResetPasswordBox };
