import { TableBox } from "@lk-gtcom/ecomlab-components"
import "./ProductCardExtended_OrdersAndSales.scss"

const ProductCardExtended_OrdersAndSales = ({ 
    tableData,
    tableCallback,
    paginatorRef
 }) => {

    return (
        <section className='orders-and-sales-inside-content'>
            {/* <TableBox
                {...tableData}
                sortingFunc={tableCallback}
                paginator={true}
                ref={paginatorRef}
                fetchCallback={tableCallback}
            /> */}
        </section>
    )
}

export { ProductCardExtended_OrdersAndSales }