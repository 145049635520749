import { useState, useEffect } from 'react';
import { ButtonBasic } from '@lk-gtcom/ecomlab-components';
import {
  InputDinamycPlaceholder,
  DropDownSelector,
} from '@lk-gtcom/ecomlab-components';
import './CrmModalDetail.scss';
import useGeneralStore from '../../store/general';
import customHeaders, { getSpace } from '../../common/headers';
import { DenisCrmHttps } from '../../fetchUrls';
import close_icon from '../CrmCreateTable/img/close_icon.svg';
import { CrmAttrControlModal } from '../CrmCreateTable/CrmAttrControlModal';

const CrmModalDetail = ({
  setIsModal,
  selectedRowId,
  table_uid,
  rowData,
  onUpdateTable,
}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };

  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});
  const [fieldConfig, setFieldConfig] = useState([]);
  const [lists, setLists] = useState({});
  const [selectedLists, setSelectedLists] = useState({});
  const [changedFields, setChangedFields] = useState(new Set());

  const handleInputChange = (field, value) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [field]: value,
    }));

    if (rowData[field] !== value) {
      setChangedFields((prev) => new Set([...prev, field]));
    } else {
      setChangedFields((prev) => {
        const updated = new Set(prev);
        updated.delete(field);
        return updated;
      });
    }
  };

  const handleSubmit = () => {
    if (changedFields.size === 0) {
      setIsModal(false);
      return;
    }

    const updatedFields = Array.from(changedFields).map((field) => ({
      row_uid: selectedRowId,
      value: inputs[field]?.value || inputs[field],
      field_name: field,
    }));

    const url = `${DenisCrmHttps}/api/custom_table/add_custom_table_data`;
    const body = JSON.stringify({
      table_uid,
      data: updatedFields,
    });

    fetch(url, {
      method: 'POST',
      headers,
      body,
    })
      .then((res) =>
        res.ok ? res.json() : Promise.reject('Ошибка при сохранении данных')
      )
      .then(() => {
        onUpdateTable(table_uid);
        setChangedFields(new Set());
        setIsModal(false);
      })
      .catch((err) => console.log(err));
  };

  const onGetFieldList = (table_uid, fieldName) => {
    const url = `${DenisCrmHttps}/api/custom_table/get_custom_table_dict_values`;
    const body = JSON.stringify({
      table_uid,
      field_name: fieldName,
    });

    fetch(url, { method: 'POST', headers, body })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('error');
        }
      })
      .then((json) => {
        const formattedArray = json.map((el) => ({
          value: el.value,
          label: el.label,
        }));

        setLists((prevLists) => ({
          ...prevLists,
          [fieldName]: formattedArray,
        }));
      })
      .catch((err) => console.log(err));
  };

  const onGetData = (table_uid) => {
    const url = `${DenisCrmHttps}/api/custom_table/get_custom_table_list_field`;
    const body = JSON.stringify({ table_uid });

    fetch(url, { method: 'POST', headers, body })
      .then((res) =>
        res.ok ? res.json() : Promise.reject('ERROR: get_tables_attr request')
      )
      .then((json) => {
        setFieldConfig(json.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    onGetData(table_uid);
  }, [table_uid]);

  useEffect(() => {
    if (rowData && fieldConfig.length > 0) {
      const updatedInputs = {};
      fieldConfig.forEach((field) => {
        const fieldName = field[1];
        updatedInputs[fieldName] =
          rowData[fieldName] !== undefined ? rowData[fieldName] : '';
      });
      setInputs(updatedInputs);
    }
  }, [rowData, fieldConfig]);

  return (
    <div
      className="blackout"
      // onClick={(e) => {
      //   if (e.target.classList.contains('blackout')) {
      //     setIsModal(false);
      //   }
      // }}
    >
      <div className="modal-crm-detail">
        <div className="modal-crm-detail__header">
          <h2 className="title">Изменение данных</h2>
          <button
            className="modal-crm__btn-close"
            onClick={() => {
              setIsModal(false);
            }}
          >
            <img src={close_icon} />
          </button>
        </div>
        {fieldConfig.map((field, index) => (
          <div
            key={`${field[1]}-${index}`}
            style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
          >
            {field[2] === 'dictionary' ? (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  onGetFieldList(table_uid, field[1]);
                  console.log(field[1]);
                  console.log(lists[field[1]]);
                }}
              >
                <DropDownSelector
                  // options_prop={lists[field[1]]}
                  options_prop={lists[field[1]]?.filter(
                    (item) => item?.value !== selectedLists[field[1]]?.value
                  )}
                  state={selectedLists[field[1]] || null}
                  setState={(selectedOption) => {
                    setSelectedLists((prev) => ({
                      ...prev,
                      [field[1]]: selectedOption,
                    }));
                    handleInputChange(field[1], selectedOption?.value);
                  }}
                  placeholder={`${field[1]}`}
                  defaultValue={rowData[field[1]]}
                />
              </div>
            ) : (
              <InputDinamycPlaceholder
                classname="autorization__input-field"
                placeholder={`${field[1]}`}
                maxLength={150}
                onChange={(value) => handleInputChange(field[1], value)}
                type="text"
                changeValue={inputs[field[1]] || ''}
                textErr={errors[field[1]]}
                err={!!errors[field[1]]}
                autoComplete={false}
                required
              />
            )}

            <button
              className="modal-crm__btn-close"
              onClick={() => handleInputChange(field[1], '')}
            >
              <img src={close_icon} alt="удалить" />
            </button>
          </div>
        ))}

        <ButtonBasic
          violet
          size="56px"
          text="Сохранить изменения"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export { CrmModalDetail };
