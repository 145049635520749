import { useState, useEffect } from 'react';
import { InputDinamycPlaceholder } from '@lk-gtcom/ecomlab-components';
import {
  KonstantinAccountInSettingsHttps,
  KonstantinAccountInSettingsHttp,
} from '../../fetchUrls';
import Cookies from 'universal-cookie';
import './AccountSettings.scss';
import { ModalSuccessful } from '../Modal/ModalSuccessful/ModalSuccessful';

const AccountSettings = ({ changeDataUser, setChangeDataUser, setValid }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [tel, setTel] = useState('');
  const [errName, setErrName] = useState(false);
  const [errEmail, setErrEmail] = useState(false);
  const [errTel, setErrTel] = useState(false);
  const [err, setErr] = useState('');
  const [isModal, setIsModal] = useState(false);
  const auth = new Cookies().get('auth');

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const telRegex = /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/;

  const validateForm = (isEmailValid, isNameValid, isTelValid) => {
    setValid(isEmailValid && isNameValid && (isTelValid || tel === ''));
  };

  const handleEmailChange = (e) => {
    const value = e;
    setEmail(value);
    validateForm(emailRegex.test(value), name.length >= 3, telRegex.test(tel));
  };

  const handleNameChange = (e) => {
    const value = e;
    setName(value);
    validateForm(emailRegex.test(email), value.length >= 3, telRegex.test(tel));
  };

  const handleTelChange = (e) => {
    const value = e;
    setTel(value);
    validateForm(
      emailRegex.test(email),
      name.length >= 3,
      value === '' || telRegex.test(value)
    );
  };

  const validateEmailBlur = () => {
    setErrEmail(!emailRegex.test(email));
  };

  const validateNameBlur = () => {
    setErrName(name.length < 3);
  };

  const validateTelBlur = () => {
    setErrTel(tel !== '' && !telRegex.test(tel));
  };

  const fetchUser = () => {
    const url = window.location.hostname.match('localhost')
      ? `${KonstantinAccountInSettingsHttp}/web/users/data`
      : `${KonstantinAccountInSettingsHttps}/web/users/data`;

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth}`,
    };

    fetch(url, { method: 'GET', headers })
      .then((res) => res.json())
      .then(({ email, name, phone_number }) => {
        if (email) setEmail(email);
        if (name) setName(name);
        if (phone_number) setTel(phone_number);
      })
      .catch((err) => console.error(err));
  };

  const fetchChangeUserData = () => {
    const url = window.location.hostname.match('localhost')
      ? `${KonstantinAccountInSettingsHttp}/web/users/data`
      : `${KonstantinAccountInSettingsHttps}/web/users/data`;

    const body = JSON.stringify({ name, email, phone_number: tel });
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth}`,
    };

    fetch(url, { body, method: 'POST', headers })
      .then((res) =>
        res.json().then((json) => {
          if (json?.detail === 'User does not exist') {
            setErr('Такого пользователя не существует');
          } else {
            setIsModal(true);
          }
        })
      )
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (changeDataUser) {
      validateNameBlur();
      validateEmailBlur();
      if (tel?.length) {
        validateTelBlur();
      }
      if (tel?.length > 0) {
        if (!errName && !errEmail && !errTel) {
          fetchChangeUserData();
        }
      } else {
        if (!errName && !errEmail) {
          fetchChangeUserData();
        }
      }
      setChangeDataUser(false);
    }
  }, [changeDataUser]);

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <>
      {isModal && (
        <ModalSuccessful
          title="Данные аккаунта успешно изменены"
          setIsModal={(e) => setIsModal(e)}
        />
      )}
      <div className="account-settings">
        <h2 className="title" style={{ margin: 0 }}>
          Настройки аккаунта
        </h2>

        <InputDinamycPlaceholder
          onChange={(e) => handleEmailChange(e)}
          onBlur={validateEmailBlur}
          changeValue={email}
          type="text"
          err={errEmail}
          textErr="Неверная электронная почта"
          classname="autorization__input-field"
          placeholder="Электронная почта"
          autoComplete={false}
        />

        <InputDinamycPlaceholder
          onChange={(e) => handleNameChange(e)}
          onBlur={validateNameBlur}
          changeValue={name}
          type="text"
          err={errName}
          textErr="Имя должно содержать минимум 3 символа"
          classname="autorization__input-field"
          placeholder="Имя пользователя"
          autoComplete={false}
        />

        <InputDinamycPlaceholder
          onChange={(e) => handleTelChange(e)}
          onBlur={validateTelBlur}
          changeValue={tel}
          type="tel"
          err={errTel}
          textErr="Неверный номер телефона"
          classname="autorization__input-field"
          placeholder="Контактный телефон"
          autoComplete={false}
          mask={'+{7} (000) 000-00-00'}
        />
      </div>

      <div className="err-box">
        {err && <span className="text text_red">{err}</span>}
      </div>
    </>
  );
};

export { AccountSettings };
