import React, { useState, useEffect } from 'react';
import './CrmAttrControlModal.scss';
import close_icon from './img/close_icon.svg';
import { RavshanHttps, DenisCrmHttps } from '../../fetchUrls';
import sort_icon from './img/sort.svg';
import edit_icon from './img/edit.svg';
import {
  Checkbox,
  ButtonBasic,
  TextareaDynamicPlaceholder,
  InputSearch,
} from '@lk-gtcom/ecomlab-components';
import customHeaders, { getSpace } from '../../common/headers';
import useGeneralStore from '../../store/general';
import { ButtonCopyCrm } from './ButtonCopyCrm';

export const CrmAttrControlModal = (props) => {
  const {
    isOpen,
    close,
    objValuesAttr,
    currentIndex,
    setObjValuesAttr,
    tableId,
    fieldName,
  } = props;

  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [listPoints, setListPoints] = useState(
    objValuesAttr[currentIndex]?.attr_values ?? []
  );
  const [checkedValues, setCheckedValues] = useState([]);
  const [textareaValue, setTextareaValue] = useState('');
  const [existingValues, setExistingValues] = useState([]);

  const fetchExistingValues = () => {
    const url = `${DenisCrmHttps}/api/custom_table/get_custom_table_dict_values`;
    const body = JSON.stringify({
      table_uid: tableId,
      field_name: String(fieldName),
    });

    fetch(url, { method: 'POST', headers, body })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error('Failed to fetch existing values');
      })
      .then((data) => {
        setListPoints(data?.map((item) => item) || []);
        setExistingValues(data?.map((item) => item) || []);
      })
      .catch((err) => console.error(err));
  };

  const handleDeleteCheckedAttr = () => {
    setListPoints((prevPoints) =>
      prevPoints.filter((_, index) => !checkedValues.includes(index))
    );
    setCheckedValues([]);
  };

  const handleAddPoints = () => {
    if (!textareaValue.trim()) return;

    const newItems = textareaValue
      .split('\n')
      .filter((line) => line.trim() && !existingValues.includes(line.trim()));

    setListPoints((prev) => [...prev, ...newItems]);
    setTextareaValue('');
  };

  const fetchAddValueInDictionaryField = () => {
    const url = `${DenisCrmHttps}/api/custom_table/add_value_in_dictionary_field`;

    const itemsToAdd = listPoints
      .filter((item) => !existingValues.includes(item))
      .map((value) => ({ value }));

    if (itemsToAdd.length === 0) return;

    const body = JSON.stringify({
      table_uid: tableId,
      field_name: fieldName,
      data: itemsToAdd,
    });

    fetch(url, { body, method: 'POST', headers })
      .then((res) => {
        if (res.ok) return res.json();
        throw new Error('Failed to add new values');
      })
      .then((json) => {
        setExistingValues((prev) => [
          ...prev,
          ...itemsToAdd.map((item) => item.value),
        ]);
      })
      .catch((err) => console.error('Error adding values:', err));
  };

  const handleDeleteField = () => {
    const url = `${RavshanHttps}/api/custom_table/delete_value_in_dictionary_field`;

    const itemsToDelete = checkedValues.map((index) => ({
      value_uid: listPoints[index]?.value,
    }));

    const body = JSON.stringify({
      table_uid: tableId,
      field_name: fieldName,
      data: itemsToDelete,
    });

    fetch(url, { method: 'DELETE', headers, body })
      .then((res) => (res.ok ? res.json() : Promise.reject('ERROR')))
      .then((json) => {
        setExistingValues((prev) =>
          prev.filter((item, idx) => !checkedValues.includes(idx))
        );
        setListPoints((prev) =>
          prev.filter((item, idx) => !checkedValues.includes(idx))
        );
      })
      .catch((err) => console.error('Error deleting values:', err));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleAddPoints();
    }
  };

  useEffect(() => {
    if (isOpen) fetchExistingValues();
  }, [isOpen]);

  const [showGenModal, setShowGenModal] = useState(false);
  const [copiedFields, setCopiedFields] = useState([]);

  const handleCheckboxChange = (fieldId) => {
    setCopiedFields((prev) =>
      prev.includes(fieldId)
        ? prev.filter((id) => id !== fieldId)
        : [...prev, fieldId]
    );
  };

  const generateRequestBody = () => {
    return {
      table_uid: tableId,
      field_name: fieldName,
      fields_data: copiedFields.map((field) => ({
        name: field?.label,
        field_uid: field?.value,
      })),
    };
  };

  if (!isOpen) return null;

  return (
    <div
      className="modal-crm-attr"
      // onClick={close}
    >
      <div
        className="modal-crm-attr__main-content"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%',
          flexDirection: 'column',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-crm-attr__header">
          <h2 className="modal-crm-attr__title">Управление списком</h2>

          {tableId && (
            <ButtonBasic
              width={'100px'}
              className="modal-crm__btn-generate"
              onClick={() => setShowGenModal(!showGenModal)}
              text={'Сформировать'}
            />
          )}
          <button
            className="modal-crm-attr__btn-close"
            onClick={() => {
              close();
              setShowGenModal(false);
            }}
          >
            <img src={close_icon} alt="Close" />
          </button>
        </div>

        <div className="modal-crm-attr__content">
          <div className="modal-crm-attr__item">
            <h3 className="modal-crm-attr__title_min">
              Добавить элементы списка
            </h3>

            <div className="modal-crm-attr__new-el-box">
              <div className="modal-crm-attr__header-new">
                <h4 className="modal-crm-attr__title-new">
                  Новые элементы списка
                </h4>
                <ButtonBasic
                  lilac
                  width="105px"
                  onClick={handleAddPoints}
                  text="Добавить"
                />
              </div>

              <div className="modal-crm-attr__input-item">
                <TextareaDynamicPlaceholder
                  changeValue={textareaValue}
                  width={'100%'}
                  placeholder="Введите значения списка через Enter"
                  onChange={(e) => setTextareaValue(e)}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
          </div>

          <div className="modal-crm-attr__item">
            <h4>Изменить список</h4>

            <InputSearch />

            <div className="modal-crm-attr__medium-panel">
              <button className="modal-crm-attr__btn-sort">
                Сортировать по алфавиту
                <img src={sort_icon} alt="Sort" />
              </button>

              <ButtonBasic
                lilac
                width="100px"
                text="Удалить"
                onClick={() => {
                  handleDeleteCheckedAttr();
                  handleDeleteField();
                }}
              />
            </div>
            <div className="modal-crm-attr__checkbox-group">
              {listPoints.map((item, idx) => (
                <label className="modal-crm-attr__label" key={idx}>
                  <button className="modal-crm-attr__btn-edit">
                    <img src={edit_icon} alt="Edit" />
                  </button>
                  <Checkbox
                    value={checkedValues.includes(idx)}
                    onChange={() =>
                      setCheckedValues((prev) =>
                        prev.includes(idx)
                          ? prev.filter((i) => i !== idx)
                          : [...prev, idx]
                      )
                    }
                  />
                  {item?.label || item}
                  {showGenModal && (
                    <Checkbox
                      value={copiedFields.includes(item)}
                      onChange={() => handleCheckboxChange(item)}
                    >
                      <p style={{ fontSize: '10px' }}>Copy ID</p>
                    </Checkbox>
                  )}
                </label>
              ))}
            </div>
          </div>
        </div>

        <div className="modal-crm-attr__btn-group">
          <ButtonBasic
            green
            width="110px"
            text="Сохранить"
            onClick={() => {
              fetchAddValueInDictionaryField();
              close();
            }}
          />
          <ButtonBasic grey width="110px" text="Отменить" onClick={close} />
        </div>
      </div>
      {showGenModal && (
        <div className="modal-crm__generated-modal">
          <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
            <h3>Для записи элементов списка используйте метод:</h3>
            <button
              className="modal-crm__btn-close"
              onClick={() => {
                setShowGenModal(false);
                setCopiedFields([]);
              }}
            >
              <img src={close_icon} />
            </button>
          </div>
          <div style={{ display: 'flex', gap: '12px' }}>
            <h3>URL:</h3>
            <p style={{ textDecoration: 'underline', fontSize: '18px' }}>
              https://asdb.com/add/item
            </p>
          </div>
          <hr />
          <h3>С телом запроса:</h3>
          <pre>{JSON.stringify(generateRequestBody(), null, 2)}</pre>
        </div>
      )}
    </div>
  );
};
