import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { TabsGreen } from '../../../components/TabsGreen/TabsGreen';
import { InputDinamycPlaceholder, ButtonBasic, RangeBox } from '@lk-gtcom/ecomlab-components';
import close from './img/close.svg'
import alarm_exclamation from './img/bx-alarm-exclamation.svg'
import columns from './img/bx-columns.svg'
import select_multiple from './img/bx-select-multiple.svg'
import { RavshanHttps, RavshanHttp } from '../../../fetchUrls';
import customHeaders, { getSpace } from '../../../common/headers';
import useGeneralStore from '../../../store/general';
import './ModalCreateBoard.scss'

const btns = [
    { label: 'Обычная доска', value: 'board', icon: columns },
    { label: 'Спринт доска', value: 'sprint-board', icon: alarm_exclamation },
    { label: 'Таск-лист', value: 'task-list', icon: select_multiple },
    { label: 'Вики', value: 'wiki', icon: select_multiple },
];

const ModalCreateBoard = ({
    setIsModal,
    taskId,
    fetchGetBord,
    setIsEdit,
    isEdit,
    currentBord
}) => {

    const selectedSpace = useGeneralStore((state) => state.selectedSpace);
    const headers = { ...customHeaders, ...getSpace(selectedSpace) };
    const [currentTab, setCurrentTab] = useState(btns[0].value)
    const [name, setName] = useState(isEdit ? currentBord?.name : '')
    const [date, setDate] = useState(
        [new Date().getTime() - 604800000, new Date().getTime()]
    )
    const [dateFrom, dateTo] = date

    // const formateDate = (date) => {
    //     const timeZone = new Date().getTimezoneOffset() * 60000;
    //     const formate_date = new Date(
    //       new Date(date)?.getTime() + timeZone
    //     ).toUTCString();
    //     return new Date(formate_date);
    //   };

    const formateDate = (date) => {
        const timeZone = (new Date().getTimezoneOffset() / 60) * -3600000;
        const formate_date = new Date(
            new Date(date)?.getTime() + timeZone
        )?.toUTCString();
        return new Date(formate_date);
    };

    useEffect(() => {
        if (isEdit && currentBord) {
            setDate([new Date(currentBord?.start_time).getTime(), new Date(currentBord?.end_time).getTime()])
        }
    }, [isEdit]);

    const fetchCreateBord = () => {
        let url;
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/v3/tasks/board/${isEdit ? 'change_task_board' : 'create_task_board'}`;
        } else {
            url = `${RavshanHttp}/api/v3/tasks/board/${isEdit ? 'change_task_board' : 'create_task_board'}`;
        }

        const task_board_id = isEdit ? { task_board_id: currentBord?.id } : {}

        const body = JSON.stringify({
            name: name,
            task_id: taskId,
            start_time: formateDate(date[0]).toISOString().split('T')?.[0],
            end_time: formateDate(date[1]).toISOString().split('T')?.[0],
            ...task_board_id
        });

        fetch(url, { body, method: isEdit ? 'PUT' : 'POST', headers })
            .then((res) => res.json())
            .then((json) => {
                console.log(json);
                fetchGetBord()
            })
            .catch((err) => console.error(err));
    };

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsEdit(false)
                setIsModal(false)
            }
        }}>
            <div className='modal-create-board'>
                <div className='modal-create-board__header'>
                    <h1 className='modal-create-board__title'>Создание доски</h1>
                    <InputDinamycPlaceholder
                        changeValue={name}
                        onChange={(e) => setName(e)}
                        placeholder="Название доски"
                        size='s'
                    />

                    <ButtonBasic
                        width='190px'
                        grey
                        text='Сохранить '
                        onClick={(e) => {
                            fetchCreateBord()
                            setTimeout(() => {
                                fetchGetBord()
                            }, 500)
                            setIsEdit(false)
                            setIsModal(false)
                        }}
                    />

                    <button className='modal-create-board__btn-close'>
                        <img src={close} />
                    </button>
                </div>

                <div className='modal-create-board__row'>
                    <h2 className='modal-create-board__title-medium'>Выберите тип доски</h2>
                    <TabsGreen
                        btns={btns}
                        currentBtn={currentTab}
                        setCurrentBtn={(e) => setCurrentTab(e)}
                        checkValue={true}
                    />
                </div>

                <div className='modal-create-board__row'>
                    <h2 className='modal-create-board__title-medium'>Выберите период для спринта</h2>
                    <RangeBox
                        defaultValue={isEdit && [new Date(currentBord?.start_time).getTime(), new Date(currentBord?.end_time).getTime()]}
                        hideBtnGroup
                        btn_period={false}
                        setDate={e => setDate(e)} />
                </div>
            </div>
        </div>
    )
}

export { ModalCreateBoard }