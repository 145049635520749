import { useEffect, useState, useCallback, useRef } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { FilterContainer } from '../../components/FilterContainer/FilterContainer';
import {
  DropDownSelector,
  Checkbox,
  ButtonBasic,
} from '@lk-gtcom/ecomlab-components';
import { JoinedPromotions } from '../../components/JoinedPromotions/JoinedPromotions';
import { ListPromotionVer2 } from '../../components/ListPromotionVer2/ListPromotionVer2';
import { PromotionRules } from '../../components/PromotionRules/PromotionRules';
import useGeneralStore from '../../store/general';
import customHeaders from '../../common/headers';
import './PromotionPage.scss';
import { OlegHttps } from '../../fetchUrls';

const options_prop = [
  { label: 'Ozon', value: 1 },
  { label: 'Wildberries', value: 3 },
  { label: 'Yandex', value: 2 },
];

const fetchFilterData = async ({
  filterName,
  selectedSpace,
  page = 1,
  searchParam = '',
  selectedFilters = {},
  setFilterData,
  setSelectedFilter,
  append = false,
}) => {
  const url = `${OlegHttps}/api/v3/filter/${filterName}`;
  const payload = {
    search_param: searchParam || null,
    page,
    limit: 50,
    api_id_list: selectedFilters.api?.map((api) => api.value) || ['all'],
    category_id_list: selectedFilters.category?.map((cat) => cat.value) || [
      'all',
    ],
    brand_id_list: selectedFilters.brand?.map((brand) => brand.value) || [
      'all',
    ],
    shop_id_list: selectedFilters.shop?.map((shop) => shop.value) || ['all'],
    campaign_id_list: selectedFilters.action?.map((action) => action.value) || [
      'all',
    ],
    selected_id_list: selectedFilters.selected_id || [],
  };
  const headers = {
    ...customHeaders,
    space: selectedSpace,
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      const data = await response.json();
      const formattedData = data.filter_data?.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setFilterData((prevData) =>
        append ? [...prevData, ...formattedData] : formattedData
      );

      if (data.selected?.length) {
        const selectedItems = data.selected.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        setSelectedFilter(selectedItems);
      }
    } else {
      console.log(response.statusText);
    }
  } catch (error) {
    console.log(error);
  }
};

const PromotionsPage = () => {
  const location = useLocation();
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const [apiData, setApiData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [actionData, setActionData] = useState([]);
  const [selectedMarketplace, setSelectedMarketplace] = useState([{ label: 'Все', value: 'all' }]);
  const [selectedApi, setSelectedApi] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedAction, setSelectedAction] = useState([]);

  const [apiPage, setApiPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);
  const [actionPage, setActionPage] = useState(1);

  const [apiQuery, setApiQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');
  const [actionQuery, setActionQuery] = useState('');

  const [check, setCheck] = useState(false);
  const [isModal, setIsModal] = useState(false);

  // const mpParamRef = useRef([]);
  // const actionParamRef = useRef([]);
  // const apiParamRef = useRef([]);

  const [mpParamRef, setMpParamRef] = useState('');
  const [actionParamRef, setActionParamRef] = useState('');
  const [apiParamRef, setApiParamRef] = useState('');

  const handleFetchData = useCallback(
    (
      filterName,
      page,
      query,
      selectedFilters,
      setFilterData,
      setSelectedFilter,
      append = false
    ) => {
      fetchFilterData({
        filterName,
        selectedSpace: selectedSpace?.value,
        page,
        searchParam: query,
        selectedFilters,
        setFilterData,
        setSelectedFilter,
        append,
      });
    },
    [selectedSpace]
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setActionParamRef(params.get('action')?.split(',') || [])
    setApiParamRef(params.get('api_id')?.split(',') || [])
    setMpParamRef(params.get('mp_id')?.split(',') || [])

    // if (mpParamRef.current.length > 0) {
    //   const mappedMarketplace = mpParamRef.current.map((mpId) =>
    //     options_prop.find((option) => String(option.value) === mpId)
    //   );
    //   setSelectedMarketplace(mappedMarketplace);
    // }
  }, [location.search]);

  useEffect(() => {
    handleFetchData(
      'api_id',
      apiPage,
      apiQuery,
      { selected_id: apiParamRef, shop: selectedMarketplace },
      setApiData,
      setSelectedApi,
      apiPage > 1
    );
  }, [apiPage, apiQuery, selectedMarketplace, apiParamRef, handleFetchData]);

  useEffect(() => {
    handleFetchData(
      'category',
      categoryPage,
      categoryQuery,
      { api: selectedApi, shop: selectedMarketplace },
      setCategoryData,
      setSelectedCategory,
      categoryPage > 1
    );
  }, [
    categoryPage,
    categoryQuery,
    selectedApi,
    selectedMarketplace,
    handleFetchData,
  ]);

  useEffect(() => {
    handleFetchData(
      'brand',
      brandPage,
      brandQuery,
      {
        api: selectedApi,
        category: selectedCategory,
        shop: selectedMarketplace,
      },
      setBrandData,
      setSelectedBrand,
      brandPage > 1
    );
  }, [
    brandPage,
    brandQuery,
    selectedApi,
    selectedCategory,
    selectedMarketplace,
    handleFetchData,
  ]);

  useEffect(() => {
    handleFetchData(
      'action',
      actionPage,
      actionQuery,
      {
        selected_id: actionParamRef,
        api: selectedApi,
        shop: selectedMarketplace,
      },
      setActionData,
      setSelectedAction,
      actionPage > 1
    );
  }, [
    actionPage,
    actionQuery,
    selectedApi,
    selectedMarketplace,
    handleFetchData,
  ]);

  useEffect(() => setApiPage(1), [selectedMarketplace]);
  useEffect(() => setCategoryPage(1), [selectedApi, selectedMarketplace]);
  useEffect(
    () => setBrandPage(1),
    [selectedApi, selectedCategory, selectedMarketplace]
  );
  useEffect(
    () => setActionPage(1),
    [selectedApi, selectedCategory, selectedBrand, selectedMarketplace]
  );

  const getTitle = () => {
    if (location.pathname.includes('joined-promotions'))
      return 'Товары в акции';
    if (location.pathname.includes('list-promotion')) return 'Список акций';
    if (location.pathname.includes('promotion-rules')) return 'Правила';
  };

  const filters = (
    <div className="promotion_page__filters">
      <FilterContainer>
        <DropDownSelector
          options_prop={options_prop}
          state={selectedMarketplace}
          setState={setSelectedMarketplace}
          placeholder="Площадка"
          multi
        />
        <DropDownSelector
          options_prop={apiData}
          state={selectedApi}
          setState={setSelectedApi}
          placeholder="Магазин"
          fetchCallback={setApiPage}
          setInputChange={setApiQuery}
          multi
        />
        <DropDownSelector
          options_prop={categoryData}
          state={selectedCategory}
          setState={setSelectedCategory}
          placeholder="Категория"
          fetchCallback={setCategoryPage}
          setInputChange={setCategoryQuery}
          defaultValue={[{ label: 'Все', value: 'all' }]}
          multi
        />
        <DropDownSelector
          options_prop={brandData}
          state={selectedBrand}
          setState={setSelectedBrand}
          placeholder="Бренд"
          fetchCallback={setBrandPage}
          setInputChange={setBrandQuery}
          defaultValue={[{ label: 'Все', value: 'all' }]}
          multi
        />
        {location.pathname.includes('joined-promotions') && (
          <DropDownSelector
            options_prop={actionData}
            state={selectedAction}
            setState={setSelectedAction}
            placeholder="Акции"
            fetchCallback={setActionPage}
            setInputChange={setActionQuery}
            defaultValue={[{ label: 'Все', value: 'all' }]}
            multi
          />
        )}
        {location.pathname.includes('joined-promotions') && (
          <Checkbox value={check} onChange={() => setCheck(!check)}>
            <p className="label-check">Без 0 остатков</p>
          </Checkbox>
        )}
      </FilterContainer>
      <div className="promotion_page__group-btn">
        {location.pathname.includes('promotion-rules') && (
          <ButtonBasic
            green
            onClick={() => setIsModal(true)}
            width="auto"
            text="Добавить правило +"
          />
        )}
      </div>
    </div>
  );

  return (
    <div className="settings__content">
      <div className="promotion_page">
        <h1 className="title_main">{getTitle()}</h1>
        {filters}
        <Routes>
          <Route
            path="joined-promotions/*"
            element={
              <JoinedPromotions
                selectedMarketplace={selectedMarketplace}
                selectedApi={selectedApi}
                selectedCategory={selectedCategory}
                selectedBrand={selectedBrand}
                selectedAction={selectedAction}
                check={check}
              />
            }
          />
          <Route
            path="list-promotion/*"
            element={
              <ListPromotionVer2
                setShowTooltipLauncAdvertisingCampaign={() => {}}
                selectedMarketplace={selectedMarketplace}
                selectedApi={selectedApi}
                selectedCategory={selectedCategory}
                selectedBrand={selectedBrand}
              />
            }
          />
          <Route
            path="promotion-rules/*"
            element={
              <PromotionRules
                isModal={isModal}
                setIsModal={setIsModal}
                selectedMarketplace={selectedMarketplace}
                selectedApi={selectedApi}
                selectedCategory={selectedCategory}
                selectedBrand={selectedBrand}
              />
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export { PromotionsPage };
