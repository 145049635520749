import customHeaders, { getSpace } from '../../../common/headers';
import { Link, useNavigate } from 'react-router-dom';
import { Rating } from '@mui/material';
import { SliderRange } from '../../Slider/Slider';
import { useState, useEffect } from 'react';
import { ReactComponent as StarIcon } from './img/rating_star.svg';
import {
  KonstantinFeedbackHttps,
  KonstantinFeedbackHttp,
} from './../../../fetchUrls';
import { SelectedRating } from '../../SelectedRating/SelectedRating';
import { SelectedRatingMulti } from '../../SelectedRatingMulti/SelectedRatingMulti';
import {
  DropDownSelector,
  CheckboxToggle,
  TextareaDynamicPlaceholder,
  ButtonBasic,
  InputDinamycPlaceholder,
} from '@lk-gtcom/ecomlab-components';
import {
  fetchApiReviewsFilter,
  fetchFeedbacksBrandFilter,
  fetchFeedbacksCategoryFilter,
} from '../../../common/filtersFunc';
import useGeneralStore from '../../../store/general';
import close_icon from './img/bx-x.svg.svg';
import './ModalNewScenario.scss';

const optionRating = (rating) => {
  return (
    <div className="rating-option">
      <span className="text_span">{rating}</span>
      <Rating
        precision={1}
        defaultValue={rating}
        max={1}
        readOnly
        emptyIcon={<StarIcon style={{ opacity: '.1' }} />}
        icon={<StarIcon style={{ fill: '#faaf00' }} />}
        size="small"
      />
    </div>
  );
};

const textOptions = [
  {
    label: 'Отвечать только на отзывы с текстом (комментарием покупателя)',
    value: true,
  },
  {
    label: 'Отвечать только на отзывы без текста (комментария покупателя) ',
    value: false,
  },
  {
    label:
      'Отвечать на отзывы независимо от наличия текста (комментария покупателя)',
    value: null,
  },
];

const photoOptions = [
  {
    label: 'Отвечать только на отзывы с фото',
    value: true,
  },
  {
    label: 'Отвечать только на отзывы без фото',
    value: false,
  },
  {
    label: 'Отвечать на отзывы независимо от наличия фото',
    value: null,
  },
];

const questionSignOptions = [
  {
    label: 'Отвечать только на отзывы, в тексте которых нет знака вопроса',
    value: true,
  },
  {
    label: 'Отвечать только на отзывы, в тексте которых есть знак вопроса',
    value: false,
  },
  {
    label: 'Отвечать на отзывы независимо от наличия в их тексте знака вопроса',
    value: null,
  },
];

const template_selection_option = [
  {
    label: 'На отзывы с одинаковой оценкой для одного товара ответы чередуются (не повторяются 2 раза подряд)',
    value: true
  },
  {
    label: 'Шаблоны для ответа выбираются рандомно',
    value: false
  }
]

const mpData = [
  {
    label: 'Ozon',
    value: 1,
  },
  {
    label: 'Wildberries',
    value: 3,
  },
  {
    label: 'Yandex',
    value: 2,
  },
];

const ModalNewScenario = ({
  setIsModal,
  selectedApiProps,
  fetchRuleTable,
  setIsEdit,
  isEdit,
  scenarioData,
  setScenarioData,
}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [selectedResponseFormat, setSelectedResponseFormat] = useState([]);
  const [selectedTemplateFormat, setSelectedTemplateFormat] = useState([]);
  const [isCheck, setIsCheck] = useState(false);

  const [titleValue, setTitleValue] = useState('');
  const [title, setTitle] = useState('');

  const [description, setDescription] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');

  const [stateSlider, setStateSlider] = useState(30);
  const [selectedBrand1, setSelectedBrand1] = useState([]);
  const [selectedBrand2, setSelectedBrand2] = useState([]);
  const [selectedCategory1, setSelectedCategory1] = useState([]);
  const [selectedCategory2, setSelectedCategory2] = useState([]);

  const [selectedTextFilter, setSelectedTextFilter] = useState([]);
  const [selectedPhotoFilter, setSelectedPhotoFilter] = useState([]);
  const [selectedQuestionFilter, setSelectedQuestionFilter] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState([])

  const [groupList, setGroupList] = useState([]);
  const [ruleId, setRuleId] = useState(-1);

  const [apiData, setApiData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);

  const [selectedApi, setSelectedApi] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedMarketplace, setSelectedMarketplace] = useState([]);
  const [selectedRating, setSelectedRating] = useState([1, 2, 3, 4, 5]);
  const [selectedStatus, setSelectedStatus] = useState('Все');
  const [selectedGroup, setSelectedGroup] = useState([]);

  const [apiPage, setApiPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);

  const [apiQuery, setApiQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [allPeriod, setAllPeriod] = useState(false);
  const [dateFrom, dateTo] = date;

  const [pageValue, setPageValue] = useState();
  const [stopWordList, setStopWordList] = useState('');
  const [stopWordValue, setStopWordValue] = useState('');

  const fetchGroupList = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/answers/groups?limit=100&offset=0`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/answers/groups?limit=100&offset=0`;
    }

    fetch(url, { method: 'GET', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setGroupList(
          json.map(({ group_name, id }) => ({ label: group_name, value: id }))
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const createRule = () => {
    if (title?.length < 1) {
      return;
    }
    let url;
    if (!window.location.hostname.match('localhost')) {
      if (isEdit) {
        url = `${KonstantinFeedbackHttps}/feedbacks/front-rules/${ruleId}`;
      } else {
        url = `${KonstantinFeedbackHttps}/feedbacks/front-rules`;
      }
    } else {
      if (isEdit) {
        url = `${KonstantinFeedbackHttp}/feedbacks/front-rules/${ruleId}`;
      } else {
        url = `${KonstantinFeedbackHttp}/feedbacks/front-rules`;
      }
    }

    const body = JSON.stringify({
      rule_name: title,
      description,
      active: isCheck,
      attributes: {
        mp_id: selectedMarketplace?.value,
        api_id_list: selectedApi,
        rating_list: selectedRating,
        answer_group_id: selectedGroup,
        rotate_answer_for_same_product_and_rating: selectedTemplate?.value,
        has_text: selectedTextFilter?.value,
        has_photo: selectedPhotoFilter?.value,
        without_question: selectedQuestionFilter?.value,
        category_id_list: selectedCategory1,
        brand_id_list: selectedBrand1,
        stop_category_id_list: selectedCategory2,
        stop_brand_id_list: selectedBrand2,
        stop_word_list: (stopWordList?.length > 0 && !stopWordList[0]) ? null : stopWordList,
        answer_delay: stateSlider
      },
    });

    fetch(url, { body, method: isEdit ? 'PUT' : 'POST', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setTimeout(() => {
          fetchRuleTable([], 'custom_scenarios');
        }, 600);
        setScenarioData({});
        setIsEdit(false);
        setIsModal(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchRule = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/front-rules/${scenarioData?.id}`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/front-rules/${scenarioData?.id}`;
    }

    fetch(url, { method: 'GET', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        const { rule_id, rule_name, active, attributes, description } = json;

        console.log(template_selection_option.filter(({ label, value }) => value == attributes?.rotate_answer_for_same_product_and_rating))

        setRuleId(rule_id);
        setTitleValue(rule_name);
        setIsCheck(active)
        setSelectedMarketplace(
          mpData?.filter((el) => el?.value === attributes?.mp_id)[0]
        );
        setSelectedApi(attributes?.api_id_list);
        setSelectedGroup(attributes?.answer_group_id);
        setSelectedCategory1(attributes?.category_id_list);
        setSelectedBrand1(attributes?.brand_id_list);
        setSelectedCategory2(attributes?.stop_category_id_list);
        setSelectedBrand2(attributes?.stop_brand_id_list);
        setSelectedRating(attributes?.rating_list);
        const rotate_answer = template_selection_option.filter(({ label, value }) => value == attributes?.rotate_answer_for_same_product_and_rating)
        setSelectedTemplate(Array.isArray(rotate_answer) ? rotate_answer[0] : {})
        // setSelectedRating(ratingData?.filter(el => attributes?.rating_list?.indexOf(el?.value) !== -1))
        setSelectedQuestionFilter(
          questionSignOptions?.filter(
            (el) => attributes?.without_question === el?.value
          )
        );

        setSelectedPhotoFilter(
          photoOptions?.filter((el) => attributes?.has_photo === el?.value)
        );
        setSelectedTextFilter(
          textOptions?.filter((el) => attributes?.has_text === el?.value)
        );
        setStopWordValue(attributes?.stop_word_list?.join(',', ','));
        setStateSlider(attributes?.answer_delay);
        setDescriptionValue(description);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (isEdit) {
      if (scenarioData?.id) {
        fetchRule();
      }
    }
  }, [isEdit, scenarioData, selectedSpace]);

  useEffect(() => {
    fetchGroupList();
  }, [selectedSpace]);

  useEffect(() => {
    fetchApiReviewsFilter({
      selectedSpace: selectedSpace?.value,
      selectedMarketplace: selectedMarketplace,
      withAll: false,
      page: apiPage,
      setApiData: (e) => setApiData(e),
      searchParam: apiQuery,
      type: 'wb',
    });
  }, [apiPage, apiQuery, selectedMarketplace, selectedSpace]);

  useEffect(() => {
    fetchFeedbacksCategoryFilter({
      selectedSpace: selectedSpace?.value,
      page: categoryPage,
      apiData,
      dateFrom,
      dateTo,
      allPeriod,
      selectedApi,
      pageValue: 'feedbacks',
      selectedBrand: selectedBrand1,
      selectedCategory,
      setCategoryData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
    });
  }, [
    selectedSpace,
    apiData,
    dateFrom,
    dateTo,
    allPeriod,
    selectedApi,
    pageValue,
    selectedBrand1,
    categoryPage,
    categoryQuery,
  ]);

  useEffect(() => {
    fetchFeedbacksBrandFilter({
      selectedSpace: selectedSpace?.value,
      page: brandPage,
      apiData,
      categoryData,
      selectedCategory,
      pageValue: 'feedbacks',
      selectedBrand: selectedBrand1,
      selectedApi,
      dateFrom,
      dateTo,
      allPeriod,
      searchParam: brandQuery,
      setBrandData: (e) => setBrandData(e),
    });
  }, [
    selectedSpace,
    categoryData,
    selectedApi,
    selectedCategory,
    pageValue,
    selectedBrand1,
    brandPage,
    brandQuery,
  ]);

  return (
    <div
      className="blackout"
    // onClick={(e) => {
    //   let el = e.target;
    //   if (el.classList.contains('blackout')) {
    //     setIsModal(false);
    //     setIsEdit(false);
    //     setScenarioData({});
    //   }
    // }}
    >
      <div className="modal-new-scenario">
        <div className="modal-new-scenario__header">
          <h1 className="title_main">
            {isEdit ? 'Редактирование сценария' : 'Новый сценарий'}
          </h1>
          <label className="modal-new-scenario__label">
            <span>{isCheck ? 'Активен' : 'Не активен'}</span>
            <CheckboxToggle
              value={isCheck}
              onChange={(e) => setIsCheck(!isCheck)}
            />
          </label>
          <button
            className="modal-adding-ready-scenario__btn-close"
            onClick={(e) => {
              setIsModal(false);
              setIsEdit(false);
              setScenarioData({});
            }}
          >
            <img src={close_icon} />
          </button>
        </div>
        <div className="modal-new-scenario__content">
          <InputDinamycPlaceholder
            position="left"
            maxWidth="100%"
            isOpen={true}
            onChange={(value) => {
              setTitle(value);
            }}
            changeValue={titleValue}
            placeholder="Введите название сценария "
            width="100%"
            size="s"
          />

          <div className="filter-group">
            {/* <DropDownSelector
                            state={selectedResponseFormat}
                            options_prop={[]}
                            setState={e => setSelectedResponseFormat(e)}
                            placeholder='Как отвечать'
                        /> */}

            <DropDownSelector
              state={selectedMarketplace}
              options_prop={mpData}
              setState={(e) => setSelectedMarketplace(e)}
              placeholder="Маркетплейс"
              defaultValue={[{ label: 'Ozon', value: 1 }]}
            />
            <DropDownSelector
              state={selectedApi}
              options_prop={apiData}
              setState={(e) => setSelectedApi(e)}
              placeholder="Магазин"
              all={false}
              fetchCallback={(e) => setApiPage(e)}
              setInputChange={(e) => setApiQuery(e)}
              multi
            />
            <DropDownSelector
              state={selectedGroup}
              options_prop={groupList}
              setState={(e) => setSelectedGroup(e)}
              placeholder="Выберите группу"
              positionMenu
            />
          </div>
          <div className="points">
            <div className="bottom-content">
              <p className="modal-new-scenario__text">Отвечать только</p>
              <div className="filter-group">
                <DropDownSelector
                  state={selectedCategory1}
                  options_prop={categoryData}
                  setState={(e) => setSelectedCategory1(e)}
                  placeholder="Категория"
                  multi={true}
                  fetchCallback={(e) => setCategoryPage(e)}
                  setInputChange={(e) => setCategoryQuery(e)}
                />
                <DropDownSelector
                  state={selectedBrand1}
                  options_prop={brandData}
                  setState={(e) => setSelectedBrand1(e)}
                  placeholder="Бренд"
                  positionMenu
                  multi={true}
                  fetchCallback={(e) => setBrandPage(e)}
                  setInputChange={(e) => setBrandQuery(e)}
                />
              </div>
            </div>

            <div className="bottom-content">
              <p className="modal-new-scenario__text">Кроме</p>
              <div className="filter-group">
                <DropDownSelector
                  state={selectedCategory2}
                  options_prop={categoryData}
                  setState={(e) => setSelectedCategory2(e)}
                  placeholder="Категория"
                  multi={true}
                  fetchCallback={(e) => setCategoryPage(e)}
                  setInputChange={(e) => setCategoryQuery(e)}
                />

                <DropDownSelector
                  state={selectedBrand2}
                  options_prop={brandData}
                  setState={(e) => setSelectedBrand2(e)}
                  placeholder="Бренд"
                  multi={true}
                  positionMenu
                  fetchCallback={(e) => setBrandPage(e)}
                  setInputChange={(e) => setBrandQuery(e)}
                />
              </div>
            </div>


            <div className="top-content">
              <p className="modal-new-scenario__text">Учет оценки</p>

              <SelectedRatingMulti
                value={selectedRating}
                onChange={(e) => setSelectedRating(e)}
              />



              {/* <DropDownSelector
                                options_prop={ratingData}
                                state={selectedRating}
                                setState={e => setSelectedRating(e)}
                                placeholder='Рейтинг'
                                multi
                            /> */}
            </div>

            <div className="top-content">
              <p className="modal-new-scenario__text">Вариант выбора шаблона:</p>
              <DropDownSelector
                positionMenu
                options_prop={template_selection_option}
                state={selectedTemplate}
                setState={(e) => setSelectedTemplate(e)}
                placeholder="Выберите вариант"
              />
            </div>

            <div className="top-content">
              <p className="modal-new-scenario__text">Учет знака вопроса в тексте отзыва:</p>
              <DropDownSelector
                positionMenu
                options_prop={questionSignOptions}
                state={selectedQuestionFilter}
                setState={(e) => setSelectedQuestionFilter(e)}
                placeholder="Выберите вариант"
              />
            </div>

            <div className="top-content">
              <p className="modal-new-scenario__text">Учет фото в отзыве</p>
              <DropDownSelector
                positionMenu
                options_prop={photoOptions}
                state={selectedPhotoFilter}
                setState={(e) => setSelectedPhotoFilter(e)}
                placeholder="Выберите вариант"
              />
            </div>

            <div className="top-content">
              <p className="modal-new-scenario__text">Учет текста отзыва</p>
              <DropDownSelector
                positionMenu
                options_prop={textOptions}
                state={selectedTextFilter}
                setState={(e) => setSelectedTextFilter(e)}
                placeholder="Выберите вариант"
              />
            </div>

            <div className="top-content">
              <p className="modal-new-scenario__text">Использовать фильтр стоп слов</p>
              {/* <CheckboxToggle /> */}
            </div>

            <div className="bottom-content">
              <TextareaDynamicPlaceholder
                width="100%"
                changeValue={stopWordValue}
                placeholder="Введите слова списком, через запятую, например: красн, желт, зелен и тд."
                onChange={(e) => setStopWordList(e?.split(','))}
              />
            </div>

            <div className="top-content">
              <p className="modal-new-scenario__text">Задержка отправки автоответа</p>
              {/* <CheckboxToggle /> */}
            </div>

            <SliderRange
              minValue={0}
              maxValue={300}
              minMax={false}
              range={false}
              // step={30}
              setState={(e) => {
                if (typeof e === 'number') {
                  setStateSlider(e);
                }
              }}
              defaultValue={stateSlider}
            />

            <div className="modal-new-scenario__input-box">
              <input
                value={stateSlider}
                min={0}
                max={300}
                onChange={(e) => setStateSlider(Number(e.target.value))}
                type="number"
              />
              <span>минут</span>
            </div>

            <div className="bottom-content">
              <TextareaDynamicPlaceholder
                width="100%"
                changeValue={descriptionValue}
                placeholder="Описание"
                onChange={(value) => {
                  setDescription(value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="modal-new-scenario__btn-box">
          <ButtonBasic
            green
            width="200x"
            size="56px"
            text={isEdit ? 'Редактировать сценарий' : 'Создать сценарий'}
            onClick={(e) => createRule()}
          />
        </div>
      </div>
    </div>
  );
};

export { ModalNewScenario };
