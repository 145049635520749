import { FileDropZone } from 'react-file-uploadify'
import 'react-file-uploadify/dist/index.css'
import Cookies from 'universal-cookie'
import React, { useState, useEffect } from "react";
import { KonstantinAddImgHttps } from '../../fetchUrls';
import myClassNames from './myClassNames.module.scss'


const DownloadComponent = ({
    setFilesArr,
    filesdata,
    isSaveFiles,
    setIsSaveFiles,
    setFilesData,
    setSaveFile,
    comment_id,
    type,
    refProgress
}) => {
    const cookies = new Cookies()
    const token = cookies.get('auth')
    const [files, setFiles] = useState([])

    const updateFiles = (incomingFiles) => {
        setFiles(incomingFiles);
    };

    const [save, setSave] = useState([])

    const fetchPostFile = (file) => {
        if (file) {
            const fd = new FormData();
            fd.append('files', file);
            const url = `${KonstantinAddImgHttps}/clients/files?file_group=task`;
    
            const requestHeaders = {
                'Accept': 'application/json',
                'Cache-control': 'no-cache',
                'Authorization': `Bearer ${token}`
            };
    
            // Создаем новый объект XMLHttpRequest
            const xhr = new XMLHttpRequest();
    
            // Устанавливаем метод и URL
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Cache-control', 'no-cache');
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    
            // Отслеживаем прогресс загрузки
            xhr.upload.addEventListener('progress', (event) => {
                if (event.lengthComputable) {
                    // Вычисляем процент загрузки
                    const percent = Math.round((event.loaded * 100) / event.total);
                    refProgress.current.value = percent
                    // Здесь вы можете обновить состояние для отображения прогресса, например, setProgress(percent)
                }
            });

            
    
            // Обрабатываем успешную загрузку
            xhr.onload = () => {
                if (xhr.status === 200) {
                    const json = JSON.parse(xhr.responseText);
                    if (json.files) {
                        if (type == 'description') {
                            setSaveFile([json.files[0]]);
                        } else {
                            setSaveFile((prev) => [...prev, json.files[0]]);
                        }
                    }
                    refProgress.current.value = 0
                } else {
                    console.error('Upload failed: ', xhr.statusText);
                }
            };
    
            // Обрабатываем ошибку
            xhr.onerror = () => {
                console.error('Error occurred during file upload');
            };
    
            // Отправляем данные
            xhr.send(fd);
            
        }
    };

    // const fetchPostFile = async (file) => {
    //     if (!file) {
    //         return;  // Если файл не передан, сразу выходим
    //     }
    
    //     const fd = new FormData();
    //     fd.append('files', file);
    //     const url = `${KonstantinAddImgHttps}/clients/files?file_group=task`;
    
    //     const requestHeaders = {
    //         'Accept': 'application/json',
    //         'Cache-control': 'no-cache',
    //         'Authorization': `Bearer ${token}`,
    //     };
    
    //     try {
    //         const res = await fetch(url, { body: fd, method: 'POST', headers: requestHeaders });
    
    //         if (!res.ok) {
    //             throw new Error(`Ошибка HTTP! Статус: ${res.status}`);
    //         }
    
    //         const reader = res.body.getReader();
    //         const contentLength = +res.headers.get('Content-Length');
            
    //         // Убедитесь, что максимальное значение прогрессбара правильно установлено
    //         if (refProgress?.current) {
    //             refProgress.current.max = contentLength;  // Устанавливаем максимальное значение прогрессбара
    //         }
    
    //         let receivedLength = 0;
    //         let chunks = [];
    
    //         while (true) {
    //             const { done, value } = await reader.read();
    //             if (done) break;
    
    //             chunks.push(value);
    //             receivedLength += value.length;
    
    //             // Обновляем прогрессбар
    //             if (refProgress?.current) {
    //                 console.log(receivedLength)
    //                 refProgress.current.value = receivedLength; // Устанавливаем текущий прогресс
    //             }
    
    //             console.log(`Получено ${receivedLength} из ${contentLength}`);
    //         }
    
    //         // После окончания чтения собираем все чанки
    //         let chunksAll = new Uint8Array(receivedLength);
    //         let position = 0;
    //         for (let chunk of chunks) {
    //             chunksAll.set(chunk, position);
    //             position += chunk.length;
    //         }
    
    //         // Декодируем данные
    //         const result = new TextDecoder("utf-8").decode(chunksAll);
    //         let commits;
    //         try {
    //             commits = JSON.parse(result);
    //         } catch (e) {
    //             console.error('Ошибка при парсинге JSON:', e);
    //             return null;  // Возвращаем null, если произошла ошибка при парсинге
    //         }
    
    //         if (commits && commits.files) {
    //             if (type === 'description') {
    //                 setSaveFile([commits.files[0]]);
    //             } else {
    //                 setSaveFile((prev) => [...prev, commits.files[0]]);
    //             }
    //             refProgress.current.value = 0;
    //             return commits.files[0];
    //         } else {
    //             throw new Error('Файлы не найдены в ответе');
    //         }
    //     } catch (err) {
    //         console.error('Ошибка загрузки файла:', err);
    //         return null;  // Возвращаем null в случае ошибки
    //     }
    // };

    useEffect(() => {
        if (filesdata?.length > 0) {
            let arr = filesdata.map((el) => el.value)
            setFilesArr([save])
        } else {
            setFilesArr(save)
        }
    }, [save]);

    useEffect(() => {
        fetchPostFile(files.at(-1))
    }, [files]);

    useEffect(() => {
        if (save.at(-1)) {
            setFilesData(save)
            setFilesArr([...filesdata, save.at(-1)])
        }
    }, [save]);

    useEffect(() => {
        setFilesData([...filesdata])
    }, []);

    

    return (
        <>

            <div className={myClassNames.container}>

                <FileDropZone
                    onChange={(e) => updateFiles(e)}
                    maxFilesSizeInMb={20}
                    acceptTypes={[
                        '.png',
                        '.jpg',
                        '.jpeg',
                        '.gif',
                        '.bmp',
                        '.tiff',
                        '.txt',
                        '.pdf',
                        '.doc',
                        '.docx',
                        '.xls',
                        '.xlsx',
                        '.xml',
                        '.swf',
                        '.avi',
                        '.mpg',
                        '.mpeg',
                        '.mpv',
                        '.mp4',
                        '.m4e',
                        '.m4v',
                        '.mp3',
                        '.m3u',
                        '.wma',
                        '.wmv',
                        '.js',
                        '.jsx',
                        '.css',
                        '.htm',]}

                    haveFileList={true}
                    multiple={true}
                    // minFiles={2}
                    maxFiles={10}
                    lang={"ru"}
                    classNames={{
                        fileIcon: myClassNames.myFileIconClass,
                        fileBox: myClassNames.box,
                        fileBoxButton: myClassNames.myFileButton,
                        fileName: myClassNames.myFileName,
                        dropZoneBox: myClassNames.drop_zone,
                        button: myClassNames.btn,
                    }}
                >
                </FileDropZone>
            </div>
        </>

    );
}
export { DownloadComponent };