import { create } from 'zustand';

const usePushStore = create((set) => ({
  pushes: [],
  addPush: (push) =>
    set((state) => ({
      pushes: [...state.pushes, { ...push, id: Date.now() }],
    })),
  removePush: (id) =>
    set((state) => ({
      pushes: state.pushes.filter((push) => push.id !== id),
    })),
}));

export default usePushStore;
