import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import { Checkbox } from '@lk-gtcom/ecomlab-components';
import './ModalFixedMessage.scss'

const ModalFixedMessage = ({ setIsModal, title, description = false }) => {

    const [isFixed, setIsFixed] = useState(false)

    return (
        <div className='blackout'
            style={{ zIndex: 20000 }}
            onClick={(e) => {
                let el = e.target
                if (el.classList.contains('blackout')) {
                    setIsModal(false)
                }
            }}>
            <div className='modal-fixed-message'>
                <h2 className='modal-fixed-message__title'>Закрепить это сообщение?</h2>
                {description && <p className='text'>{description}</p>}
                <Checkbox
                    value={isFixed}
                    onChange={(checked) => setIsFixed(checked)}
                >Закрепить это для других пользователей</Checkbox>
                <div className='modal-fixed-message__group-btn'>
                    <button className='modal-fixed-message__btn'
                        onClick={() => {
                            setIsModal(false)
                        }}
                    >Отмена</button>
                    <button className='modal-fixed-message__btn'
                        style={{ color: 'green' }}
                    >Закрепить</button>
                </div>
            </div>
        </div>
    )
}

export { ModalFixedMessage }

