import customHeaders, { getSpace } from '../../../common/headers';
import close from './img/close.svg';
import copy_icon from './img/copy_icon.svg';
import check_green from './img/bx-check.svg';
import low_status from './img/low_status.svg';
import hight_status from './img/hight_status.svg';
import medium_status from './img/medium_status.svg';
import plus_circle from './img/bx-plus-circle.svg';
import close_icon from './img/close_icon.svg';
import { useRef } from 'react';

import { Editor } from '../../Editor/Editor';
import { DateBox } from '../../DateBox/DateBox';
import { Comment } from '../../Comment/Comment';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { CommentItem } from '../../CommentItem/CommentItem';
import { TASK_EVENT_TYPE } from '../../../pages/TaskList/TaskList';
import { useState, useEffect } from 'react';
import { RavshanHttps, RavshanHttp, KonstantinHttps } from '../../../fetchUrls';
import {
  ButtonBasic,
  DropDownSelector,
  Checkbox,
  SelectExecutor,
  // TitleContentEditable,
  InputDinamycPlaceholder,
} from '@lk-gtcom/ecomlab-components';
import { ButtonCopy } from '../../ButtonCopy/ButtonCopy';
import {
  OptionsExecutor,
  ExecutorPlaceholder,
} from '../../OptionsExecutor/OptionsExecutor';
import { TooltipBlack } from '../../Tooltip/TooltipBlack/TooltipBlack';
import { DownloadComponent } from '../../DownloadComponent/DownloadComponent';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import useGeneralStore from '../../../store/general';
import no_data_file from './img/no_data_file.svg';
import './ModalAddTask2.scss';
import { TabsGreen } from '../../TabsGreen/TabsGreen';
import { FileItemComponent } from '../../FileItemComponent/FileItemComponent';
import { ButtonActionDrop } from '../../Buttons/ButtonActionDrop/ButtonActionDrop';
import { ModalAddTaskExit } from './ModalAddTaskExit/ModalAddTaskExit';
import { MoonLoader } from 'react-spinners';
import { PrintableVersion } from './PrintableVersion';
import pin from './img/bx-pin.svg';
import check_circle from './img/bx-check-circle.svg';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import { ModalFixedMessage } from './ModalFixedMessage/ModalFixedMessage';
import print_icon from './img/print_icon.svg';
import delete_icon from './img/trash_icon.svg';
import edit_icon from './img/edit_icon.svg';
import star_icon from './img/star_icon.svg';
import { useTextSelectionInModal } from '../../../hooks/useTextSelectionInModal';
import TitleContentEditable from './test/TitleContentEditable';
import { ModalShowFixedMessage } from './ModalShowFixedMessage/ModalShowFixedMessage';
const parse = require('html-react-parser');

// const btns = [
//     { label: 'Исполнитель', value: 'еxecutor', icon: user_icon },
//     { label: 'История', value: 'history', icon: info_circle_icon },
// ]

//     "files": 2,
//     "logs": 37,
//     "comments": 0,
//     "task_list": 1

const list_fields = [
  {
    name: 'сколько',
    type: 'string',
    value: '100500',
    field_id: 13,
  },
  {
    name: 'когда',
    type: 'string',
    value: 'завтра',
    field_id: 14,
  },
];

const OPTIONS_PRIORITY = [
  {
    label: (
      <p className="option-status" style={{ color: '#E62E2E' }}>
        Высокий <img src={hight_status} />
      </p>
    ),
    value: 'Высокий',
  },
  {
    label: (
      <p className="option-status" style={{ color: '#EE9023' }}>
        Средний <img src={medium_status} />
      </p>
    ),
    value: 'Средний',
  },
  {
    label: (
      <p className="option-status" style={{ color: '#26AF33' }}>
        Низкий <img src={low_status} />
      </p>
    ),
    value: 'Низкий',
  },
  {
    label: 'Не указано',
    value: 'Не назначено',
  },
];

const subtask_list = [
  {
    value: 'Подзадача 1',
    date: '16 окт. 23:33',
    id: 1,
    status: true,
  },
  {
    value: 'Подзадача 2',
    date: '16 окт. 23:33',
    id: 2,
    status: false,
  },
  {
    value: 'Подзадача 3',
    date: '16 окт. 23:33',
    id: 3,
    status: false,
  },
];

export const PRIORITY_TO_COLOR_MAP = {
  Низкий: '#26AF33',
  Средний: '#EE9023',
  Высокий: '#E62E2E',
  'Не назначено': 'blue',
};

const btn_tab = [
  {
    label: 'Описание',
    value: `task-info/description`,
  },
  {
    label: 'Комментарии',
    value: `task-info/comments`,
  },
];

const colors = {
  'на стопе': '#F1893F',
  'в работе': '#DC17D4',
  'на проверке': '#8F43F0',
  готово: '#08C104',
  'новые задачи': '#6574FF',
};

const convertDate = (dateString) => {
  const [datePart, timePart] = dateString.split(' ');

  // Разделяем дату на день, месяц и год
  const [day, month, year] = datePart.split('-');

  // Формируем новый формат: YYYY-MM-DDTHH:mm:ss.sssZ
  const formattedDate = `${year}-${month}-${day}T${timePart}:00.000Z`;
  return formattedDate;
};

const ModalAddTask2 = ({
  setIsModal,
  infoTask = false,
  attendees,
  setInfoTask,
  setTasks,
  selectedColumn,
  allColumns,
  fetchDeleteTask,
  taskId,
  query,
  isLoadInfoTask,
  isNew,
  setIsNew,
  event_id,
  setUpdateId,
  setActualId
}) => {
  const [count, setCount] = useState({});

  const btns_2 = [
    { label: 'Комментарии', value: 'comment', count: count?.comments },
    { label: 'Файлы', value: 'file', count: count?.files },
    { label: 'Чек-Лист', value: 'task-list', count: count?.task_list },
    { label: 'История', value: 'history', count: count?.logs },
  ];

  const location = useLocation();
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const email = localStorage.getItem('email');
  const currentUserColor = localStorage.getItem('currentUserColor');
  const currentUserName = localStorage.getItem('currentUserName');
  const refFixedMessage = useRef()


  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());

  const [endDate2, setEndDate2] = useState(new Date());
  const navigate = useNavigate();
  const [isModalExit, setIsModalExit] = useState(false);

  const [executorSearchValue, setExecutorSearchValue] = useState('');
  const [executors, setExecutors] = useState([]);
  const [coExecutors, setCoExecutors] = useState([]);
  const [executorOptions, setExecutorOptions] = useState(attendees);

  const reset = () => {
    setTitle('');
    setInitialDescription('');
    setDescription('');
    setAuthorName('');
    setAuthorColor('');
    setAuthorEmail('');
    setComments([]);
    setStatusTask([]);
    setSelectedStatusTask({});
    setSelectedProject(null);
    setSelectedPriority({});
    setFiles([]);
    setFilesData([]);
    setEventId(null);
  };

  const [title, setTitle] = useState('');
  const [defaultTitle, setDefaultTitle] = useState(false);
  const [description, setDescription] = useState('');
  const [initialDescription, setInitialDescription] = useState('');
  const [authorName, setAuthorName] = useState(currentUserName);
  const [authorColor, setAuthorColor] = useState(currentUserColor);
  const [authorEmail, setAuthorEmail] = useState(email);
  const [addTaskId, setAddTaskId] = useState();

  // Комментарии 
  const [comments, setComments] = useState([]);
  const [commentPage, setCommentPage] = useState(1);
  const [commentTotal, setСommentTotal] = useState(0);
  const [currentMessageAnswer, setCurrentMessageAnswer] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false)
  const [commentPageHeight, setCommentPageHeight] = useState(0);
  const [commentIsSearchLastPage, setCommentIsSearchLastPage] = useState(false);
  const [commentPageScrollTop, setCommentPageScrollTop] = useState(-1);

  //console.log(comments)
  // console.log(commentPageHeight)
  //console.log(commentPage)
  // console.log(commentPageScrollTop)

  const [statusTask, setStatusTask] = useState([]);
  const [selectedStatusTask, setSelectedStatusTask] = useState({});
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState({
    label: 'Не указано',
    value: 'Не назначено',
  });
  const [isDropMenu, setIstDropMenu] = useState(false);
  const [check, setCheck] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [logsArr, setLogsArr] = useState([]);
  const [logsErr, setLogsErr] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesdata, setFilesData] = useState([]);
  const [filesdataComments, setFilesDataComments] = useState([]);


  const [saveFile, setSaveFile] = useState(null);
  const [saveFileComment, setSaveFileComment] = useState([]);
  const [saveFileMessage, setSaveFileMessage] = useState([]);
  const [taskListId, setTaskListId] = useState(null);
  const [isShowHistoryTask, setIsShowHistoryTask] = useState(false);
  const [isLoadingInfoTask, setIsLoadingInfoTask] = useState({});

  const [showOption, setShowOptions] = useState(false);
  const [showOption2, setShowOptions2] = useState(false);
  const [eventId, setEventId] = useState(event_id);

  const activeTab = useParams()['*'];
  const [pageValue, setPageValue] = useState(btn_tab[0].value);
  const [currentTab2, setCurrentTab2] = useState(btns_2?.[0]?.value);
  const [isLoadingComments, setIsLoadingComments] = useState(false);

  const [idTable, setIdTable] = useState('');
  const [taskList, setTasklist] = useState([]);
  const [isSave, setIsSave] = useState(null);
  const [fields, setFields] = useState([]);
  const [isPrint, setIsPrint] = useState(false);
  const [isModalFixed, setIsModalFixed] = useState(false);
  const [isShowModalFixed, setIsShowModalFixed] = useState(false)

  // Подзадачник
  const [isNewSubtask, setIsNewSubtask] = useState(false);
  const [isCheckSubtask, setIsCheckSubtask] = useState(false);
  const [nameSubTask, setNameSubTask] = useState('');



  // Для логики модалки с подтверждение выхода
  const [isChanges, setIsChanges] = useState(false);

  // Первоначальная загрузка
  const [initLoadExecutor, setInitLoaderExecutor] = useState(true);
  const [initLoadCoExecutor, setInitLoaderCoExecutor] = useState(true);
  const [initLoadDate, setInitLoaderDate] = useState(true);



  const formateDate = (date) => {
    const timeZone = new Date().getTimezoneOffset() * 60000;
    const formate_date = new Date(
      new Date(date)?.getTime() + timeZone
    ).toUTCString();
    return new Date(formate_date);
  };

  // Создание задачи
  // Получаем в ответ event_id
  // done
  const fetchCreateTask = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/create_event`;
    } else {
      url = `${RavshanHttp}/api/v3/tasks/create_event`;
    }

    const selCol = allColumns?.filter(
      ({ label }) => label === selectedColumn?.typeName
    )?.[0];
    const task_type =
      +taskId !== -1 ? { task_type: selectedColumn?.typeId } : {};

    const { headers } = getBodyAndHeaders();
    const body_create = {
      ...task_type,
      task_id: +taskId === -1 ? selCol?.value?.id.toString() : taskId,
      priority: selectedPriority?.value,
    };

    fetch(url, { body: JSON.stringify(body_create), method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        if (typeof json === 'number') {
          setEventId(json);
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (eventId) {
      fetchGetPriority();
      fetchGetAuthor();
      fetchGetExecutor();
      fetchGetCoExecutor();
      fetchGetDates();
      fetchGetRating();
      fetchGetName();
      fetchGetDescription();
      fetchGetTaskInfo();
      fetchGetEventCounter();
      fetchGetTaskListId();
      fetchGetTaskType();
      fetchGetFiles('description');
      // fetchTaskLog()
    }
  }, [eventId]);

  useEffect(() => {
    if (currentTab2 == 'task-list') {
      fetchGetTaskListId();
    }

    if (eventId) {
      if (currentTab2 == 'task-list') {
        fetchGetCustomTableDataDict();
      }
      if (currentTab2 == 'history') {
        fetchGetEventLog();
      }
      if (currentTab2 == 'comment') {
        fetchComments();
      }
      if (currentTab2 == 'file') {
        fetchGetFiles('description');
        fetchGetFiles('comments');
      }
    }
  }, [currentTab2, isFavorite]);

  // useEffect(() => {
  //   fetchComments();
  // }, [isFavorite]);

  const isLoadFunc = () => {
    if (
      isLoadingInfoTask.author &&
      isLoadingInfoTask.date &&
      isLoadingInfoTask.name &&
      isLoadingInfoTask.priority &&
      isLoadingInfoTask.executor &&
      isLoadingInfoTask.co_executors &&
      isLoadingInfoTask.text
    ) {
      return true;
    } else {
      return false;
    }
  };

  const [pinMessage, setPinMessage] = useState()

  // получение прикрепленного
  const fetchGetPinMessage = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/get_pinned_comment/${eventId}`;
    } else {
      url = `${RavshanHttp}/api/v3/tasks/get_pinned_comment/${eventId}`;
    }

    const { headers } = getBodyAndHeaders();

    fetch(url, { method: 'POST', headers })
      .then((res) => {
        if (res.status === 204) {
          setPinMessage([])
        } else {
          return res.json()
        }
      })
      .then((json) => {
        if (typeof json === 'object') {
          setPinMessage([json])
        } else {
          setPinMessage([])
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (eventId) {
      fetchGetPinMessage()
    }
  }, [eventId, comments]);


  // получение файлов
  // filter : description || comments
  const fetchGetFiles = (filter) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/get_event_files?event_id=${eventId}&filter=${filter}`;
    } else {
      url = `${RavshanHttp}/api/v3/tasks/get_event_files?event_id=${eventId}&filter=${filter}`;
    }

    const { headers } = getBodyAndHeaders();

    fetch(url, { method: 'GET', headers })
      .then((res) => res.json())
      .then((json) => {
        // в ответ файлы по фильтру
        const curr_files = json?.map((el) => {
          return {
            created_at: el?.created_at,
            file_size_kb: (el?.file_size_kb).toString(),
            new_filename: el?.label,
            url: el?.url,
            extension: el?.extension,
          };
        });
        if (filter == 'description') {
          setFilesData(curr_files);
        } else {
          setFilesDataComments(curr_files);
        }
      })
      .catch((err) => console.error(err));
  };

  const [initIdCol, setInitIdCol] = useState(null)

  // Получение task_type к задаче
  // done
  const fetchGetTaskType = (filter) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/get_task_type/${eventId}`;
    } else {
      url = `${RavshanHttps}/api/v3/tasks/get_task_type/${eventId}`;
    }

    const { headers } = getBodyAndHeaders();

    fetch(url, { method: 'GET', headers })
      .then((res) => res.json())
      .then((json) => {
        //204 - нет type
        if (json?.id) {
          // let current_status = statusTask.map((el) => {
          //     if (el?.value == json?.id) {
          //         return el
          //     }
          // })?.filter((el) => el)
          // setSelectedStatusTask(current_status[0])

          const { id, name } = json;
          setSelectedStatusTask({
            label: <p className='modal-add-task__options-task' style={{ color: colors[name.toLowerCase()] }}>{name}</p>,
            value: id,
          });
          setInitIdCol(id)
        }
        // } else {
        //     const cur_status = statusTask.filter(({value}) => value == 1175)?.[0]
        //     setSelectedStatusTask(cur_status)
        // }
      })
      .catch((err) => console.error(err))
      .finally(() => { });
  };

  // Получение приоритета
  // done
  const fetchGetPriority = (filter) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/get_priority/${eventId}`;
    } else {
      url = `${RavshanHttps}/api/v3/tasks/get_priority/${eventId}`;
    }

    const { headers } = getBodyAndHeaders();

    fetch(url, { method: 'GET', headers })
      .then((res) => res.json())
      .then((json) => {
        //204 - нет type
        if (json) {
          const priorityToDropdown = OPTIONS_PRIORITY.find(
            (p) => p.value === json
          );
          setSelectedPriority(priorityToDropdown ?? OPTIONS_PRIORITY.at(-1));
          setIsLoadingInfoTask((prevState) => ({
            ...prevState,
            priority: 'true',
          }));
        }
      })
      .catch((err) => console.error(err))
      .finally(() => { });
  };

  // Получение автора
  // done
  const fetchGetAuthor = (filter) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/get_author/${eventId}`;
    } else {
      url = `${RavshanHttps}/api/v3/tasks/get_author/${eventId}`;
    }

    const { headers } = getBodyAndHeaders();

    fetch(url, { method: 'GET', headers })
      .then((res) => res.json())
      .then((json) => {
        if (json?.id) {
          const { name, color, login } = json;
          setAuthorName(name);
          setAuthorColor(color);
          setAuthorEmail(login);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoadingInfoTask((prevState) => ({
          ...prevState,
          author: 'true',
        }));
      });
  };

  // Получение Исполнителя
  // done
  const fetchGetExecutor = (filter) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/get_executor/${eventId}`;
    } else {
      url = `${RavshanHttps}/api/v3/tasks/get_executor/${eventId}`;
    }

    const { headers } = getBodyAndHeaders();

    fetch(url, { method: 'GET', headers })
      .then((res) => res.json())
      .then((json) => {
        if (json && json?.id) {
          setExecutors([json]);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoadingInfoTask((prevState) => ({
          ...prevState,
          executor: 'true',
        }));
      });
  };

  // Получение соИсполнителя
  // done
  const fetchGetCoExecutor = (filter) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/get_co_executors/${eventId}`;
    } else {
      url = `${RavshanHttps}/api/v3/tasks/get_co_executors/${eventId}`;
    }

    const { headers } = getBodyAndHeaders();

    fetch(url, { method: 'GET', headers })
      .then((res) => res.json())
      .then((json) => {
        const new_coExecutors = json.map((el) => {
          let elem = el;
          elem.email = elem?.login;
          return elem;
        });
        setCoExecutors(new_coExecutors);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoadingInfoTask((prevState) => ({
          ...prevState,
          co_executors: 'true',
        }));
      });
  };

  // Получение даты
  // done
  const fetchGetDates = (filter) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/get_dates/${eventId}`;
    } else {
      url = `${RavshanHttps}/api/v3/tasks/get_dates/${eventId}`;
    }

    const { headers } = getBodyAndHeaders();

    fetch(url, { method: 'GET', headers })
      .then((res) => res.json())
      .then((json) => {
        if (json) {
          // Пример ответа
          // {
          //     "date": "13-11-2024 15:25",
          //     "end_time": "30-11-2024 16:37",
          //     "start_time": "30-11-2024 16:37",
          //     "employee_time": "30-11-2024 16:37"
          //   }

          const { end_time, start_time, employee_time } = json;

          if (end_time) {
            //setStartDate(new Date(convertDate(end_time)))
            setEndDate(new Date(convertDate(end_time)));
          }
          if (start_time) {
            setStartDate(new Date(convertDate(start_time)));
          }
          if (employee_time) {
            setEndDate2(new Date(convertDate(employee_time)));
          }
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoadingInfoTask((prevState) => ({
          ...prevState,
          date: 'true',
        }));
      });
  };

  // Получение рейтинга
  const fetchGetRating = (filter) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/get_rating/${eventId}`;
    } else {
      url = `${RavshanHttps}/api/v3/tasks/get_rating/${eventId}`;
    }

    const { headers } = getBodyAndHeaders();

    fetch(url, { method: 'GET', headers })
      .then((res) => res.json())
      .then((json) => {
        // Пример ответа
        // "5"
        console.log(json);
      })
      .catch((err) => console.error(err));
  };

  // Получение названия задачи
  // done
  const fetchGetName = (filter) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/get_name/${eventId}`;
    } else {
      url = `${RavshanHttps}/api/v3/tasks/get_name/${eventId}`;
    }

    const { headers } = getBodyAndHeaders();

    fetch(url, { method: 'GET', headers })
      .then((res) => res.json())
      .then((json) => {
        if (json) {
          setTitle(json);
          setDefaultTitle(json);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoadingInfoTask((prevState) => ({
          ...prevState,
          name: 'true',
        }));
      });
  };

  // Получение описания задачи
  // done
  const fetchGetDescription = (filter) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/get_text/${eventId}`;
    } else {
      url = `${RavshanHttps}/api/v3/tasks/get_text/${eventId}`;
    }

    const { headers } = getBodyAndHeaders();

    fetch(url, { method: 'GET', headers })
      .then((res) => res.json())
      .then((json) => {
        // Пример ответа
        // {
        //     "id": 11161,
        //     "text": "",
        //     "place": null,
        //     "text_bold": false,
        //     "text_cursive": false
        //   }
        setDescription(json?.text); // тут падает
        setInitialDescription(json?.text);
        update();
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoadingInfoTask((prevState) => ({
          ...prevState,
          text: 'true',
        }));
      });
  };

  // Получение id таск листа
  const fetchGetTaskListId = (filter) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/get_task_list_id/${eventId}`;
    } else {
      url = `${RavshanHttps}/api/v3/tasks/get_task_list_id/${eventId}`;
    }

    const { headers } = getBodyAndHeaders();

    fetch(url, { method: 'GET', headers })
      .then((res) => res.json())
      .then((json) => {
        setIdTable(json);
      })
      .catch((err) => console.error(err));
  };

  // Получение информации по проекту Задачи
  // done
  const fetchGetTaskInfo = (filter) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/get_task_info/${eventId}`;
    } else {
      url = `${RavshanHttps}/api/v3/tasks/get_task_info/${eventId}`;
    }

    const { headers } = getBodyAndHeaders();

    fetch(url, { method: 'GET', headers })
      .then((res) => res.json())
      .then((json) => {
        const { id, name } = json;
        // fetchAllColumns(0, id);
        setAddTaskId(id);
        const selectedProject = { label: name, value: id };
        setSelectedProject(selectedProject);
      })
      .catch((err) => console.error(err));
  };

  // Получение каунтера
  // done
  const fetchGetEventCounter = (filter) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/get_event_counter?event_id=${eventId}`;
    } else {
      url = `${RavshanHttps}/api/v3/tasks/get_event_counter?event_id=${eventId}`;
    }

    const { headers } = getBodyAndHeaders();

    fetch(url, { method: 'GET', headers })
      .then((res) => res.json())
      .then((json) => {
        if (json) {
          setCount(json);
        }
        // {
        //     "files": 2,
        //     "logs": 37,
        //     "comments": 0,
        //     "task_list": 1
        //   }
      })
      .catch((err) => console.error(err));
  };

  // Получение истории изменения задач
  const fetchGetEventLog = (filter) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/get_event_log`;
    } else {
      url = `${RavshanHttps}/api/v3/tasks/get_event_log`;
    }

    const { headers } = getBodyAndHeaders();
    const body_curr = JSON.stringify({
      limit: 1000,
      page: 1,
      event_id: eventId,
    });

    fetch(url, { body: body_curr, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        if (Array.isArray(json)) {
          setLogsArr(json);
          setIsShowHistoryTask(true);
        }
      })
      .catch((err) => console.error(err));
  };

  // добавление файла
  const fetchAddFiles = (filesdata, type, id_comment) => {
    if (saveFile || saveFileComment) {
      setIsSave(false);
      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${RavshanHttps}/api/v3/tasks/add_files`;
      } else {
        url = `${RavshanHttp}/api/v3/tasks/add_files`;
      }

      const { headers } = getBodyAndHeaders();

      const filesDataCurr = saveFile?.map(
        ({ url, file_size_kb, extension }) => {
          return {
            url: url,
            file_size_kb: file_size_kb.toString(),
            extension,
          };
        }
      );

      const filesDataCurrComment = saveFileComment?.map(
        ({ url, file_size_kb, extension }) => {
          return {
            url: url,
            file_size_kb: file_size_kb.toString(),
            extension,
          };
        }
      );

      const body_files = JSON.stringify({
        event_id: eventId,
        files: type == 'comments' ? filesDataCurrComment : filesDataCurr,
        place: type, //"comments", "description"
        comment_id: id_comment,
      });

      fetch(url, { body: body_files, method: 'POST', headers })
        .then((res) => res.json())
        .then((json) => {
          setSaveFile([]);
          fetchGetFiles(type);
          update();
          setIsSave(true);
        })
        .catch((err) => console.error(err));
    }
  };

  // Удаление файлов
  const fetchDeleteFiles = (event_id, url_file, type, comment_id) => {
    setIsSave(false);
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/delete_file`;
    } else {
      url = `${RavshanHttp}/api/v3/tasks/delete_file`;
    }

    const { headers } = getBodyAndHeaders();
    const body_files = JSON.stringify({
      event_id: event_id,
      file_url: url_file,
      place: type, // "comment"
      comment_id: comment_id,
    });

    fetch(url, { body: body_files, method: 'DELETE', headers })
      .then((res) => res.json())
      .then((json) => {
        update();
        if (type == 'comments') {
          setTimeout(() => fetchComments(), 500);
        }
        setIsSave(true);
      })

      .catch((err) => console.error(err));
  };

  // Изменение статуса задачи(task_type)
  // done
  const fetchSetTaskType = (selected) => {
    setIsSave(false);
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/set_task_type`;
    } else {
      url = `${RavshanHttp}/api/v3/tasks/set_task_type`;
    }

    const { headers } = getBodyAndHeaders();
    const body_curr = JSON.stringify({
      event_id: eventId,
      task_type: selectedStatusTask?.value,
    });

    fetch(url, { body: body_curr, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        update();
        setIsSave(true);
      })
      .catch((err) => console.error(err));
  };

  // Изменение приоритета
  // done
  const fetchSetPriority = (selected) => {
    setIsSave(false);
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/set_priority`;
    } else {
      url = `${RavshanHttp}/api/v3/tasks/set_priority`;
    }

    const { headers } = getBodyAndHeaders();
    const body_curr = JSON.stringify({
      event_id: eventId,
      priority: selectedPriority?.value,
    });

    fetch(url, { body: body_curr, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        update();
        setIsSave(true);
      })
      .catch((err) => console.error(err));
  };

  // Изменение исполнителя
  // done
  const fetchSetExecutor = (selected) => {
    setIsSave(false);
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/set_executor`;
    } else {
      url = `${RavshanHttp}/api/v3/tasks/set_executor`;
    }

    const { headers } = getBodyAndHeaders();
    const body_curr = JSON.stringify({
      event_id: eventId,
      executor: selected ? selected : null,
    });

    fetch(url, { body: body_curr, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        update();
        setIsSave(true);
      })
      .catch((err) => console.error(err));
  };

  // Изменение соисполнителя
  // done
  const fetchSetCoExecutor = (selected) => {
    setIsSave(false);
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/set_co_executors`;
    } else {
      url = `${RavshanHttp}/api/v3/tasks/set_co_executors`;
    }

    const { headers } = getBodyAndHeaders();
    const body_curr = JSON.stringify({
      event_id: eventId,
      co_executors: selected?.length ? selected : [],
    });

    fetch(url, { body: body_curr, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        update();
        setIsSave(true);
      })
      .catch((err) => console.error(err));
  };

  // Изменение даты
  const fetchSetDates = () => {
    setIsSave(false);
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/set_dates`;
    } else {
      url = `${RavshanHttp}/api/v3/tasks/set_dates`;
    }

    const { headers } = getBodyAndHeaders();

    const body_curr = JSON.stringify({
      event_id: eventId,
      start_time: startDate,
      end_time: endDate,
      employee_time: endDate2,
      //employee_time: check2 ? formateDate(new Date()) : endDate2 ? formateDate(endDate2) : endDate ? formateDate(endDate) : undefined
    });

    fetch(url, { body: body_curr, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        update();
        setIsSave(true);
      })
      .catch((err) => console.error(err));
  };

  // Изменение рейтинга
  const fetchRating = () => {
    setIsSave(false);
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/set_rating`;
    } else {
      url = `${RavshanHttp}/api/v3/tasks/set_rating`;
    }

    const { headers } = getBodyAndHeaders();
    const body_curr = JSON.stringify({
      event_id: eventId,
      rating: 0,
    });

    fetch(url, { body: body_curr, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        update();
        setIsSave(true);
      })
      .catch((err) => console.error(err));
  };

  // Изменение доп полей к задаче
  const fetchSetAdditionalFields = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/set_additional_fields`;
    } else {
      url = `${RavshanHttp}/api/v3/tasks/set_additional_fields`;
    }

    const { headers } = getBodyAndHeaders();
    const body_curr = JSON.stringify({
      event_id: eventId,
      additional_fields: [
        {
          field_id: 'ID поля 1',
          value: 'значение поля 1',
        },
        {
          field_id: 'ID поля 2',
          value: 'значение поля 2',
        },
      ],
    });

    fetch(url, { body: body_curr, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
      })
      .catch((err) => console.error(err));
  };

  // Изменение названия задачи
  // done
  const fetchSetName = () => {
    setIsSave(false);
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/set_name`;
    } else {
      url = `${RavshanHttp}/api/v3/tasks/set_name`;
    }

    const { headers } = getBodyAndHeaders();
    const body_curr = JSON.stringify({
      event_id: eventId,
      name: title,
    });

    fetch(url, { body: body_curr, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        update();
        setIsSave(true);
      })
      .catch((err) => console.error(err));
  };

  // Изменение описания задачи
  // done

  const fetchSetDescription = () => {
    // .replace(' </p>', '</p>')


    if (initialDescription.replaceAll('</a>', ' </a> ').replaceAll(' <a', ' <a ') !== description) {
      setIsSave(false);
      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${RavshanHttps}/api/v3/tasks/set_text`;
      } else {
        url = `${RavshanHttp}/api/v3/tasks/set_text`;
      }


      const { headers } = getBodyAndHeaders();
      const body_curr = JSON.stringify({
        event_id: eventId,
        text: description ? description.replaceAll('</a>', ' </a> ').replaceAll('<a', ' <a') : description,
      });

      fetch(url, { body: body_curr, method: 'POST', headers })
        .then((res) => res.json())
        .then((json) => {
          update();
          setInitialDescription(description.replaceAll('</a>', ' </a> ').replaceAll('<a', ' <a'));
          setIsSave(true);
        })
        .catch((err) => console.error(err));
    }
  };

  useEffect(() => {
    if (isNew) {
      fetchCreateTask();
    }
  }, []);

  const getBodyAndHeaders = (withId) => {
    const contentData = JSON.stringify({
      event_id: withId ? eventId : undefined,
      name: title,
      language: 'ru',
      start_time: formateDate(startDate),
      end_time: formateDate(endDate),
      employee_time: check2
        ? formateDate(new Date())
        : endDate2
          ? formateDate(endDate2)
          : endDate
            ? formateDate(endDate)
            : undefined,
      event_type: TASK_EVENT_TYPE,
      task_id: addTaskId ? addTaskId : taskId,
      task_type: selectedStatusTask.value,
      executor: executors[0]?.id,
      priority: selectedPriority.value,
      content: description
        ? replaceString('</p>', ' </p>', description)
        : description,
      co_executors:
        coExecutors?.length > 0 ? coExecutors.map(({ id }) => id) : [],
      show_in_calendar: false,
      files: filesdata,
    });

    // const formData = new FormData()
    // formData.append('content_data', new Blob([contentData], { type: 'application/json' }))

    const _headers = Object.assign({}, headers);
    // delete _headers['Content-Type']

    return { body: contentData, headers: _headers };
  };

  const fetchAddNewTask = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/tasks/post_event`;
    } else {
      url = `${RavshanHttp}/api/tasks/post_event`;
    }

    const { body, headers } = getBodyAndHeaders();

    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => setIsModal(false))
      .catch((err) => console.error(err));
  };

  const fetchUpdateTask = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/tasks/put_event`;
    } else {
      url = `${RavshanHttp}/api/tasks/put_event`;
    }

    const { body, headers } = getBodyAndHeaders(true);

    const _headers = Object.assign({}, headers);
    // delete _headers['Content-Type']

    fetch(url, { body: body, method: 'PUT', headers: _headers })
      .then((res) => res.json())
      .then((json) => setIsModal(false))
      .catch((err) => console.error(err));
  };

  const fetchPossibleAttendees = () => {
    const url = `${RavshanHttps}/api/tasks/project_client_team`;
    const newHeaders = {
      ...headers,
      'project-id': addTaskId,
    };
    fetch(url, { headers: newHeaders })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('bad attendees response');
        }
      })
      .then((json) => {
        setExecutorOptions(json);
      })
      .catch((e) => console.error(e));
  };

  const fetchComments = () => {
    if (eventId) {
      if (comments?.length == 0) {
        setIsLoadingComments(true);
      }

      const url = `${RavshanHttps}/api/v3/tasks/comment`;
      const newHeaders = {
        ...headers,
        // 'event-id': eventId,
        // is_favorite: isFavorite,
        // "page": 1,
        // "limit": 10
      };

      const body = JSON.stringify({
        event_id: eventId,
        is_favorite: isFavorite,
        page: commentPage,
        limit: 20
      });

      fetch(url, {
        body,
        method: 'POST',
        headers: newHeaders,
        //  signal: abortController.signal,
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          } else {
            throw new Error('bad comments response');
          }
        })
        .then((json) => {
          const total = json?.[0]?.total || 0
          if (commentPage > 1) {
            setComments((prev) => [...prev, ...json]);
          } else {
            setComments(json);
          }
          console.log(total, commentPage * 20)

          setCommentIsSearchLastPage(total < commentPage * 20);
          update();
        })
        .catch((e) => console.error(e))
        .finally(() => setIsLoadingComments(false));
    }
  };

  // const onScroll = (listRef, setPageHeight, setScrollTop) => {
  //   const { scrollTop, scrollHeight, clientHeight } = listRef.current;
  //   const bottomHeight = scrollHeight - clientHeight;
  //   if (bottomHeight) {
  //     setPageHeight(bottomHeight);
  //   }
  //   setScrollTop(scrollTop);
  // };

  // useEffect(() => {
  //   fetchComments()
  // }, [commentPage]);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     const currentPageHeight = commentPageHeight;
  //     if (
  //       !commentIsSearchLastPage &&
  //       Math.ceil(commentPageScrollTop) >= currentPageHeight &&
  //       comments?.length > 0
  //     ) {
  //       setCommentPage((prev) => prev + 1);
  //       return;
  //     }
  //   }, 500);
  //   return () => clearTimeout(timeout);

  const onScroll = (listRef, setPageHeight, setScrollTop) => {
    const { scrollTop, scrollHeight, clientHeight } = listRef.current;
    const topHeight = scrollTop; // Теперь отслеживаем верхнюю границу скролла
    if (topHeight === 0) {
      setPageHeight(scrollHeight); // Обновляем высоту страницы
    }
    setScrollTop(scrollTop); // Обновляем значение scrollTop
  };

  const [isUpdateComment, setIsUpdateComment] = useState(false)

  useEffect(() => {
    // Загрузка комментариев при изменении страницы
    fetchComments();

  }, [commentPage, isUpdateComment]);

  useEffect(() => {
    //bottomRef?.current?.scrollTo(10000000, 10000000);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const currentPageHeight = commentPageHeight;
      if (
        !commentIsSearchLastPage &&
        commentPageScrollTop <= 0 && // Когда скролл в верхней части
        comments?.length > 0
      ) {
        setCommentPage((prev) => prev + 1); // Подгрузка новых сообщений
      }
    }, 500);

    return () => clearTimeout(timeout);
  }, [commentPageScrollTop, commentPageHeight, commentIsSearchLastPage]);

  useEffect(() => {
    setCommentPage(1);
  }, []);

  // Прикрепление комментария
  const fetchPinComment = (comment_id) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/pin_comment?comment_id=${comment_id}`;
    } else {
      url = `${RavshanHttps}/api/v3/tasks/pin_comment?comment_id=${comment_id}`;
    }

    const { headers } = getBodyAndHeaders();

    fetch(url, { method: 'GET', headers })
      .then((res) => res.json())
      .then((json) => {
        fetchComments()
      })
      .catch((err) => console.error(err))
      .finally(() => { });
  };

  // Прикрепление комментария
  const fetchUnpinComment = (comment_id) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/unpin_comment?comment_id=${comment_id}`;
    } else {
      url = `${RavshanHttps}/api/v3/tasks/unpin_comment?comment_id=${comment_id}`;
    }

    const { headers } = getBodyAndHeaders();

    fetch(url, { method: 'GET', headers })
      .then((res) => res.json())
      .then((json) => {
        setComments([])
        setCommentPage(1)
        fetchComments()
      })
      .catch((err) => console.error(err))
      .finally(() => { });
  };

  const fetchGetAdditionalFields = (
    abortController = new AbortController()
  ) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/get_additional_fields_by_project`;
    } else {
      url = `${RavshanHttp}/api/v3/tasks/get_additional_fields_by_project`;
    }

    const selCol = allColumns?.filter(
      ({ label }) => label === selectedColumn?.typeName
    )?.[0];

    const body = JSON.stringify({
      task_id: taskId === '-1' ? +selCol?.value?.id : +taskId,
      event_id: +eventId,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('bad events response');
        }
      })
      .then((json) => {
        if (Array.isArray(json)) {
          setFields(json);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    // .finally(() => {
    //     setLoad(false)
    // })
  };

  useEffect(() => {
    fetchGetAdditionalFields();
  }, []);

  const [initialScroll, setInitialScroll] = useState(true)

  useEffect(() => {
    if (comments.length > 0) {
      if (
        typeof bottomRef?.current !== 'undefined' ||
        typeof bottomRef?.current !== 'null'
      ) {
        if (initialScroll) {
          new Promise(() => {
            bottomRef?.current?.scrollTo(10000000, 10000000);
          })
            .then(() => { })
          setInitialScroll(false)
        }
      }
    }

  }, [comments]);

  //console.log('-1', selectedProject)
  //console.log('-1', selectedStatusTask?.value)


  const btns = [
    {
      btn: 'Удалить',
      func: (e) => {
        const selProjId = isNew ? selectedProject?.value?.id : selectedProject?.value;
        fetchDeleteTask(eventId, true);
        setUpdateId(taskId === '-1' ? selProjId : selectedStatusTask?.value)
        // setUpdateId(selectedStatusTask?.value)
        setTasks([]);
        setIsModal(false);
        setInfoTask(null);
        setIstDropMenu(false);
      },
    },
  ];

  const [currentIdTaskList, setCurrentIdTaskList] = useState(null);

  const btns_task_list = [
    {
      btn: 'Редактировать',
      func: (e) => { },
    },
    {
      btn: 'Удалить',
      func: (e) => {
        fetchDeleteRow();
      },
    },
  ];

  const handleMainButtonClick = (update) => {
    // if (update) { fetchUpdateTask() }
    // else { fetchAddNewTask() }
    setTasks([]);
    sessionStorage.removeItem('eventId');
    navigate(`/tasks/task-list/${sessionStorage.getItem('taskProjectId')}`);
    setInfoTask(null);
  };

  const fetchAllColumns = (delayMs, taskId) => {
    if (!taskId & !selectedColumn) return;
    const url = `${RavshanHttps}/api/tasks/get_task_type`;
    const getHeaders = {
      ...headers,
      'project-id': taskId ? taskId : selectedColumn?.typeId,
      // "executor": [],
      // "author": [],
      // "co_executor": [],
      // "task_type": "string"
    };

    const fetchCallback = () =>
      fetch(url, { headers: getHeaders })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error('bad columns response');
          }
        })
        .then((json) => {
          if (!Array.isArray(json)) return;
          const statusArr = json
            .sort((a, b) => a.place - b.place)
            .map((c, ind) => ({
              label: (
                <p style={{ color: colors[c?.type_name.toLowerCase()] }}>
                  {c?.type_name}
                </p>
              ),
              value: c?.type_id,
            }));
          setStatusTask(statusArr);
        });

    if (delayMs) setTimeout(fetchCallback, delayMs);
    else fetchCallback();
  };

  useEffect(() => {
    if (saveFile?.length > 0) {
      fetchAddFiles(filesdata, 'description');
      const timeoutId = setTimeout(() => {
        fetchGetFiles('description');
        fetchGetEventLog();
        fetchGetEventCounter();
      }, 500);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [saveFile]);

  useEffect(() => {
    if (saveFileComment?.length > 0) {
      const timeoutId = setTimeout(() => {
        fetchGetFiles('comments');
        fetchGetEventLog();
        fetchGetEventCounter();
      }, 500);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [saveFileComment]);

  const update = () => {
    fetchGetEventLog();
    fetchGetEventCounter();
  };

  useEffect(() => {
    fetchPossibleAttendees();
    setPageValue(btn_tab[0].value);

    const hideOptionsBox = (e) => {
      if (!e.target.closest('.search-box-executor')) {
        setShowOptions(false);
      }
      setExecutorSearchValue('');
    };
    document.addEventListener('click', (e) => hideOptionsBox(e));
    return () =>
      document.removeEventListener('click', (e) => hideOptionsBox(e));
  }, [addTaskId, selectedSpace]);

  useEffect(() => {
    if (activeTab != btn_tab[0].value && activeTab != btn_tab[1].value) {
      setPageValue(btn_tab[0].value);
    } else setPageValue(activeTab);
  }, [activeTab]);

  // console.log(selectedProject)

  useEffect(() => {
    const selProjId = selectedProject?.value
    if (+taskId === -1 || !taskId) {
      fetchAllColumns(0, selProjId);
    }
  }, [selectedProject]);

  useEffect(() => {
    const selProjId = selectedProject?.value;
    if (!infoTask?.id) {

      if (+taskId === -1) {
        const selCol = allColumns?.filter(
          ({ label }) => label === selectedColumn?.typeName
        )?.[0];
        setAddTaskId(selProjId ? selProjId : selCol?.value?.id);
        fetchAllColumns(0, selProjId ? selProjId : selCol?.value?.id);
        setSelectedProject(selCol);
      } else {
        const selCol = allColumns?.filter(
          ({ value: { id } }) => id === +taskId
        )?.[0];
        setAddTaskId(taskId);
        fetchAllColumns(0, taskId);
        setSelectedProject(selCol);
        setSelectedStatusTask({
          label: (
            <p
              style={{ color: colors[selectedColumn?.typeName.toLowerCase()] }}
            >
              {selectedColumn?.typeName}
            </p>
          ),
          value: selectedColumn?.typeId,
        });
      }
    } else {
      setAddTaskId(selProjId);
    }
  }, [selectedProject, selectedColumn, allColumns, selectedSpace]);

  function replaceString(oldS, newS, fullS) {
    return fullS.split(oldS).join(newS);
  }

  const current_file_list = () => {
    const extensions = ['jpg', 'jpeg', 'tiff', 'raw', 'bmp', 'psd'];
    let list_files_img;
    let list_files_file;

    list_files_img = filesdata.filter((el) =>
      extensions?.includes(el?.extension)
    );
    list_files_file = filesdata.filter(
      (el) => !extensions?.includes(el?.extension)
    );
    return filesdata;
  };

  useEffect(() => {
    if (!initLoadExecutor) {
      if (Array.isArray(executors)) {
        fetchSetExecutor(executors[0]?.id ? executors[0]?.id : null);
      }
    }
  }, [executors]);

  useEffect(() => {
    if (!initLoadCoExecutor) {
      if (Array.isArray(coExecutors)) {
        fetchSetCoExecutor(
          coExecutors?.length > 0 ? coExecutors.map(({ id }) => id) : []
        );
      }
    }
  }, [coExecutors]);

  useEffect(() => {
    if (!initLoadDate) {
      fetchSetDates();
      setInitLoaderDate(false);
    }
  }, [endDate, endDate2]);


  const bottomRef = useRef(null);

  // useEffect(() => {
  //   if (
  //     typeof bottomRef?.current !== 'undefined' ||
  //     typeof bottomRef?.current !== 'null'
  //   ) {
  //     setTimeout(() => {
  //       bottomRef?.current?.scrollTo(10000000, 10000000);
  //     }, 500);
  //   }
  // }, [comments]);

  // логика открытия/закрытия с комментом
  function isCommentFocused() {
    const commentInput = document.querySelector(
      '[data-placeholder="Оставьте комментарий"]'
    );
    const childP = commentInput.querySelector('p');
    const value = childP.textContent;
    return value?.length > 0;
  }

  //логика с хуком на выделение
  const isTextSelected = useTextSelectionInModal('.modal-add-task');

  const fetchGetCustomTableDataDict = (
    abortController = new AbortController()
  ) => {
    if (idTable) {
      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${KonstantinHttps}/api/custom_table/get_custom_table_data_dict`;
      } else {
        url = `${KonstantinHttps}/api/custom_table/get_custom_table_data_dict`;
      }

      const body = JSON.stringify({
        table_uid: idTable,
      });

      fetch(url, {
        body,
        method: 'POST',
        headers,
        signal: abortController.signal,
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error('bad events response');
          }
        })
        .then((json) => {
          setTasklist(json);
        })
        .catch((err) => {
          console.error(err);
        });
      // .finally(() => {
      //     setLoad(false)
      // })
    }
  };

  const fetchAddRow = (task_name, abortController = new AbortController()) => {
    if (!idTable) return;
    setIsSave(false);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinHttps}/api/custom_table/add_row_with_name`;
    } else {
      url = `${KonstantinHttps}/api/custom_table/add_row_with_name`;
    }

    const body = JSON.stringify({
      table_uid: idTable,
      value: task_name,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          fetchGetCustomTableDataDict();
          return res.json();
        } else {
          throw new Error('bad events response');
        }
      })
      .then((json) => {
        setIsSave(true);
      })
      .catch((err) => {
        console.error(err);
      });
    // .finally(() => {
    //     setLoad(false)
    // })
  };

  const fetchDeleteRow = (row_id, abortController = new AbortController()) => {
    if (!idTable) return;
    setIsSave(false);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinHttps}/api/custom_table/delete_custom_table_row`;
    } else {
      url = `${KonstantinHttps}/api/custom_table/delete_custom_table_row`;
    }

    const body = JSON.stringify({
      table_uid: idTable,
      row_uid: row_id,
    });

    fetch(url, {
      body,
      method: 'DELETE',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          fetchGetCustomTableDataDict();
          return res.json();
        } else {
          throw new Error('bad events response');
        }
      })
      .then((json) => {
        fetchGetCustomTableDataDict();
        setCurrentIdTaskList(null);
      })
      .catch((err) => {
        console.error(err);
      });
    // .finally(() => {
    //     setLoad(false)
    // })
  };

  const fetchEditRow = (row_id, type, value) => {
    setIsSave(false);
    const url = `${KonstantinHttps}/api/custom_table/add_custom_table_data`;

    const body = JSON.stringify({
      table_uid: idTable,
      data: [
        {
          row_uid: row_id,
          value: value,
          field_name: type,
        },
      ],
    });

    fetch(url, { method: 'POST', headers, body })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('bad events response');
        }
      })
      .then((json) => {
        fetchGetCustomTableDataDict();
        setIsSave(true);
      })
      .catch((err) => console.error(err));
  };

  const SubtaskItem = ({ title, row_id, checkbox, date }) => {
    const [isEdit, setIsEdit] = useState(false);
    const [value, setValue] = useState(title);

    return (
      <div className="subtasks-list__item" key={title + row_id}>
        <Checkbox
          green
          value={checkbox}
          onChange={(e) =>
            fetchEditRow(row_id, 'checkbox', checkbox ? 'false' : 'true')
          }
        />
        {!isEdit ? (
          <p
            className={
              checkbox ? 'subtasks-list__text-through' : 'subtasks-list__text'
            }
          >
            {title}
          </p>
        ) : (
          <input
            autoFocus={isEdit}
            className="subtasks-list__input"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                fetchEditRow(row_id, 'Название', value);
              }
            }}
          />
        )}
        <p className="subtasks-list__date">
          {dayjs(date).format('DD.MM.YYYY HH:mm')}
        </p>
        {authorEmail === email &&
          <>
            <button
              className="subtasks-list__btn-edit"
              onClick={(e) => {
                if (!isEdit) {
                  setIsEdit(true)
                } else {
                  fetchEditRow(row_id, 'Название', value);
                  setIsEdit(false)
                }
              }}
            >
              {isEdit ? '✓' : <img src={edit_icon} />}
            </button>
            <button
              className="subtasks-list__btn-delete"
              onClick={(e) => {
                fetchDeleteRow(row_id);
              }}
            >
              <img src={delete_icon} />
            </button>
          </>
        }
      </div>
    );
  };

  useEffect(() => {
    fetchGetCustomTableDataDict();
  }, [idTable]);


  // useEffect(() => {
  //   if(bottomRef?.current) {
  //     const container = bottomRef?.current
  //     container.scrollTop = container.scrollHeight;
  //   }
  // }, [bottomRef?.current]);

  return (
    <>
      {isModalFixed && (
        <ModalFixedMessage
          setIsModal={() => setIsModalFixed()} />
      )}

      {isShowModalFixed && <ModalShowFixedMessage
        pinMessage={pinMessage}
        setIsModal={(e) => setIsShowModalFixed(e)}
        eventId={eventId}
        comment_id={
          comments?.filter((el) => {
            if (el?.is_pinned) {
              return el
            }
          })
        }
      />}

      {isPrint && (
        <PrintableVersion
          title={title}
          description={description}
          eventId={eventId}
          selectedStatusTask={selectedStatusTask}
          selectedPriority={selectedPriority}
          executors={executors}
          coExecutors={coExecutors}
          selectedProject={selectedProject}
          endDate2={endDate2}
          setIsPrint={setIsPrint}
        />
      )}

      {isModalExit && (
        <ModalAddTaskExit
          setIsModal={(e) => setIsModalExit(e)}
          setIsModalClose={(e) => setIsModal(e)}
          fetchDeleteTask={fetchDeleteTask}
          eventId={eventId}
          setIsNew={setIsNew}
        />
      )}

      <div
        className="blackout"
        onClick={(e) => {
          let el = e.target;
          if (el.classList.contains('blackout')) {
            // sessionStorage.removeItem('eventId')
            // navigate(`/tasks/task-list/${taskId}${query ? `/filter=${query}` : ''}${searchParams ? `?${searchParams}` : ''}`)
            // setInfoTask(null)
            // if (isChanges) {
            //   setIsModalExit(true);
            // } else {
            //   setIsModal(false);
            // }
            const selProjId = isNew ? selectedProject?.value?.id : selectedProject?.value;
            if (
              isChanges &&
              selectedStatusTask?.value &&
              title &&
              !isCommentFocused()
            ) {
              if (isTextSelected) {
                return;
              } else {
                sessionStorage.removeItem('eventId');
                navigate(
                  `/tasks/task-list/${taskId}${query ? `&${query}` : ''}`
                );
                setIsNew(false);
                if (initIdCol !== (taskId === '-1' ? selProjId : selectedStatusTask?.value) || isNew) {
                  setActualId(initIdCol)
                  if (initIdCol !== selectedStatusTask?.value) {
                    setUpdateId(taskId === '-1' ? selProjId : selectedStatusTask?.value)
                  }

                }
                reset();
                setIsModal(false);
              }
            }
            if (!isChanges) {
              sessionStorage.removeItem('eventId');
              navigate(`/tasks/task-list/${taskId}${query ? `&${query}` : ''}`);
              setInfoTask(null);
              // if (!title) {
              //     fetchDeleteTask(eventId, false)
              // }
              if (isTextSelected) {
                return;
              }
              if (!selectedStatusTask?.value || !title) {
                setIsModalExit(true);
              } else {
                sessionStorage.removeItem('eventId');
                navigate(
                  `/tasks/task-list/${taskId}${query ? `&${query}` : ''}`
                );
                setIsNew(false);
                if (initIdCol !== (taskId === '-1' ? selProjId : selectedStatusTask?.value) || isNew) {
                  setActualId(initIdCol)
                  if (initIdCol !== selectedStatusTask?.value) {
                    setUpdateId(taskId === '-1' ? selProjId : selectedStatusTask?.value)
                  }
                }
                reset();
                setIsModal(false);
              }
            }
          }
          if (!e.target.className.includes('search-box-executor'))
            setShowOptions2(false);
        }}
      >
        <div className="modal-add-task">
          <div className="favorites-message">
            <div className="favorites-message__content">
              <button className={isFavorite ? "favorites-message__btn_active" : "favorites-message__btn"}
                onClick={(e) => {
                  setCurrentTab2('comment')
                  setIsFavorite(!isFavorite)
                }}
              >
                <img src={star_icon} />
              </button>
            </div>
          </div>
          <div className="modal-add-task__chat">
            <div
              className="modal-add-task__header modal-add-task__header-comment"
              style={{
                minHeight: '58px',
              }}
            >
              <TabsGreen
                btns={btns_2}
                currentBtn={currentTab2}
                setCurrentBtn={(e) => setCurrentTab2(e)}
                checkValue={true}
              />
            </div>

            <div className="modal-add-task__comment-container">
              <div
                className={currentTab2 === 'comment' ? "modal-add-task__list-message" : "modal-add-task__list"}
                style={{
                  marginTop:
                    currentTab2 === 'comment' && refFixedMessage?.current
                      ? refFixedMessage?.current?.getBoundingClientRect().height + 'px'
                      : '',
                }}
                onScroll={(e) => {
                  onScroll(bottomRef, setCommentPageHeight, setCommentPageScrollTop)
                  console.log('scroll')
                }}
                ref={bottomRef}
              >
                {isLoadingComments ? (
                  <div className="sceleton-chat">
                    <Stack className="sceleton-chat__content">
                      <div className="sceleton-chat__message">
                        <Skeleton
                          className="round"
                          variant="circular"
                          width={32}
                          height={32}
                        />
                        <Skeleton
                          variant="rectangular"
                          width={280}
                          height={150}
                        >
                          <Skeleton variant="circular" width={32} height={32} />
                        </Skeleton>
                      </div>

                      <div className="sceleton-chat__message">
                        <Skeleton
                          variant="rectangular"
                          width={280}
                          height={130}
                        />
                        <Skeleton
                          className="round"
                          variant="circular"
                          width={32}
                          height={32}
                        />
                      </div>

                      <div className="sceleton-chat__message">
                        <Skeleton
                          className="round"
                          variant="circular"
                          width={32}
                          height={32}
                        />
                        <Skeleton variant="rectangular" width={280} height={80}>
                          <Skeleton variant="circular" width={32} height={32} />
                        </Skeleton>
                      </div>

                      <div className="sceleton-chat__message">
                        <Skeleton
                          variant="rectangular"
                          width={280}
                          height={40}
                        />
                        <Skeleton
                          className="round"
                          variant="circular"
                          width={32}
                          height={32}
                        />
                      </div>
                    </Stack>
                  </div>
                ) : (
                  <>
                    {currentTab2 === 'comment' &&
                      pinMessage?.map((el) => {
                        const id = el?.id
                        return <div className="modal-add-task__fixed-message"
                          onClick={(e) => setIsShowModalFixed(true)}
                          ref={refFixedMessage}>
                          <div className="modal-add-task__fixed-message-content">
                            <div className="comment-item">
                              <p className="text_name">{el?.author?.name}</p>
                              <p className="text">{parse(el?.text)}</p>
                              {(el?.files)?.map(({ created_at, file_size_kb, label, value, extension }, ind) => {
                                return <FileItemComponent
                                  eventId={event_id}
                                  created_at={created_at}
                                  file_size_kb={file_size_kb}
                                  new_filename={label}
                                  url={value}
                                  authorEmail={''}
                                  type={'comments'}
                                  comment_id={id}
                                  isEdit={false}
                                />
                              })}
                            </div>
                            <ButtonBasic
                              grey
                              minWidth="40px"
                              width="40px"
                              text={<img src={pin} />}
                              onClick={(e) => {
                                e.stopPropagation()
                                fetchUnpinComment(el?.id)
                              }}
                            />
                          </div>
                        </div>
                      })
                    }

                    {(currentTab2 === 'comment' && comments.length > 0) ?
                      <>
                        {comments?.map((props, ind) => {
                          const { id, date } = props;
                          let date_message = null;

                          if (ind > 0) {
                            const now_month = new Date(date).getMonth();
                            const now_day = new Date(date).getDate();
                            const prev_month = new Date(
                              comments[ind - 1].date
                            ).getMonth();
                            const prev_day = new Date(
                              comments[ind - 1].date
                            ).getDate();

                            if (
                              now_month === prev_month &&
                              prev_day === now_day
                            ) {
                              return (
                                <CommentItem
                                  setSaveFileComment={setSaveFileComment}
                                  saveFile={saveFileComment}
                                  setSaveFile={setSaveFileComment}
                                  type={'comments'}
                                  fetchAddFiles={fetchAddFiles}
                                  fetchGetFiles={fetchGetFiles}
                                  fetchDeleteFiles={fetchDeleteFiles}
                                  updateComments={() => {
                                    setComments([])
                                    bottomRef?.current?.scrollTo(10000000, 10000000);
                                    setCommentIsSearchLastPage(false);
                                    //setCommentPageScrollTop(-1)
                                    setCommentPageHeight(0)
                                    setCommentPage(1)
                                    setIsUpdateComment(!isUpdateComment)
                                  }}
                                  eventId={eventId}
                                  comment_id={id}
                                  setCurrentMessageAnswer={(e) =>
                                    setCurrentMessageAnswer(e)
                                  }
                                  fetchPinComment={fetchPinComment}
                                  disabled={props.author.login !== email}
                                  key={id}
                                  {...props}
                                  event_id={eventId}
                                  setIsModalFixed={setIsModalFixed}
                                  fetchComments={(e) => fetchComments(e)}
                                  setCommentPage={(e) => setCommentPage(e)}
                                />
                              );
                            } else {
                              date_message = date;

                              return (
                                <>
                                  {/* {date_message && (
                                    <p
                                      className={
                                        'modal-add-task__date-message'
                                      }
                                    >
                                      {new Date(
                                        date_message
                                      )?.toLocaleDateString('ru-RU', {
                                        day: '2-digit',
                                        month: 'long',
                                        year: 'numeric',
                                      })}
                                    </p>
                                  )} */}

                                  <CommentItem
                                    setSaveFileComment={setSaveFileComment}
                                    saveFile={saveFileComment}
                                    setSaveFile={setSaveFileComment}
                                    type={'comments'}
                                    fetchAddFiles={fetchAddFiles}
                                    fetchGetFiles={fetchGetFiles}
                                    fetchDeleteFiles={fetchDeleteFiles}
                                    eventId={eventId}
                                    setCurrentMessageAnswer={(e) =>
                                      setCurrentMessageAnswer(e)
                                    }
                                    updateComments={() => {
                                      setComments([])
                                      bottomRef?.current?.scrollTo(10000000, 10000000);
                                      setCommentIsSearchLastPage(false);
                                      //setCommentPageScrollTop(-1)
                                      setCommentPageHeight(0)
                                      setCommentPage(1)
                                      setIsUpdateComment(!isUpdateComment)
                                    }}
                                    disabled={props.author.login !== email}
                                    comment_id={id}
                                    key={id}
                                    {...props}
                                    event_id={eventId}
                                    setIsModalFixed={setIsModalFixed}
                                    fetchComments={(e) => fetchComments(e)}
                                    fetchPinComment={fetchPinComment}
                                    setCommentPage={(e) => setCommentPage(e)}
                                  />


                                </>
                              );
                            }
                          } else {
                            return (
                              <>
                                {/* <p className={'modal-add-task__date-message'}>
                                  {new Date(date)?.toLocaleDateString(
                                    'ru-RU',
                                    {
                                      day: '2-digit',
                                      month: 'long',
                                      year: 'numeric',
                                    }
                                  )}
                                </p> */}

                                <CommentItem
                                  saveFile={saveFileComment}
                                  setSaveFile={setSaveFileComment}
                                  type={'comments'}
                                  comment_id={id}
                                  fetchAddFiles={fetchAddFiles}
                                  fetchGetFiles={fetchGetFiles}
                                  fetchDeleteFiles={fetchDeleteFiles}
                                  updateComments={() => {
                                    setComments([])
                                    bottomRef?.current?.scrollTo(10000000, 10000000);
                                    setCommentIsSearchLastPage(false);
                                    //setCommentPageScrollTop(-1)
                                    setCommentPageHeight(0)
                                    setCommentPage(1)
                                    setIsUpdateComment(!isUpdateComment)
                                  }}
                                  setCurrentMessageAnswer={(e) =>
                                    setCurrentMessageAnswer(e)
                                  }
                                  disabled={props.author.login !== email}
                                  key={id}
                                  {...props}
                                  event_id={eventId}
                                  setIsModalFixed={setIsModalFixed}
                                  fetchComments={(e) => fetchComments(e)}
                                  fetchPinComment={fetchPinComment}
                                  setCommentPage={(e) => setCommentPage(e)}
                                />


                              </>
                            );
                          }
                        })}
                      </>
                      :
                      currentTab2 === 'comment' && <p className="modal-add-task__no-data-message">
                        Нет комментариев
                      </p>
                    }

                    {currentTab2 === 'history' && (
                      <div className="history-actions__content">
                        {logsArr?.length > 0 ? (
                          logsArr?.map((el, i) => {
                            const { author, editor, comment, timestamp } = el;
                            return (
                              <div className="history-item">
                                <div className="history-item__info">
                                  {author?.login && (
                                    <p className="history-item__text-label">
                                      {author?.login}
                                    </p>
                                  )}
                                  {editor?.login && (
                                    <p className="history-item__text-label">
                                      {editor?.login}
                                    </p>
                                  )}
                                  {Array.isArray(comment) ? (
                                    comment?.map((el) => (
                                      <p className="history-item__text-value">
                                        {el}
                                      </p>
                                    ))
                                  ) : (
                                    <p className="history-item__text-value">
                                      {parse(comment)}
                                    </p>
                                  )}
                                </div>

                                {timestamp && (
                                  <p className="history-item__text-date">
                                    {new Date(timestamp).toLocaleDateString(
                                      'ru-RU',
                                      {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: '2-digit',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                      }
                                    )}
                                  </p>
                                )}
                              </div>
                            );
                          })
                        ) : (
                          <div className="modal-add-task__no-data-file">
                            <img src={no_data_file} />
                            <p className="modal-add-task__no-data-file-text">
                              Ничего не найдено
                            </p>
                          </div>
                        )}
                      </div>
                    )}

                    {currentTab2 == 'file' && current_file_list()?.length > 0 ? (
                      <>
                        {current_file_list() &&
                          current_file_list()?.map(
                            (
                              { created_at, file_size_kb, new_filename, url },
                              ind
                            ) => {
                              return (
                                <FileItemComponent
                                  isDelete={false}
                                  type={'description'}
                                  eventId={eventId}
                                  fetchDeleteFiles={fetchDeleteFiles}
                                  fetchGetFiles={fetchGetFiles}
                                  created_at={created_at}
                                  file_size_kb={file_size_kb}
                                  new_filename={new_filename}
                                  url={url}
                                  authorEmail={authorEmail}
                                  filesdata={filesdata}
                                  setFilesData={(e) => setFilesData(e)}
                                  saveFile={saveFile}
                                  setSaveFile={setSaveFile}
                                />
                              );
                            }
                          )}

                        {filesdataComments?.map(
                          (
                            { created_at, file_size_kb, new_filename, url },
                            ind
                          ) => {
                            return (
                              <FileItemComponent
                                isDelete={false}
                                ind={ind}
                                type={'comments'}
                                eventId={eventId}
                                fetchDeleteFiles={fetchDeleteFiles}
                                fetchGetFiles={fetchGetFiles}
                                created_at={created_at}
                                file_size_kb={file_size_kb}
                                new_filename={new_filename}
                                url={url}
                                authorEmail={authorEmail}
                                filesdata={filesdata}
                                setFilesData={(e) => setFilesData(e)}
                                saveFile={saveFile}
                                setSaveFile={setSaveFile}
                              />
                            );
                          }
                        )}
                      </>
                    ) : (
                      currentTab2 == 'file' && (
                        <div className="modal-add-task__no-data-file">
                          <img src={no_data_file} />
                          <p className="modal-add-task__no-data-file-text">
                            Ничего не найдено
                          </p>
                          <p className="modal-add-task__no-data-file-description">
                            Прикрепите изображение, файл, видео в комментариях,
                            описании или нажмите на кнопку выше
                          </p>
                        </div>
                      )
                    )}

                    {/* Подзадачи */}

                    {currentTab2 == 'task-list' && (
                      <div className="subtasks-list">
                        <div className="subtasks-list__header">
                          {!isNewSubtask ? (
                            <>
                              {authorEmail === email &&
                                <ButtonBasic
                                  grey
                                  width="100%"
                                  text={
                                    <div className="subtasks-list__btn">
                                      <img src={plus_circle} />
                                      Новая задача
                                    </div>
                                  }
                                  onClick={(e) => setIsNewSubtask(true)}
                                />}
                            </>

                          ) : (
                            <div className="subtasks-list__input-box">
                              <InputDinamycPlaceholder
                                onChange={(e) => setNameSubTask(e)}
                                placeholder=""
                                size="s"
                              />

                              <ButtonBasic
                                grey
                                width="40px"
                                minWidth="40px"
                                onClick={(e) => {
                                  setIsNewSubtask(false);
                                  fetchAddRow(nameSubTask);
                                  setNameSubTask('');
                                }}
                                text={<img src={check_green} />}
                              />
                            </div>
                          )}
                        </div>
                        {taskList.length > 0 ? (
                          <div className="subtasks-list__content">
                            {taskList.map(
                              ({ row_id, checkbox, title, date }, ind) => {
                                return (
                                  <SubtaskItem
                                    date={date}
                                    title={title}
                                    row_id={row_id}
                                    checkbox={checkbox}
                                  />
                                );
                              }
                            )}
                          </div>
                        ) : (
                          currentTab2 == 'task-list' && (
                            <div className="modal-add-task__no-data-file">
                              <img src={no_data_file} />
                              <p className="modal-add-task__no-data-file-text">
                                Нет задач в таск-листе
                              </p>
                              <p className="modal-add-task__no-data-file-description">
                                Нажмите на кнопку выше для создания первой
                                задачи в таск-листе
                              </p>
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>

              {currentTab2 === 'comment' && (
                <Comment
                  saveFile={saveFileMessage}
                  setSaveFile={setSaveFileMessage}
                  type={'comments'}
                  setComments={setComments}
                  eventId={eventId}
                  fetchDeleteFiles={() => {
                    setComments([])
                    bottomRef?.current?.scrollTo(10000000, 10000000);
                    setCommentIsSearchLastPage(false);
                    //setCommentPageScrollTop(-1)
                    setCommentPageHeight(0)
                    setCommentPage(1)
                    setIsUpdateComment(!isUpdateComment)
                    fetchDeleteFiles()
                  }}
                  fetchGetFiles={fetchGetFiles}
                  setIsChanges={(e) => setIsChanges(e)}
                  currentMessageAnswer={currentMessageAnswer}
                  setCurrentMessageAnswer={(e) => setCurrentMessageAnswer(e)}
                  disabled={!infoTask}
                  name={currentUserName}
                  email={email}
                  color={currentUserColor}
                  event_id={eventId}
                  fetchUpdateComms={() => {
                    setComments([])
                    bottomRef?.current?.scrollTo(10000000, 10000000);
                    setCommentIsSearchLastPage(false);
                    //setCommentPageScrollTop(-1)
                    setCommentPageHeight(0)
                    setCommentPage(1)
                    setIsUpdateComment(!isUpdateComment)
                  }}
                />
              )}
            </div>
          </div>

          <div className="modal-add-task__right-content">
            <div
              className="modal-add-task__header"
              style={{
                minHeight: '58px',
              }}
            >
              {isNew || isLoadFunc() ? (
                <>
                  <div className="modal-add-task__header-left-box">
                    <h1
                      className="modal-add-task__task-id"
                      onClick={() => {
                        if (eventId) {
                          navigator.clipboard.writeText(
                            `https://lk.ecomru.ru` + window.location.pathname
                          );
                        }
                      }}
                    >
                      {isLoadInfoTask
                        ? 'Загрузка...'
                        : eventId && (
                          <>
                            <ButtonCopy
                              value={eventId}
                              style={{ position: 'static' }}
                              icon={copy_icon}
                            />
                            {/* <img src={copy_icon} /> */}
                            {`ID${eventId}`}
                          </>
                        )}
                    </h1>

                    <DropDownSelector
                      options_prop={allColumns}
                      placeholder="Проект"
                      state={selectedProject}
                      setState={(e) => setSelectedProject(e)}
                      disabled={infoTask?.id}
                    />
                  </div>

                  {isSave !== null && (
                    <div className="modal-add-task__save-indicator">
                      {isSave ? (
                        <>
                          <img src={check_circle} />
                          <p style={{ color: '#00B45E' }}>Сохранено!</p>
                        </>
                      ) : (
                        <>
                          <MoonLoader
                            size={18}
                            color="#7b7b7b"
                            speedMultiplier={0.5}
                          />
                          <p>Идет сохранение...</p>
                        </>
                      )}
                    </div>
                  )}

                  <ButtonBasic
                    grey
                    minWidth="40px"
                    width="40px"
                    onClick={() => setIsPrint(true)}
                    style={{
                      marginLeft: 'auto',
                    }}
                    text={<img src={print_icon} />}
                  />

                  {email == authorEmail && (
                    <ButtonActionDrop id={1} btns={btns} />
                  )}

                  <button
                    className="modal-add-task__btn-close"
                    onClick={(e) => {
                      const selProjId = isNew ? selectedProject?.value?.id : selectedProject?.value;
                      sessionStorage.removeItem('eventId');
                      navigate(
                        `/tasks/task-list/${taskId}${query ? `&${query}` : ''}`
                      );
                      setInfoTask(null);
                      // if (!title) {
                      //     fetchDeleteTask(eventId, false)
                      // }
                      if (initIdCol !== (taskId === '-1' ? selProjId : selectedStatusTask?.value) || isNew) {
                        setActualId(initIdCol)
                        setUpdateId(taskId === '-1' ? selProjId : selectedStatusTask?.value)
                      }
                      if (!selectedStatusTask?.value || !title) {
                        setIsModalExit(true);
                      } else {
                        setIsNew(false);
                        reset();
                        setIsModal(false);
                      }
                    }}
                  >
                    <img src={close_icon} />
                  </button>
                </>
              ) : (
                <Stack className="sceleton-select-group__content">
                  {/* For variant="text", adjust the height via font-size */}
                  <Skeleton variant="rectangular" width={400} height={28} />
                </Stack>
              )}
            </div>

            <div className="modal-add-task__header">
              {isNew || isLoadFunc() ? (
                <>
                  <div className="executor">
                    <div className="executor-content">
                      <ExecutorPlaceholder
                        hideTooltip
                        name={authorName}
                        color={authorColor}
                        email={authorEmail}
                      />
                      <p className="text_name">{authorName}</p>
                    </div>
                  </div>
                  <div
                    onBlur={() => {
                      fetchSetPriority();
                    }}
                  >
                    <DropDownSelector
                      options_prop={OPTIONS_PRIORITY}
                      placeholder="Приоритет"
                      isClearable={false}
                      value={selectedPriority}
                      defaultValue={selectedPriority}
                      state={selectedPriority}
                      setState={(e) => {
                        setSelectedPriority(e);
                      }}
                      maxWidth="100px"
                    />
                  </div>

                  <div
                    onBlur={() => {
                      fetchSetTaskType();
                    }}
                  >
                    <DropDownSelector
                      options_prop={statusTask}
                      placeholder="Статус"
                      isClearable={false}
                      value={selectedStatusTask}
                      defaultValue={selectedStatusTask}
                      state={selectedStatusTask}
                      setState={(e) => {
                        setSelectedStatusTask(e);
                      }}
                      maxWidth="100px"
                    />
                  </div>

                  <div className="select-group">
                    <div
                      onBlur={(e) => {
                        if (initLoadDate) {
                          setInitLoaderDate(false);
                        }
                      }}
                    >
                      <DateBox
                        disabled={check}
                        startDate={endDate}
                        description={'Крайний день выполнения'}
                        setStartDate={(e) => {
                          setEndDate(e);
                        }}
                      />
                    </div>

                    <label className="checkbox-content">
                      <Checkbox value={check} onChange={(e) => setCheck(e)} />
                      <p className="text">Без срока</p>
                    </label>
                  </div>
                </>
              ) : (
                <div className="sceleton-header">
                  <Stack className="sceleton-header__content">
                    {/* For variant="text", adjust the height via font-size */}
                    <Skeleton variant="circular" width={40} height={40} />
                    {[...Array(3)].map((el) => {
                      return (
                        <>
                          <Skeleton
                            variant="rectangular"
                            width={210}
                            height={40}
                          />
                        </>
                      );
                    })}
                  </Stack>
                </div>
              )}
            </div>

            <div className="modal-add-task__content">
              <div className="main-info">
                {isNew || isLoadFunc() ? (
                  <>
                    <div
                      id="task-title-container"
                      onBlur={(e) => {
                        fetchSetName();
                      }}
                    >
                      {/* <TitleContentEditable
                        setIsChanges={(e) => setIsChanges(e)}
                        disabled={authorEmail !== email}
                        placeholder="Добавьте название"
                        title={title}
                        setTitle={(e) => setTitle(e)}
                      /> */}

                      <TitleContentEditable
                        setIsChanges={(e) => setIsChanges(e)}
                        disabled={authorEmail !== email}
                        placeholder="Добавьте название"
                        title={title}
                        setTitle={(e) => setTitle(e)}
                      />
                    </div>

                    <div
                      id="task-description-container"
                      onBlur={(e) => {
                        fetchSetDescription();
                      }}
                    >
                      <Editor
                        setSaveFile={setSaveFile}
                        type={'description'}
                        fetchAddFiles={fetchAddFiles}
                        fetchGetFiles={fetchGetFiles}
                        eventId={eventId}
                        setIsChanges={(e) => setIsChanges(e)}
                        filesdata={filesdata}
                        setFiles={(e) => setFiles(e)}
                        disabled={authorEmail !== email}
                        bounds=".main-info"
                        title="Описание"
                        placeholder="Редактировать описание задачи"
                        description={description}
                        setDescription={(e) => setDescription(e)}
                        setFilesData={setFilesData}
                        authorEmail={authorEmail}
                        hideBtn={true}
                        fetchDeleteFiles={fetchDeleteFiles}
                        saveFile={saveFile}
                      />
                    </div>

                    <div className="modal-add-task__list-fields">
                      {fields?.map(({ name, type, value, field_id }) => {
                        return (
                          <InputDinamycPlaceholder
                            key={field_id}
                            placeholder={name}
                            changeValue={value}
                            setValue={(e) => e}
                            size="s"
                          />
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <div className="sceleton-main-info">
                    <Stack className="sceleton-main-info__content">
                      <Skeleton variant="rectangular" height={48} />
                      <Skeleton variant="rectangular" width={100} height={16} />
                      <Skeleton variant="rectangular" height={200} />
                    </Stack>
                  </div>
                )}
              </div>

              <div className="additional-information">
                <div className="additional-information__info">
                  <div className="select-group">
                    {isNew || isLoadFunc() ? (
                      <>
                        <>
                          <div
                            onClick={(e) => {
                              if (initLoadExecutor) {
                                setInitLoaderExecutor(false);
                              }
                            }}
                          >
                            <SelectExecutor
                              executors={executors}
                              setExecutors={(e) => {
                                if (!isNew) {
                                  const selProjId = selectedProject?.value;
                                  setUpdateId(taskId === '-1' ? selProjId : selectedStatusTask?.value)
                                  //  setUpdateId(selectedStatusTask?.value)
                                }
                                setExecutors(e);
                              }}
                              executorOptions={executorOptions}
                              title="Исполнитель"
                              placeholder="Назначить"
                            />
                          </div>

                          <div className="select-group__date-box">
                            <label className="checkbox-content">
                              <Checkbox
                                value={
                                  new Date(endDate2)?.toLocaleDateString(
                                    'ru-RU'
                                  ) === new Date()?.toLocaleDateString('ru-RU')
                                }
                                onChange={(e) => setEndDate2(new Date())}
                                disabled={
                                  new Date(endDate2)?.toLocaleDateString(
                                    'ru-RU'
                                  ) === new Date()?.toLocaleDateString('ru-RU')
                                }
                              />
                              <p className="text">Выполню сегодня</p>
                            </label>

                            <div
                              onBlur={(e) => {
                                if (initLoadDate) {
                                  setInitLoaderDate(false);
                                }
                              }}
                            >
                              <DateBox
                                startDate={endDate2}
                                description={'Срок выполнения'}
                                setStartDate={(e) => {
                                  setEndDate2(e);
                                }}
                              />
                            </div>
                          </div>

                          {
                            <div
                              onClick={(e) => {
                                if (initLoadCoExecutor) {
                                  setInitLoaderCoExecutor(false);
                                }
                              }}
                            >
                              <SelectExecutor
                                executors={coExecutors}
                                setExecutors={(e) => {
                                  setCoExecutors(e);
                                }}
                                executorOptions={executorOptions?.filter(
                                  ({ id }) => {
                                    if (executors[0]?.id) {
                                      return id != executors[0].id;
                                    }
                                  }
                                )}
                                title="Cоисполнители"
                                placeholder="Назначить"
                                hideTooltip={false}
                                multi
                              />
                            </div>
                          }
                        </>
                      </>
                    ) : (
                      <div className="sceleton-select-group">
                        <Stack className="sceleton-select-group__content">
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={16}
                          />

                          <div className="sceleton-select-group__user">
                            <Skeleton
                              variant="circular"
                              width={40}
                              height={40}
                            />
                            <Skeleton
                              variant="rectangular"
                              width={97}
                              height={40}
                            />
                          </div>

                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={16}
                          />
                          <Skeleton
                            variant="rectangular"
                            width={200}
                            height={32}
                          />
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={16}
                          />

                          <div className="sceleton-select-group__user">
                            <Skeleton
                              variant="circular"
                              width={40}
                              height={40}
                            />
                            <Skeleton
                              variant="rectangular"
                              width={97}
                              height={40}
                            />
                          </div>
                          <div className="sceleton-select-group__user">
                            <Skeleton
                              variant="circular"
                              width={40}
                              height={40}
                            />
                            <Skeleton
                              variant="rectangular"
                              width={97}
                              height={40}
                            />
                          </div>
                        </Stack>
                      </div>
                    )}

                    {/* <ButtonBasic
                                        disabled={isLoadInfoTask}
                                        green
                                        width='100%'
                                        style={{
                                            marginTop: 'auto'
                                        }}
                                        text={infoTask ? 'Сохранить задачу' : 'Создать'}
                                        onClick={() => {
                                            //   infoTask ? handleMainButtonClick(true) : handleMainButtonClick(false)
                                        }}
                                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ModalAddTask2 };
