import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import "./Sceleton.scss"

const Sceleton = ({ height = '400' }) => {

    return (
        <div className='sceleton-col'>
            <Stack className='sceleton-col__content'>
                <Skeleton  className='sceleton-col__item' variant="rectangular" />
            </Stack>
        </div>
    )
}

export { Sceleton }