import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import "./SceletonCardOrder.scss"

const SceletonCardOrder = ({ height = '400' }) => {

    return (
        <div className='sceleton-card-order'>
            <Stack className='sceleton-card-order__content'>
                {[...Array(1)].map((el) => {
                    return <Skeleton variant="rectangular" width={160} height={92} />
                })}
            </Stack>
        </div>
    )
}

export { SceletonCardOrder }