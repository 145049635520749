import Cookies from 'universal-cookie'
import customHeaders, { getSpace } from '../../../common/headers'
import plus_icon from './img/plus_icon.svg'
import blank_image from './img/blank_image.svg'
import { RavshanHttps, KonstantinAddImgHttps } from '../../../fetchUrls'
import { useEffect, useState } from 'react'
import { InputDinamycPlaceholder, ButtonBasic, Tabs } from "@lk-gtcom/ecomlab-components"
import useGeneralStore from '../../../store/general'
import './ModalAddNewGroup.scss'



const ModalAddNewGroup = ({ ...props }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }

    const { setIsModal, cardInfo, setCardInfo } = props
    const [name, setName] = useState('')
    const [accounts, setAccounts] = useState([])
    const [selectedAccounts, setSelectedAccounts] = useState([])
    const [edit, setEdit] = useState(false)
    const [group_id, setId] = useState(-1)
    const [createdAt, setCreatedAt] = useState('')
    const [uploadImage, setUploadImage] = useState('')
    const cookies = new Cookies()
    const token = cookies.get('auth')


    const btn_tab = [
        {
            label: `Доступные ${accounts?.length - selectedAccounts?.length}`,
            value: 'available'
        },
        {
            label: `Добавленные ${selectedAccounts?.length}`,
            value: 'added'
        }
    ]
    const [pageValue, setPageValue] = useState(btn_tab[0].value)

    const onCreateHandler = () => {
        if (selectedAccounts?.length > 0) {
            const url = `${RavshanHttps}/api/social/${edit ? 'change_group' : 'create_group'}`

            const body = JSON.stringify({
                name,
                accounts: selectedAccounts,
                group_id,
                description: '',
                image: uploadImage,
                status: cardInfo?.status
            })

            fetch(url, { body, method: edit ? 'PUT' : 'POST', headers })
                .then(res => {
                    if (!res?.ok) { throw new Error('bad response') }
                    return res.json()
                })
                .then(() => {
                    setSelectedAccounts([])
                    setCardInfo({})
                    setIsModal(false)
                })
                .catch(e => console.log(e))
        }
    }


    const fetchAccounts = signal => {
        const url = `${RavshanHttps}/api/social/get_accounts`

        const body = JSON.stringify({
            page: 1,
            limit: 10,
            search: "",
            status: "active",
            mp_name: "all",
            order_param: "name",
            order_param_direction: "asc"
        })

        fetch(url, { body: body, method: 'POST', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad response')
                }
            })
            .then(json => {
                console.log(json)
                setAccounts(json.map((el) => ({ label: el[0].name, icon: el[0].icon_link, id: el[0].id })))
            })
            .catch(err => {
                console.error(err)
            })
    }

    const fetchPostImg = (file) => {

        const fd = new FormData()
        fd.append('images', file)
        const url = `${KonstantinAddImgHttps}/clients/images`

        const requestHeaders = {
            'Accept': 'application/json',
            'Cache-control': 'no-cache',
            'Authorization': `Bearer ${token}`
        }

        fetch(url, { body: fd, method: 'POST', headers: requestHeaders })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad response')
                }
            })
            .then(json => {
                if (json.images) {
                    setUploadImage(json.images[0].url)
                }
            })
            .catch(err => {
                console.error(err)
            })
    }

    const handleFileInput = (e) => {
        if (e.target.files.length === 0) {
            e.preventDefault()
            return
        }
        if (e.target.files[0]) {
            fetchPostImg(e.target.files[0])
        }
    }

    useEffect(() => {
        const abortController = new AbortController()
        fetchAccounts(abortController.signal)
        return () => abortController.abort()
    }, [selectedSpace])

    useEffect(() => {
        if (cardInfo?.id) {
            const { accounts, name, id, image, created_at } = cardInfo
            if (accounts) {
                setSelectedAccounts(accounts.map(({ id }) => id))
            }
            if (name) { setName(name) }
            if (id) { setId(id) }
            if (image) { setUploadImage(image) }
            if (created_at) { setCreatedAt(`${new Date(created_at)?.toLocaleDateString('ru-RU')} ${new Date(created_at)?.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}`) }

            setEdit(true)
        }
    }, [cardInfo])

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                // setIsModal(false)
                // if (setCardInfo) {
                //     setCardInfo({})
                //     setEdit(false)
                // }
            }
        }}>
            <div className='modal-add-new-group'>
                <div className='modal-add-new-group__header'>
                    <h1 className='title'>{edit ? 'Редактировать группу' : 'Новая группа'}</h1>
                    <p className='text_date-create'>Создано {createdAt}</p>
                </div>

                <div className='modal-add-new-group__content'>
                    <div className='top-content'>
                        <h3 className='title'>Обложка</h3>
                        <div className='drop-zone-and-input'>
                            <div className='drop-zone'>
                                <input
                                    type='file'
                                    accept='image/png, image/jpg, image/jpeg'
                                    multiple={true}
                                    className='input-file'
                                    onChange={(e) => {
                                        handleFileInput(e)
                                    }}
                                />
                                {
                                    uploadImage ?
                                        <img className='img' src={uploadImage} />
                                        :
                                        <img src={plus_icon} />
                                }
                            </div>

                            <InputDinamycPlaceholder
                                onChange={(e) => setName(e)}
                                textErr={'Неверное название'}
                                placeholder='Название группы'
                                tooltipStatic='Введите название группы и установите ей статус (например актинвый или неактивный)'
                                autoComplete={false}
                                changeValue={name}
                            />
                        </div>
                    </div>
                </div>

                <div className='list-content'>
                    <h3 className='title'>Социальные сети</h3>
                    <Tabs tabs={btn_tab} pageValue={pageValue} setPageValue={(e) => setPageValue(e)} />
                    <div className='network-group'>
                        {
                            accounts?.filter(({ id }) => {
                                if (pageValue === 'added') {
                                    return selectedAccounts?.indexOf(id) !== -1
                                } else {
                                    return selectedAccounts?.indexOf(id) === -1
                                }
                            })?.map(({ label, icon, id }) => {
                                return <div className={`${(selectedAccounts.indexOf(id) !== -1 ? 'notification-item_active' : 'notification-item')}`}
                                    key={id + label}
                                    onClick={e => {
                                        if (id) {
                                            if (selectedAccounts.indexOf(id) === -1) {
                                                setSelectedAccounts(prev => [...prev, id])
                                            } else {
                                                setSelectedAccounts(prev => prev.filter(el => el !== id))
                                            }
                                        }
                                    }}
                                >
                                    <img src={icon ? icon : blank_image}
                                        onLoad={e => {
                                            e.target.style.backgroundImage = 'none'
                                        }}
                                    />
                                    <div className='changing-content'>
                                        <ButtonBasic
                                            red={pageValue == 'added'}
                                            green={pageValue !== 'added'}
                                            width='100%'
                                            size='32px'
                                            text={pageValue !== 'added' ? 'Выбрать' : 'Убрать'}
                                            onClick={(e) => (id)}
                                        />
                                        <p className='text'>{label}</p>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>       


                <div className='modal-add-new-group__bottom' style={{gap: '1em', display: 'flex'}}>
                    <ButtonBasic
                        grey
                        size='40px'
                        width='162px'
                        text='Отмена'
                        onClick={e => {
                            setIsModal(false)
                            if (setCardInfo) {
                                setCardInfo({})
                                setEdit(false)
                            }
                        }}
                    />
                    
                    <ButtonBasic
                        green
                        size='40px'
                        width='162px'
                        text='Сохранить'
                        onClick={e => onCreateHandler()}
                    />
                </div>
            </div>
        </div>
    )
}

export { ModalAddNewGroup }