import Cookies from 'universal-cookie'
import customHeaders, { getSpace } from '../../../common/headers'
import plus_icon from './img/plus_icon.svg'
import blank_image from './img/blank_image.svg'
import { RavshanHttps, KonstantinAddImgHttps } from '../../../fetchUrls'
import { useEffect, useState } from 'react'
import { InputDinamycPlaceholder, ButtonBasic, Tabs } from "@lk-gtcom/ecomlab-components"
import useGeneralStore from '../../../store/general'
import './ModalAddNewWiki.scss'



const ModalAddNewWiki = ({ ...props }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }

    const { setIsModal, cardInfo, setCardInfo, fetchWikiList } = props
    const [name, setName] = useState('')
    const [accounts, setAccounts] = useState([])
    const [selectedAccounts, setSelectedAccounts] = useState([])
    const [edit, setEdit] = useState(false)
    const [group_id, setId] = useState(-1)
    const [createdAt, setCreatedAt] = useState('')
    const [uploadImage, setUploadImage] = useState('')
    const cookies = new Cookies()
    const token = cookies.get('auth')



    const createWiki = () => {
        const url = `${RavshanHttps}/api/v2/article/wiki/create`

        const body = JSON.stringify({
            name
        })

        fetch(url, { body, method: 'POST', headers,  })
            .then(res => {
                if (!res?.ok) { throw new Error('bad response') }
                return res.json()
            })
            .then(json => {
                fetchWikiList()
                setIsModal(false)
            })
            .catch(e => console.log(e))
    }


    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                // setIsModal(false)
                // if (setCardInfo) {
                //     setCardInfo({})
                //     setEdit(false)
                // }
            }
        }}>
            <div className='modal-add-new-group'>
                <div className='modal-add-new-group__header'>
                    <h1 className='title'>{edit ? 'Редактировать wiki' : 'Новое wiki'}</h1>
                    <p className='text_date-create'>{edit ? `Создано ${createdAt}` : ''}</p>
                </div>

                <div className='modal-add-new-group__content'>
                        <InputDinamycPlaceholder
                            onChange={(e) => setName(e)}
                            textErr={'Неверное название'}
                            placeholder='Название wiki'
                            tooltipStatic='Введите название wiki'
                            autoComplete={false}
                            changeValue={name}
                        />
                </div>
 


                <div className='modal-add-new-group__bottom' style={{gap: '1em', display: 'flex'}}>
                    <ButtonBasic
                        grey
                        size='40px'
                        width='162px'
                        text='Отмена'
                        onClick={e => {
                            setIsModal(false)
                            if (setCardInfo) {
                                setCardInfo({})
                                setEdit(false)
                            }
                        }}
                    />
                    
                    <ButtonBasic
                        green
                        size='40px'
                        width='162px'
                        text='Сохранить'
                        onClick={e => createWiki()}
                    />
                </div>
            </div>
        </div>
    )
}

export { ModalAddNewWiki }