import { create } from 'zustand';

const useModal = create((set) => ({
  isModalActive: false,
  setIsModalActive: (value) => set({ isModalActive: value }),

  isServiceSelected: false,
  setIsServiceSelected: (value) => set({ isServiceSelected: value }),

  isServiceConnected: false,
  setIsServiceConnected: (value) => set({ isServiceConnected: value }),

  isModalAddEmployee: false,
  setIsModalAddEmployee: (value) => set({ isModalAddEmployee: value }),

  fetchedServiceData: null,
  setFetchedServiceData: (value) => set({ fetchedServiceData: value }),

  isCurrentArticle: '',
  setIsCurrentArticle: (value) => set({ isCurrentArticle: value }),

  selectedServiceItemId: [],
  setSelectedServiceItemId: (value) => set({ selectedServiceItemId: value }),
}));

export default useModal;
