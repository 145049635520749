import { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import drop_icon from './img/drop_icon.svg';
import './SidebarMenu.scss'

const SidebarMenu = ({ label, path, icon, subItems, active, setActive, hover, setActiveItems, activeItems, setIsActiveItems, isActiveItems }) => {
    const [subItemsList, setSubItemsList] = useState([])
    const [openItems, setOpenItems] = useState(new Set(['Содержание отчета', 'Анализ','Анализ площадок', 'Финансы', 'Отзывы и вопросы', 'Обратная связь', 'Управление остатками','Управление ценами', 'Все уведомления', 'Аналитика конкурентов', 'Общие графики', 'Рекламные кампании', 'Подключения', 'Товары', 'Добавить', 'Мульти интеграция', 'Инструменты', 'Рекламная аналитика', 'По заказная аналитика', 'По заказная аналитика', 'Кросс аналитика', 'Акции', 'Социальные сети', 'Wildberries', 'OZON', 'Yandex Market', 'Ссылки на конкурентов', 'Настройка пользователей']));
    const urlPath = useParams()

    const handleOpenDropItem = () => {
        const newState = new Set(openItems);
        if (openItems.has(label)) {
            newState.delete(label);
        } else {
            newState.add(label);
        }
        setOpenItems(newState)
    }

    const generateSidebar = () => {
        if (Array.isArray(subItems)) {
            const title = label
           
            setSubItemsList([
                ...subItems.map(({ label, path, black }, ind) => {
                    if (path === urlPath['*']) {
                        setActive(label + title)
                    }
                    return (
                        <Link to={path ? path : "not-active"} key={label + ind} className='sidebar-inside__link'>
                            <li
                                key={label + Math.random(0, 1) * 1000}
                                onClick={() => {
                                    setIsActiveItems(undefined)
                                    setActive(label + title)
                                }}
                                className={(active) === (label + title) ? 'sidebar-inside__list-item_active' : 'sidebar-inside__list-item'}
                            >
                                {label}
                            </li>
                        </Link>
                    )
                }
                )
            ])
        }
    }

    useEffect(() => {
        generateSidebar()
    }, [openItems, urlPath, active])

    const dropClass = openItems.has(label) ? 'sidebar-inside__icon-drop_active' : 'sidebar-inside__icon-drop'
    const sidebarClass = activeItems === label ? 'sidebar-inside__link-box_grey_active' : 'sidebar-inside__link-box_grey'
    const listClass = openItems.has(label) ? 'sidebar-inside__list' : 'sidebar-inside__list_hide'
    const pathPage = path ? path : 'not-active'

    return (
        <div key={label + icon + Math.round(0, 1) * 1000} className='sidebar-inside__item'>
            <div className={subItems?.length > 0 ? 'sidebar-inside__link-box' : sidebarClass} onClick={(e) => {
               if (activeItems) {
                setActiveItems(label)
               } 
                handleOpenDropItem(e)              
            }}>
                <Link to={ (subItems?.length == 0) ? pathPage : '#' } className={((isActiveItems == label) && subItems?.length < 1) ? 'sidebar-inside__link_active' : 'sidebar-inside__link'} 
                onClick={(e) => {
                    setIsActiveItems(label)
                    setActive(undefined)
                    }}>{label}</Link>
                {subItems?.length > 0 ? <img src={drop_icon} className={dropClass} /> : null}
                {icon ? <img src={icon} /> : null}
            </div>
            <div className='sidebar-inside__drop'>
                <ul className={listClass}>
                    {subItemsList}
                </ul>
            </div>
        </div>
    )
}

export { SidebarMenu }