import customHeaders, { getSpace } from '../../common/headers';
import { useEffect, useState, useRef } from 'react';
import {
  ButtonBasic,
  TableBox,
  DropDownSelector,
  RangeBox,
} from '@lk-gtcom/ecomlab-components';
import add_icon from './img/add.svg';
import { KonstantinMasterHttp, KonstantinMasterHttps } from '../../fetchUrls';
import { useDataTable } from '../../common/hooks';
import useGeneralStore from '../../store/general';
import './FinanceForServices.scss';
import AddBalanceModal from '../../components/AddBalanceModal';

const options_period = [
  {
    label: 'дням',
    value: 'День',
  },
  {
    label: 'неделям',
    value: 'Неделя',
  },

  {
    label: 'месяцам',
    value: 'Месяц',
  },
];

const operationTypeArr = [
  'Все',
  'Оплата банковской картой',
  'Анализ цен на товары',
  'Парсинг характеристик товара',
];

const FinanceForServices = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [selectedPeriod, setSelectedPeriod] = useState(options_period[0]);
  const [isModalBalance, setIsModalBalance] = useState(false);

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [operationType, setOpearationType] = useState([]);
  const [selectedOperationType, setSelectedOpearationType] = useState({});

  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { setLoading, setFetchedData, setHeaders, setSort, setFilters } =
    setTableFuncs;

  const fetchTableData = (params, abortController = new AbortController()) => {
    let sort;
    let filters;

    if (params) {
      [sort, filters] = params;
    }

    const [date_from, date_to] = date;
    const orderParam = sort?.[0]
      ? `&order_param=${sort?.[0]}&order_param_direction=${sort?.[1]}`
      : '';
    const operationType = `&operation_type=${selectedOperationType?.value}`;

    setLoading(true);
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/finance/by-services/details-by-service?period=${selectedPeriod?.value}${operationType}${orderParam}`;
    } else {
      url = `${KonstantinMasterHttp}/finance/by-services/details-by-service?period=${selectedPeriod?.value}${operationType}${orderParam}`;
    }

    const body = JSON.stringify({
      date_from: new Date(date_from + 10)?.toISOString().split('T')?.[0],
      date_to: new Date(date_to)?.toISOString().split('T')?.[0],
      filters,
      limit: paginatorRef.current?.limit,
      page: paginatorRef.current?.page,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setTable(json);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
        setLoading(false);
      });
  };

  const fetchOperationType = (abortController = new AbortController()) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/finance/by-services/operation-types`;
    } else {
      url = `${KonstantinMasterHttp}/finance/by-services/operation-types`;
    }

    const [date_from, date_to] = date;

    const body = JSON.stringify({
      date_from: new Date(date_from + 10)?.toISOString().split('T')?.[0],
      date_to: new Date(date_to)?.toISOString().split('T')?.[0],
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setOpearationType(json);
        if (
          Object.keys(selectedOperationType)?.length === 0 ||
          !json.some((item) => item.value === selectedOperationType?.value)
        ) {
          setSelectedOpearationType(json?.[0]);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
        setLoading(false);
      });
  };

  useEffect(() => {
    const abortController = new AbortController();
    fetchTableData([], abortController);
    return () => abortController.abort();
  }, [date, selectedOperationType, selectedPeriod, selectedSpace]);

  useEffect(() => {
    fetchOperationType();
  }, [date, selectedSpace]);

  return (
    <div className="finance-for-services">
      {isModalBalance && (
        <AddBalanceModal setIsModalActive={() => setIsModalBalance(false)} />
      )}
      <div className="finance-for-services__header">
        <h1 className="finance-for-services__title">По услугам</h1>
        <ButtonBasic
          green
          width="190px"
          text={
            <div className="content-btn">
              <img src={add_icon} alt="icon" />
              Пополнить баланс
            </div>
          }
          onClick={() => setIsModalBalance(true)}
        />
      </div>

      <div className="finance-for-services__table">
        <div className="finance-for-services__header-table">
          {/* <h2 className="finance-for-services__title_period">Детализация по услугам</h2> */}

          <div className="range-group-btn-and-range-box">
            <div className="drop-box operation-type">
              <p className="text">Тип операции</p>
              <DropDownSelector
                options_prop={operationType}
                state={selectedOperationType}
                setState={(e) => setSelectedOpearationType(e)}
                defaultValue={operationType?.[0]}
                className="connections_page_selector"
                isClearable={false}
                width={'300px'}
              />
            </div>
            <div className="drop-box">
              <p className="text">По</p>
              <DropDownSelector
                options_prop={options_period}
                state={selectedPeriod}
                setState={(e) => setSelectedPeriod(e)}
                defaultValue={selectedPeriod}
                className="connections_page_selector"
                isClearable={false}
              />
            </div>

            <RangeBox setDate={(e) => setDate(e)} />
          </div>
        </div>

        <TableBox ref={paginatorRef} paginator={true} {...tableProps} />
      </div>
    </div>
  );
};

export { FinanceForServices };
