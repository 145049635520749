import { useState, useEffect } from 'react';

const useTextSelectionInModal = (modalSelector) => {
  const [isTextSelected, setIsTextSelected] = useState(false);

  useEffect(() => {
    const modalElement = document?.querySelector(modalSelector);

    if (!modalElement) {
      return;
    }

    const handleSelectionChange = () => {
      const selection = document.getSelection();

      if (!selection || selection.rangeCount === 0 || selection.isCollapsed) {
        setIsTextSelected(false);
        return;
      }

      const range = selection.getRangeAt(0);
      const isInModal = modalElement.contains(range.commonAncestorContainer);

      setIsTextSelected(isInModal);
    };

    document.addEventListener('selectionchange', handleSelectionChange);

    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange);
    };
  }, [modalSelector]);

  return isTextSelected;
};

export { useTextSelectionInModal };
