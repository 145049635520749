import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { ButtonActionDrop } from '../../../components/Buttons/ButtonActionDrop/ButtonActionDrop';
import calendar from './img/bx-calendar.svg'
import dayjs from 'dayjs';
import { RavshanHttp, RavshanHttps } from '../../../fetchUrls';
import customHeaders, { getSpace } from '../../../common/headers';
import useGeneralStore from '../../../store/general';
import './BordCard.scss'


const BordCard = ({
    name,
    sum_task,
    date,
    id,
    start_time,
    end_time,
    fetchGetBord,
    setIsModal,
    setCurrentBord,
    currentBord,
    setIsEdit
}) => {

    const selectedSpace = useGeneralStore((state) => state.selectedSpace);
    const headers = { ...customHeaders, ...getSpace(selectedSpace) };

    const deleteBord = () => {
        let url;
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/v3/tasks/board/delete_task_board`;
        } else {
            url = `${RavshanHttps}/api/v3/tasks/board/delete_task_board`;
        }

        const body = JSON.stringify({
            task_board_id: id
        });

        fetch(url, { body, method: 'DELETE', headers })
            .then(async (res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    const err = await res.json();
                    throw Error(JSON.stringify(err));
                }
            })
            .then((json) => {
                console.log(json)
                fetchGetBord()
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const btns = [,
        {
            btn: 'Открыть',
            func: () => { 
                setCurrentBord({
                    name,
                    sum_task,
                    date,
                    id,
                    start_time,
                    end_time,
                })
                setIsEdit(true)
                setIsModal(true)
             },
        },
        {
            btn: 'Удалить',
            func: () => { deleteBord() },
        }
    ];

    return (
        <div className={currentBord?.name == name ? 'bord-card_active' : 'bord-card'} key={name + sum_task}
            onClick={(e) => {
                setCurrentBord({
                    name,
                    sum_task,
                    date,
                    id,
                    start_time,
                    end_time,
                })
            }}
        >
            <div className='bord-card__header'>
                <h4 className='bord-card__title'>{name}</h4> 
                 <ButtonActionDrop
                    id={1}
                    btns={btns}
                    size='s' />   

            </div>
            <div className='bord-card__info'>
                {(end_time) && <div className='bord-card__date-box'>
                    <img src={calendar} />
                    <div className='bord-card__date'>{dayjs(end_time).format('DD-MM-YYYY')}</div>
                </div>}
                <div className='bord-card__sum-task'>{sum_task ? sum_task : 0} задачи</div>
            </div>
        </div>
    )
}

export { BordCard }