import all_tab from './img/all_tab.svg';
import customHeaders, { getSpace } from '../../common/headers';
import LineChart from '../../charts/LineChart';
import active_tab from './img/active_tab.svg';
import disactive_tab from './img/disactive_tab.svg';
import { RavshanHttps, OlegHttps } from '../../fetchUrls';
import { useDataTable } from '../../common/hooks';
import { FilterContainer } from '../FilterContainer/FilterContainer';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import {
  fetchAdvCampaignTypeFilter,
  fetchAdvStatusFilter,
  fetchApiFilter,
  fetchBrandFilter,
  fetchCategoryFilter,
} from '../../common/filtersFunc';
import {
  TableBox,
  Tabs,
  DropContainer,
  ButtonBasic,
  DropDownSelector,
  RangeBox,
} from '@lk-gtcom/ecomlab-components';
import { ModalCreatingAnAdvertisingCampaignV2 } from '../Modal/ModalCreatingAnAdvertisingCampaignV2/ModalCreatingAnAdvertisingCampaignV2';
import useGeneralStore from '../../store/general';
import './ListOfAdvertisingCampaigns.scss';

const options_prop = [
  { label: 'Ozon', value: 1 },
  { label: 'Wildberries', value: 3 },
  { label: 'Yandex', value: 2 },
];

const btn_tab = [
  {
    label: 'Все',
    value: 'all_product',
    icon: all_tab,
  },
  {
    label: 'Активны',
    value: 'active',
    icon: active_tab,
  },
  {
    label: 'Не активны',
    value: 'disactive',
    icon: disactive_tab,
  },
  {
    label: 'Завершена',
    value: '_',
    icon: disactive_tab,
  },
  {
    label: 'Архивирована',
    value: '_',
    icon: disactive_tab,
  },
  // {
  //   label: 'Запланированы',
  //   value: 'planned',
  //   icon: disactive_tab,
  // },
  // {
  //   label: 'Остановлены',
  //   value: 'stopped',
  //   icon: disactive_tab,
  // },
  // {
  //   label: 'Без рекламы',
  //   value: 'no_advertising',
  //   icon: disactive_tab,
  // },
  // {
  //   label: 'Участвуют',
  //   value: 'participate',
  //   icon: disactive_tab,
  // },
  // {
  //   label: 'Не участвуют',
  //   value: 'no_participate',
  //   icon: disactive_tab,
  // },
];

const ListOfAdvertisingCampaigns = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  // const [btn_tab, setTabs] = useState([])

  const [apiData, setApiData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [advCampaignTypeData, setAdvCampaignTypeData] = useState([]);
  const [advStatusData, setAdvStatusData] = useState([]);

  const [selectedMarketplace, setSelectedMarketplace] = useState([]);

  const [selectedApi, setSelectedApi] = useState([{ label: 'Все', value: 'all' }]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedAdvCampaignType, setSelectedAdvCampaignType] = useState([]);
  const [selectedAdvStatus, setSelectedAdvStatus] = useState([]);

  const [apiPage, setApiPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);
  const [advCampaignTypePage, setAdvCampaignTypePage] = useState(1);
  const [advStatusPage, setAdvStatusPage] = useState(1);

  const [apiQuery, setApiQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');
  const [advCampaignTypeQuery, setAdvCampaignTypeQuery] = useState('');
  const [advStatusQuery, setAdvStatusQuery] = useState('');
  const [showDropBox, setShowDropBox] = useState(false);

  const activeTab = useParams()['*'];
  const navigate = useNavigate();
  const [pageValue, setPageValue] = useState(btn_tab[0].value);
  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);
  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );

  const { setLoading, setFetchedData, setHeaders, setSort } = setTableFuncs;
  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);

  const [dateFrom, dateTo] = date;

  const [
    isModalCreatingAnAdvertisingCampaign,
    setIsModalCreatingAnAdvertisingCampaign,
  ] = useState(false);
  const [currentStrategy, setCurrentStrategy] = useState(null);
  const [currentStrategyName, setCurrentStrategyName] = useState(null);
  const [currentStrategyNameForPortal, setCurrentStrategyNameForPortal] =
    useState(null);
  const [checkedProducts, setCheckedProducts] = useState([]);
  const [isModalChoiceOfStrategy, setIsModalChoiceOfStrategy] = useState(false);
  const [total, setTotal] = useState(0);

  const fetchTableData = (params, abortController = new AbortController()) => {
    if (!pageValue) return;
    //    if (!selectedApi?.value || !selectedCategory.length || !selectedBrand.length) return

    setLoading(true);

    let sort, filtersParam;
    if (params) {
      [sort, filtersParam] = params;
    }

    // const url = `${RavshanHttps}/api/campaign/campaigns_list`;

    const url = `${OlegHttps}/api/v2/marketing/table/${'list_advertising_campaigns'}`;

    let api_id_list;
    let brand_id_list;
    let category_id_list;
    let adv_campaign_type_list;
    let adv_status_list;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi?.map((el) => el.value);
    }

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }
    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    if (selectedAdvCampaignType?.length > 0) {
      adv_campaign_type_list = selectedAdvCampaignType?.map((el) => el.value);
    } else {
      adv_campaign_type_list = ['all'];
    }

    if (selectedAdvStatus?.length > 0) {
      adv_status_list = selectedAdvStatus?.map((el) => el.value);
    } else {
      adv_status_list = ['all'];
    }

    let [date_from, date_to] = date;
    date_from = new Date(date_from + 10)?.toISOString().split('T')?.[0];
    date_to = new Date(date_to)?.toISOString().split('T')?.[0];

    const body = JSON.stringify({
      campaign_id_list: ['all'],
      offer_id_list: ['all'],
      filters: filtersParam,
      category_id_list,
      brand_id_list,
      api_id_list,
      date_from,
      date_to,
      state: btn_tab
        .filter((el) => el?.value === pageValue)
        .map((el) => el?.label)
        ?.join(''),
      page: paginatorRef.current.page,
      limit: paginatorRef.current.limit,
      order_param: sort?.[0],
      order_param_direction: sort?.[1],
      adv_campaign_type_list,
      adv_status_list,
    });

    fetch(url, {
      method: 'POST',
      headers,
      body,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('bad table response');
        }
      })
      .then((json) => {
        setTable(json);
        setTableUrl(url);
        setTableBody(body);
        setTotal(json?.total);
      })
      .catch((e) => console.error(e))
      .finally(() => {
        if (abortController.signal.aborted) return;
        setLoading(false);
      });
  };

  // const fetchTabs = (abortController = new AbortController()) => {
  //     setLoading(true)

  //     const url = `${RavshanHttps}/api/campaign/campaign_type`

  //     const body = JSON.stringify({
  //         mp_id: selectedMarketplace?.intValue
  //     })

  //     fetch(url, { method: 'POST', headers, body, signal: abortController.signal })
  //         .then(res => {
  //             if (res.ok) {
  //                 return res.json()
  //             } else {
  //                 throw new Error('bad table response')
  //             }
  //         })
  //         .then(json => {
  //             setTabs(json.map(el => ({label: el, value: el})))
  //         })
  //         .catch(e => console.error(e))
  //         .finally(() => {
  //             if(abortController.signal.aborted) return
  //             setLoading(false)
  //         })
  // }

  const resetTable = () => {
    paginatorRef.current.reset();
    paginatorRef.current.setTotal(0);
    setSort({});
    setHeaders([]);
    setFetchedData([]);
  };

  useEffect(() => {
    if (
      selectedApi?.length > 0 &&
      selectedBrand.length > 0 &&
      selectedCategory.length > 0
    ) {
      const abortController = new AbortController();
      fetchTableData([], abortController);
      return () => abortController.abort();
    } else resetTable();
  }, [
    selectedSpace,
    selectedApi,
    selectedBrand,
    selectedCategory,
    pageValue,
    selectedMarketplace,
    date,
    selectedAdvCampaignType,
    selectedAdvStatus,
  ]);

  // useEffect(() => {
  //     fetchTabs()
  // }, [selectedMarketplace])

  useEffect(() => {
    setPageValue(activeTab);
  }, [activeTab]);

  useEffect(() => {
    if (selectedMarketplace) {
      fetchApiFilter({
        selectedSpace: selectedSpace?.value,
        page: apiPage,
        setApiData: (e) => setApiData(e),
        searchParam: apiQuery,
        type: selectedMarketplace?.value,
        selectedShop: selectedMarketplace,
        selectedBrand,
      });
    }
  }, [selectedSpace, apiPage, apiQuery, selectedMarketplace, selectedBrand]);

  useEffect(() => {
    fetchCategoryFilter({
      selectedSpace: selectedSpace?.value,
      page: categoryPage,
      apiData,
      selectedApi,
      setCategoryData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
      selectedBrand,
      selectedShop: selectedMarketplace,
    });
  }, [
    selectedSpace,
    apiData,
    selectedApi,
    categoryPage,
    categoryQuery,
    selectedBrand,
    selectedMarketplace,
  ]);

  useEffect(() => {
    fetchBrandFilter({
      selectedSpace: selectedSpace?.value,
      page: brandPage,
      apiData,
      categoryData,
      selectedCategory,
      searchParam: brandQuery,
      selectedApi,
      setBrandData: (e) => setBrandData(e),
      selectedBrand,
      selectedShop: selectedMarketplace,
    });
  }, [
    selectedSpace,
    categoryData,
    selectedApi,
    selectedCategory,
    brandPage,
    brandQuery,
    selectedMarketplace,
  ]);

  useEffect(() => {
    fetchAdvCampaignTypeFilter({
      selectedSpace: selectedSpace?.value,
      page: advCampaignTypePage,
      setSuperStatusData: setAdvCampaignTypeData,
      searchParam: advCampaignTypeQuery,
      selectedCategory,
      selectedBrand,
      selectedShop: selectedMarketplace,
      apiData,
      selectedApi,
      dateFrom,
      dateTo,
    });
  }, [
    advCampaignTypePage,
    advCampaignTypeQuery,
    selectedApi,
    selectedMarketplace,
    selectedCategory,
    selectedBrand,
    selectedSpace,
  ]);

  useEffect(() => {
    fetchAdvStatusFilter({
      selectedSpace: selectedSpace?.value,
      page: advStatusPage,
      setSuperStatusData: setAdvStatusData,
      searchParam: advStatusQuery,
      selectedCategory,
      selectedBrand,
      selectedShop: selectedMarketplace,
      apiData,
      selectedApi,
      dateFrom,
      dateTo,
    });
  }, [
    advStatusPage,
    advStatusQuery,
    selectedApi,
    selectedMarketplace,
    selectedCategory,
    selectedBrand,
    selectedSpace,
  ]);

  const onRedirect = (e) => {
    const [dataObj, el, header] = e;
    const { ID, Магазин, api_id } = dataObj || {};
    const [name] = el;
    if (header === 'Название кампании') {
      navigate(
        `/marketing-place/products-in-campaign/participate_items?campaignName=${name}&campaignId=${ID}&apiName=${Магазин}&apiId=${api_id}`
      );
    }
  };

  const onActionHandler = (el) => {
    setCheckedProducts(el.ID);
    setIsModalCreatingAnAdvertisingCampaign(true);
  };

  return (
    <>
      {isModalCreatingAnAdvertisingCampaign && (
        <ModalCreatingAnAdvertisingCampaignV2
          isEdit={true}
          dataTable={checkedProducts}
          selectedApi={selectedApi}
          selectedMarketplace={selectedMarketplace}
          selectedBrand={selectedBrand}
          selectedCategory={selectedCategory}
          currentStrategy={currentStrategy}
          currentStrategyName={currentStrategyName}
          currentStrategyNameForPortal={currentStrategyNameForPortal}
          setIsModalChoiceOfStrategy={(e) => setIsModalChoiceOfStrategy(e)}
          isModalChoiceOfStrategy={isModalChoiceOfStrategy}
          setIsModal={(e) => setIsModalCreatingAnAdvertisingCampaign(e)}
          tableDate={date}
          setDataTable={(e) => setCheckedProducts(e)}
          tablePageValue={pageValue}
        />
      )}

      <div className="list-of-advertising-campaigns">
        <h1 className="title_main-page">Список рекламных кампаний</h1>

        <div className="filter-and-btn">
          <div className="filter-group">
            <FilterContainer
              onReset={(e) => {
                setSelectedMarketplace([]);
                setSelectedApi([]);
                setSelectedCategory([]);
                setSelectedBrand([]);
              }}
            >
              <DropDownSelector
                style={{ maxWidth: '210px' }}
                options_prop={options_prop}
                state={selectedMarketplace}
                setState={(e) =>
                  setSelectedMarketplace(Array.isArray(e) ? e : [e])
                }
                placeholder="Маркетплейс"
                defaultValue={[{ label: 'Все', value: 'all' }]}
                multi
              />

              <DropDownSelector
                style={{ maxWidth: '210px' }}
                options_prop={apiData}
                state={selectedApi}
                setState={(e) => setSelectedApi(e)}
                placeholder="Площадка"
                fetchCallback={(e) => setApiPage(e)}
                setInputChange={(e) => setApiQuery(e)}
                multi
              />
              <DropDownSelector
                style={{ maxWidth: '210px' }}
                options_prop={categoryData}
                state={selectedCategory}
                setState={(e) => setSelectedCategory(e)}
                placeholder="Категория"
                fetchCallback={(e) => setCategoryPage(e)}
                setInputChange={(e) => setCategoryQuery(e)}
                defaultValue={[{ label: 'Все', value: 'all' }]}
                multi
              />
              <DropDownSelector
                style={{ maxWidth: '210px' }}
                options_prop={brandData}
                state={selectedBrand}
                setState={(e) => setSelectedBrand(e)}
                placeholder="Бренд"
                fetchCallback={(e) => setBrandPage(e)}
                setInputChange={(e) => setBrandQuery(e)}
                defaultValue={[{ label: 'Все', value: 'all' }]}
                multi
              />
              <DropDownSelector
                options_prop={advCampaignTypeData}
                state={selectedAdvCampaignType}
                setState={(e) => setSelectedAdvCampaignType(e)}
                className="connections_page_selector"
                placeholder="По типу рекламной кампании"
                fetchCallback={(e) => setAdvCampaignTypePage(e)}
                setInputChange={(e) => setAdvCampaignTypeQuery(e)}
                defaultValue={[{ label: 'Все', value: 'all' }]}
                multi
              />
              <DropDownSelector
                options_prop={advStatusData}
                state={selectedAdvStatus}
                setState={(e) => setSelectedAdvStatus(e)}
                className="connections_page_selector"
                placeholder="По статусу рекламной кампании"
                fetchCallback={(e) => setAdvStatusPage(e)}
                setInputChange={(e) => setAdvStatusQuery(e)}
                defaultValue={[{ label: 'Все', value: 'all' }]}
                multi
              />
            </FilterContainer>
          </div>
          <RangeBox setDate={(e) => setDate(e)} btn_period={false} />
        </div>

        {/* <Tabs tabs={btn_tab} pageValue={pageValue} /> */}

        <DropContainer
          showDropBox={showDropBox}
          setShowDropBox={(e) => setShowDropBox(e)}
        >
          <LineChart
            datasets_prop={[]}
            labels_prop={[]}
            data_name="Название графика"
            loading={null}
            scaleLabel={['abc', 'cba']}
          />
        </DropContainer>

        <TableBox
          {...tableProps}
          onRedirectInfo={true}
          paginator={true}
          ref={paginatorRef}
          onRedirect={onRedirect}
          fetchCallback={fetchTableData}
          tableUrl={tableUrl}
          tableBody={tableBody}
          onAction={(e) => onActionHandler(e)}
          statistic={[
            {
              label: 'Всего кампаний',
              sum: total,
            },
          ]}
          child={
            <>
              <ButtonBasic
                green
                text="Создать рекламную кампанию"
                width="232px"
                size="32px"
                onClick={(e) =>
                  navigate(
                    '/marketing-place/analytical-collections/all_product'
                  )
                }
              />
              <ButtonBasic
                green
                text="Cоздать кампанию из правил"
                width="232px"
                size="32px"
                onClick={(e) => {}}
              />
            </>
          }
        />
      </div>
    </>
  );
};

export { ListOfAdvertisingCampaigns };
