import React, { useState, useEffect, useRef } from 'react';
import './ModalMiltilevelSidebarSettings.scss';
import customHeaders, { getSpace } from '../../../common/headers';
import { RavshanHttps } from '../../../fetchUrls';
import useGeneralStore from '../../../store/general';
import { useNavigate, useParams } from 'react-router-dom';
import { DropDownSelector, ButtonBasic } from '@lk-gtcom/ecomlab-components';
import drop_icon from './img/drop_icon.svg';
import { minWidth, width } from '@mui/system';
import trash_icon from './img/bx-trash.svg';
import settings_icon from './img/bx-cog.svg';
import { Button } from '@mui/material';
import { ButtonActionDrop } from '../../Buttons/ButtonActionDrop/ButtonActionDrop';


const MenuItem = ({
  ind,
  item,
  onDragStart,
  onDragOver,
  onDrop,
  onDropBetween,
  toggleExpand,
  handleAddChild,
  handleSelect,
  handleDelete,
  selectedItemId,
  fetchChildren,
  setIsModalSettings,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredTop, setIsHoveredTop] = useState(false);
  const [isHoveredForDrop, setIsHoveredForDrop] = useState(false);

  //эффект делает expanded false когда не становится детей у элемента
  useEffect(() => {
    if (item.children.length === 0 && item.expanded) {
      toggleExpand(item.id, false);
    }
  }, [item.children, item.expanded, toggleExpand, item.id]);


  const btns = [

    { btn: 'Редактировать', func: () => { setIsModalSettings(true) } },
    { btn: 'Удалить', func: () => { handleDelete(item.id)} },
  ];


  return (
    <>
      <div
        onDragOver={(e) => onDragOver(e)}
        onDrop={(e) => onDropBetween(e, item.id, 'above')}
      />

      {/* <div
        className="hover-block"
        style={{
          height: '4px',
          backgroundColor: isHoveredTop ? '#5282ff' : 'transparent',
        }}
        onDragEnter={() => setIsHoveredTop(true)}
        onDragLeave={() => setIsHoveredTop(false)}
        onDragOver={(e) => {
          onDragOver(e);
          e.dataTransfer.dropEffect = 'move';
        }}
        onDrop={(e) => {

          onDropBetween(e, item.before, 'above');
          setIsHoveredTop(false);
        }}
      /> */}

      <div
        className={`menu-item `}
        draggable
        onDragStart={(e) => {
          onDragStart(e, item.id);
        }}
        onDragOver={(e) => {
          onDragOver(e);
          e.dataTransfer.dropEffect = 'move';
          // setIsHoveredForDrop(true);
        }}
        onDrop={(e) => {
          onDrop(e, item.id);
          setIsHoveredForDrop(false);
        }}
        onDragLeave={() => setIsHoveredForDrop(false)}
        onClick={(e) => {
          e.stopPropagation();
          handleSelect(item.id);
        }}
      >
        <div
          className={`item-label ${isHoveredForDrop ? 'hovered-for-drop' : ''}`}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            color: selectedItemId === item.id ? '#00B45E' : '',
          }}
          onDragEnter={(e) => {
            e.preventDefault();
            setIsHoveredForDrop(true);
          }}
        >
          <div
            className="hover-block"
            style={{
              height: '4px',
              backgroundColor: isHoveredTop ? '#5282ff' : '',
              top: 0,
            }}
            onDragEnter={() => setIsHoveredTop(true)}
            onDragLeave={() => setIsHoveredTop(false)}
            onDragOver={(e) => {
              onDragOver(e);
              e.dataTransfer.dropEffect = 'move';
            }}
            onDrop={(e) => {
              onDropBetween(e, item.before, 'above');
              setIsHoveredTop(false);
            }}
          />

          <div className="item-label__box">
            <div className="menu-item__arrow-box">
              {(item.has_child || item.children.length > 0) && (
                <img
                  src={drop_icon}
                  className={`arrow ${item.expanded ? 'open' : ''}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (item.expanded) {
                      toggleExpand(item.id, false);
                    } else {
                      fetchChildren(item.id);
                      toggleExpand(item.id, true);
                    }
                  }}
                />
              )}
            </div>
            {item.label}

            <div className="menu-item__group-btn">
              {/* <button
                className="delete-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(item.id);
                }}
              >
                <img src={trash_icon} />
              </button>
              <button
                className="delete-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsModalSettings(true);
                }}
              >
                <img src={settings_icon} />
              </button> */}

              <ButtonActionDrop id={item.id} btns={btns} size={'s'} />
            </div>
          </div>

          {!item.expanded && (
            <div
              className="hover-block"
              style={{
                borderTop: isHovered ? '4px solid #5282ff' : 'transparent',
                height: '4px',
                backgroundColor: isHovered ? '#5282ff' : '',
              }}
              onDragEnter={() => setIsHovered(true)}
              onDragLeave={() => setIsHovered(false)}
              onDragOver={(e) => {
                onDragOver(e);
                e.dataTransfer.dropEffect = 'move';
              }}
              onDrop={(e) => {
                onDropBetween(e, item.id, 'below');
                setIsHovered(false);
              }}
            />
          )}
        </div>

        {item.expanded && item.children.length > 0 && (
          <div className="children">
            {item.children.map((child, ind) => (
              <MenuItem
                ind={ind}
                key={child.id}
                item={child}
                onDragStart={onDragStart}
                onDragOver={onDragOver}
                onDrop={onDrop}
                onDropBetween={onDropBetween}
                toggleExpand={toggleExpand}
                handleAddChild={handleAddChild}
                handleDelete={handleDelete}
                handleSelect={handleSelect}
                selectedItemId={selectedItemId}
                fetchChildren={fetchChildren}
                setIsModalSettings={setIsModalSettings}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

const ModalMiltilevelSidebarSettings = ({
  setTitle,
  setPathsWebName,
  onPortalClick,
  setPaths,
  selectedItemId,
  setSelectedItemId,
}) => {
  const staticMenu = [
    {
      id: 1,
      label: 'Главная страница',
      expanded: true,
      children: [
        {
          id: 2,
          label: 'Подстраница 1',
          expanded: false,
          children: [],
        },
        {
          id: 3,
          label: 'Подстраница 2',
          expanded: false,
          children: [],
        },
      ],
    },
    {
      id: 4,
      label: 'Контакты',
      expanded: false,
      children: [],
    },
    {
      id: 5,
      label: 'О нас',
      expanded: false,
      children: [],
    },
  ];

  const [menu, setMenu] = useState([]);
  const [draggedItemId, setDraggedItemId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalSettings, setIsModalSettings] = useState(false);
  const [newItemLabel, setNewItemLabel] = useState('');
  // const [selectedItemId, setSelectedItemId] = useState(null);
  const [isLoadingChildren, setIsLoadingChildren] = useState(false);
  const wiki_name = localStorage.getItem('wiki_name');
  const wiki_id = localStorage.getItem('wiki_id');
  const path = useParams()['*'];

  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };

  const [selectedLang, setSelectedLang] = useState('ru');
  const [isLanguage, setIsLanguage] = useState(false);

  const menuRef = useRef(null);

  const getExpandedFromStorage = () => {
    const expandedItems = localStorage.getItem('expandedItems');
    return expandedItems ? JSON.parse(expandedItems) : [];
  };

  const saveExpandedToStorage = (expandedItems) => {
    localStorage.setItem('expandedItems', JSON.stringify(expandedItems));
  };

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (menuRef.current && !menuRef.current.contains(event.target)) {
  //       setSelectedItemId(null);
  //     }
  //   };

  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [menuRef]);

  const findItemById = (items, id) => {
    for (let item of items) {
      if (item.id === id) return item;
      if (item.children) {
        const found = findItemById(item.children, id);
        if (found) return found;
      }
    }
  };

  const findAndRemoveItem = (items, id) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].id === id) return items.splice(i, 1)[0];
      if (items[i].children) {
        const found = findAndRemoveItem(items[i].children, id);
        if (found) return found;
      }
    }
  };

  const addItemToParent = (items, targetId, item) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].id === targetId) {
        if (!items[i].children) items[i].children = [];
        items[i].children.push(item);
        items[i].expanded = true;
        return;
      }
      if (items[i].children) {
        addItemToParent(items[i].children, targetId, item);
      }
    }
  };

  const isChildOf = (childId, parentId, items) => {
    for (let item of items) {
      if (item.id === parentId) {
        return item.children.some((child) => child.id === childId);
      }
      if (item.children) {
        if (isChildOf(childId, parentId, item.children)) return true;
      }
    }
    return false;
  };

  const addItemAsSibling = (items, targetId, item, position) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].id === targetId) {
        const index = i;
        if (position === 'above') {
          items.splice(index, 0, item);
        } else if (position === 'below') {
          items.splice(index + 1, 0, item);
        }
        return;
      }
      if (items[i].children) {
        addItemAsSibling(items[i].children, targetId, item, position);
      }
    }
  };

  const fetchArticlesByLevel = (parent = null) => {
    setIsLoadingChildren(true);
    const url = `${RavshanHttps}/api/v2/article/levels/${wiki_id}`;
    const parentsHeader = parent ? { 'parent-id': parent } : '';
    const getHeaders = { ...headers, ...parentsHeader };

    fetch(url, { headers: getHeaders })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        const expandedItems = getExpandedFromStorage();
        const transformedData = json.map(
          ({ id, name, has_child, subItems, before }) => ({
            id,
            before,
            label: name,
            has_child,
            before,
            children:
              subItems && subItems.length > 0
                ? subItems.map((subItem) => ({
                    id: subItem.id,
                    label: subItem.name,
                    children: [],
                    has_child: subItem.has_child,
                    before: subItem.before,
                    parent_id: id,
                  }))
                : [],
            expanded: expandedItems.includes(id),
          })
        );

        transformedData.forEach((item) => {
          if (item.expanded) {
            fetchChildren(item.id);
          }
        });

        if (parent) {
          setMenu((prevMenu) => {
            const newMenu = [...prevMenu];
            const parentItem = findItemById(newMenu, parent);
            if (parentItem) {
              parentItem.children = transformedData;
              parentItem.expanded = true;
            }
            return newMenu;
          });
        } else {
          setMenu(transformedData);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setIsLoadingChildren(false);
      });
  };

  const fetchChildren = (parentId) => {
    fetchArticlesByLevel(parentId);
  };

  useEffect(() => {
    fetchArticlesByLevel();
  }, []);


  const getLabelFromUrl = () => {
    const label = window.location.pathname.split('/').at(-2);
    return label;
  };

  let actualPath = getLabelFromUrl();

  useEffect(() => {
    setPaths([actualPath.replace(/%20/g, ' ')]);
  }, [actualPath]);

  const navigate = useNavigate();

  const handleSelect = (itemId) => {
    const item = findItemById(menu, itemId);
    const label = item.label;
    const newUrl = `/wiki/${label}/`;
    navigate(newUrl);
    // window.history.pushState({}, '', newUrl);
    setSelectedItemId(itemId);
    setTitle(label);
    onPortalClick(itemId);
    // if (typeof setPaths === 'function') {
    //   setPaths([actualPath]);
    // }
    setPathsWebName(label);
  };

  const toggleExpand = (id, expanded) => {
    const newMenu = [...menu];
    const expandedItems = getExpandedFromStorage();

    if (expanded) {
      if (!expandedItems.includes(id)) {
        expandedItems.push(id);
      }
    } else {
      const index = expandedItems.indexOf(id);
      if (index > -1) {
        expandedItems.splice(index, 1);
      }
    }

    saveExpandedToStorage(expandedItems);

    toggleExpandItem(newMenu, id, expanded);
    setMenu(newMenu);
  };

  const toggleExpandItem = (items, id, expanded) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].id === id) {
        items[i].expanded = expanded;
        return;
      }
      if (items[i].children) {
        toggleExpandItem(items[i].children, id, expanded);
      }
    }
  };

  const openModalToAdd = () => {
    setIsModalOpen(true);
    setNewItemLabel('');
  };

  const addNewItem = () => {
    if (!newItemLabel) return;

    const parentId = selectedItemId || null;
    fetchAddLevel(newItemLabel, parentId, null)
      .then((newItem) => {
        const newMenu = [...menu];
        const newMenuItem = {
          id: newItem.id,
          label: newItemLabel,
          children: [],
        };

        if (parentId) {
          addItemToParent(newMenu, parentId, newMenuItem);
        } else {
          newMenu.unshift(newMenuItem);
        }

        setMenu(newMenu);
        setIsModalOpen(false);
        setSelectedItemId(newMenuItem.id);
        fetchArticlesByLevel();
      })
      .catch((err) => console.error(err));
  };

  const fetchAddLevel = (name, parent_id, place) => {
    const url = `${RavshanHttps}/api/v2/article/level`;
    const body = JSON.stringify({
      name,
      parent_id,
      before: place,
      wiki_list_id: wiki_id,
    });

    return fetch(url, { body, method: 'POST', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.json());
        }
      })
      .then(fetchArticlesByLevel())
      .catch((err) => console.error(err));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragStart = (e, id) => {
    setDraggedItemId(id);
    e.stopPropagation();
  };

  const handleDrop = (e, targetId) => {
    e.preventDefault();
    e.stopPropagation();

    if (draggedItemId === targetId) return;

    const newMenu = [...menu];

    if (isChildOf(targetId, draggedItemId, newMenu)) {
      return;
    }

    const draggedItem = findAndRemoveItem(newMenu, draggedItemId);

    if (!draggedItem) return;

    addItemToParent(newMenu, targetId, draggedItem);

    setMenu(newMenu);
    fetchChangeLevel(draggedItem.label, targetId, null, draggedItem.id);
  };

  // const handleDropBetween = (e, targetId, position) => {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   if (draggedItemId === targetId) return;

  //   const newMenu = [...menu];

  //   if (isChildOf(targetId, draggedItemId, newMenu)) {
  //     return;
  //   }

  //   const draggedItem = findAndRemoveItem(newMenu, draggedItemId);

  //   if (!draggedItem) return;

  //   let newParentId = null;
  //   let before = targetId;

  //   if (isChildOf(draggedItemId, targetId, newMenu)) {
  //     addItemAsSibling(newMenu, targetId, draggedItem, position);
  //     newParentId = findItemParent(newMenu, targetId)?.id || null;
  //   } else {
  //     addItemAsSibling(newMenu, targetId, draggedItem, position);
  //   }

  //   setMenu(newMenu);
  //   fetchChangeLevel(draggedItem.label, newParentId, before, draggedItem.id);
  // };

  const handleDropBetween = (e, targetId, position) => {
    e.preventDefault();
    e.stopPropagation();

    if (draggedItemId === targetId) return;

    const newMenu = [...menu];

    if (isChildOf(targetId, draggedItemId, newMenu)) {
      return;
    }

    const draggedItem = findAndRemoveItem(newMenu, draggedItemId);

    if (!draggedItem) return;

    let newParentId = null;
    let before = null;

    const targetParent = findItemParent(newMenu, targetId);

    if (position === 'above') {
      newParentId = targetParent?.id || null;
      before = targetId;
      addItemAsSibling(newMenu, targetId, draggedItem, 'above');
    } else if (position === 'below') {
      if (targetParent) {
        newParentId = targetParent.id;
      } else {
        newParentId = targetId;
      }
      before = null;

      addItemToParent(newMenu, targetId, draggedItem);
    }

    fetchChangeLevel(draggedItem.label, newParentId, before, draggedItem.id);

    setMenu(newMenu);
  };

  const findItemParent = (items, id) => {
    for (let item of items) {
      if (item.children.some((child) => child.id === id)) return item;
      if (item.children) {
        const found = findItemParent(item.children, id);
        if (found) return found;
      }
    }
  };

  const fetchChangeLevel = (name, parent_id, place, wikiID) => {
    const url = `${RavshanHttps}/api/v2/article/level`;
    const body = JSON.stringify({
      name,
      parent_id,
      before: place,
      wiki_list_id: wiki_id,
      wiki_id: wikiID,
    });

    fetch(url, { body, method: 'PUT', headers })
      .then((res) => {
        if (res.ok) {
          fetchArticlesByLevel();
          return res.json();
        } else {
          throw new Error(res.json());
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchDeleteLevel = (level_id) => {
    const url = `${RavshanHttps}/api/v2/article/level?wiki_list_id=${wiki_id}`;

    const _headers = {
      ...headers,
      'level-id': level_id,
    };

    fetch(url, { method: 'DELETE', headers: _headers })
      .then((res) => {
        if (res.ok) {
          if (res.ok) {
            setMenu((prevState) => {
              const updatedState = removeItemFromMenu(prevState, level_id);
              return updatedState;
            });
            fetchArticlesByLevel();
            setSelectedItemId(null);
          } else {
            return res.json();
          }
        } else {
          throw new Error(res.json());
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const removeItemFromMenu = (items, id) => {
    return items.reduce((acc, item) => {
      if (item.id === id) return acc;
      if (item.children) {
        item.children = removeItemFromMenu(item.children, id);
      }
      acc.push(item);
      return acc;
    }, []);
  };

  const options_prop = [
    { label: 'Ru', value: 1 },
    { label: 'Eng', value: 3 },
    { label: 'Lt', value: 2 },
  ];

  useEffect(() => {
    if (selectedLang) {
      setIsLanguage(true);
    } else {
      setIsLanguage(false);
    }
  }, [selectedLang]);

  return (
    <div className="modal-miltilevel-sidebar-settings" ref={menuRef}
    // style={{ marginTop: (path?.includes('/article/') || path?.includes('cpu/new')) ? '70px' : '' }}
    >
      <div className="modal-miltilevel-sidebar-settings__select-container">
        <ButtonBasic
          blue
          text="Добавить новый пункт"
          width="100%"
          onClick={openModalToAdd}
        />

        {/* <DropDownSelector
          options_prop={options_prop}
          state={selectedLang}
          setState={setSelectedLang}
          placeholder="Выберите язык"
          width="100%"
          defaultValue={options_prop[0]}
          style={{ minWidth: '100px' }}
        /> */}
      </div>

      {isLanguage && (
        <>
          <div className="menu">
            {menu?.map((item) => (
              <MenuItem
                key={item.id}
                item={item}
                onDragStart={handleDragStart}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onDropBetween={handleDropBetween}
                toggleExpand={toggleExpand}
                handleAddChild={openModalToAdd}
                handleDelete={fetchDeleteLevel}
                handleSelect={handleSelect}
                selectedItemId={selectedItemId}
                fetchChildren={fetchChildren}
                setIsModalSettings={setIsModalSettings}
              />
            ))}
          </div>
        </>
      )}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h3>Добавить подстраницу</h3>
            <input
              type="text"
              value={newItemLabel}
              onChange={(e) => setNewItemLabel(e.target.value)}
              placeholder="Введите название"
            />
            <button
              onClick={(e) => {
                addNewItem();
              }}
            >
              Добавить
            </button>
            <button onClick={() => setIsModalOpen(false)}>Отмена</button>
          </div>
        </div>
      )}
      {isModalSettings && (
        <div className="modal-overlay">
          <div
            className="modal"
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <h3>Настройка:</h3>
            <input
              type="text"
              // value={newItemLabel}
              onChange={(e) => setNewItemLabel(e.target.value)}
              placeholder="Тайтл"
            />
            <input
              type="text"
              // value={newItemLabel}
              onChange={(e) => setNewItemLabel(e.target.value)}
              placeholder="Дискрипшн"
            />
            <input
              type="text"
              // value={newItemLabel}
              onChange={(e) => setNewItemLabel(e.target.value)}
              placeholder="Веб нейм"
            />
            <input
              type="text"
              // value={newItemLabel}
              onChange={(e) => setNewItemLabel(e.target.value)}
              placeholder="Имя вкладки"
            />
            <div>
              <button
                onClick={(e) => {
                  // isEdit ?
                  //   fetchChangeLevel()
                  // :
                  //   addNewItem()
                }}
              >
                Добавить
              </button>
              <button onClick={() => setIsModalSettings(false)}>Отмена</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { ModalMiltilevelSidebarSettings };
