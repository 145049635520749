import React, { useEffect, useState } from 'react';
import trash_icon from './img/trash_icon.svg';
import settings_icon from './img/settings.svg';
import './CrmModal.scss';
import { DenisCrmHttps } from '../../fetchUrls';
import customHeaders, { getSpace } from '../../common/headers';
import {
  DropDownSelector,
  InputDinamycPlaceholder,
  ButtonBasic,
  Checkbox,
} from '@lk-gtcom/ecomlab-components';
import { TooltipLightBlue } from '../Tooltip/TooltipLightBlue/TooltipLightBlue';
import { CrmCommonAttrSubModal } from './CrmCommonAttrSubModal';
import { CrmUncommonAttrSubModal } from './CrmUncommonAttrSubModal';
import { CrmAttrControlModal } from './CrmAttrControlModal';
import close_icon from './img/close_icon.svg';
import useGeneralStore from '../../store/general';
import { ButtonCopyCrm } from './ButtonCopyCrm';
import { display } from '@mui/system';

export const CrmModal = ({
  isOpen,
  close,
  fetchTableData,
  tableId,
  setTableId,
}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [title, setTitle] = useState('');
  const [selectedTableType, setSelectedTableType] = useState('');
  const [commonAttributes, setCommonAttributes] = useState([]);
  const [tableTypes, setTableTypes] = useState([]);
  const [isCommonAttrSubModalOpen, setIsCommonAttrSubModalOpen] =
    useState(false);
  const [isUncommonAttrSubModalOpen, setIsUncommonAttrSubModalOpen] =
    useState(false);
  const [uncommonAttrTypes, setUncommonAttrTypes] = useState([]);
  const [isAttrControlModalOpen, setIsAttrControlModalOpen] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [currentAttr, setCurrentAtt] = useState(null);
  const [valuesAtr, setValuesAtr] = useState([]);
  const [checkedAttr1, setCheckedAttr1] = useState([]);
  const [checkedAttr2, setCheckedAttr2] = useState([]);

  const [currentIndex, setCurrentIndex] = useState(null);
  const [objValuesAttr, setObjValuesAttr] = useState({});

  const [newColName, setNewColName] = useState(null);
  const [oldColName, setOldColName] = useState('');
  const [currentDictName, setCurrentDictName] = useState('');

  //Получение имени таблицы
  const onGetCustomTableName = (table_uid) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${DenisCrmHttps}/api/custom_table/get_custom_table_name`;
    } else {
      url = `${DenisCrmHttps}/api/custom_table/get_custom_table_name`;
    }

    const body = JSON.stringify({
      table_uid,
    });

    fetch(url, { method: 'POST', headers, body })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('ERROR: get_tables_attr request');
        }
      })
      .then((json) => {
        const { table_name } = json;
        setTitle(table_name);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  //Изменения имени таблицы
  const onChangeCustomTableName = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${DenisCrmHttps}/api/custom_table/change_custom_table_name`;
    } else {
      url = `${DenisCrmHttps}/api/custom_table/change_custom_table_name`;
    }

    const body = JSON.stringify({
      table_uid: tableId,
      name: title,
      fields: selectedAttributes.map((item, index) => ({
        field_name: item[1],
        field_type: item[2],
        order_num: index + 1,
      })),
    });

    fetch(url, { method: 'PUT', headers, body })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('ERROR: get_tables_attr request');
        }
      })
      .then((json) => {
        // const { table_name } = json;
        setTitle(json?.table_name);
        fetchTableData();
        // setTableId(null);
        // close();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  //Получение филдов таблицы
  const onGetField = (table_uid) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${DenisCrmHttps}/api/custom_table/get_custom_table_list_field`;
    } else {
      url = `${DenisCrmHttps}/api/custom_table/get_custom_table_list_field`;
    }

    const body = JSON.stringify({
      table_uid,
    });

    fetch(url, { method: 'POST', headers, body })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('ERROR: get_tables_attr request');
        }
      })
      .then((json) => {
        const { data } = json;
        setSelectedAttributes(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  //Получение даты таблицы
  const onGetData = (table_uid) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${DenisCrmHttps}/api/custom_table/get_custom_table_data`;
    } else {
      url = `${DenisCrmHttps}/api/custom_table/get_custom_table_data`;
    }

    const body = JSON.stringify({
      table_uid,
    });

    fetch(url, { method: 'POST', headers, body })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('ERROR: get_tables_attr request');
        }
      })
      .then((json) => {
        // setAttributes(json);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchCommonAttributes = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${DenisCrmHttps}/api/crm/get_common_attr`;
    } else {
      url = `${DenisCrmHttps}/api/crm/get_common_attr`;
    }

    fetch(url, { method: 'GET', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.json());
        }
      })
      .then((json) => {
        setCommonAttributes(json);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Получение типов аттрибутов
  const fetchUncommonAttributesTypes = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${DenisCrmHttps}/api/custom_table/get_custom_table_fields_type`;
    } else {
      url = `${DenisCrmHttps}/api/custom_table/get_custom_table_fields_type`;
    }

    fetch(url, { method: 'GET', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.json());
        }
      })
      .then((json) => {
        setUncommonAttrTypes(json);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  //Получение типов таблицы
  // const fetchTableTypes = () => {
  //   let url;
  //   if (!window.location.hostname.match('localhost')) {
  //     url = `${DenisCrmHttps}/api/custom_table/get_custom_table_fields_type`;
  //   } else {
  //     url = `${DenisCrmHttps}/api/custom_table/get_custom_table_fields_type`;
  //   }

  //   fetch(url, { method: 'GET', headers })
  //     .then((res) => {
  //       if (res.ok) {
  //         return res.json();
  //       } else {
  //         throw new Error(res.json());
  //       }
  //     })
  //     .then((json) => {
  //       console.log(json, 'json');
  //       setUncommonAttrTypes(json);
  //       // setTableTypes(json?.map((el) => ({ label: el?.title, value: el?.id })));
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };

  //Создание таблицы
  const fetchCreateTable = (common, attr_new) => {
    // if (title && selectedTableType?.value) {
    if (title) {
      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${DenisCrmHttps}/api/custom_table/create_custom_table  `;
      } else {
        url = `${DenisCrmHttps}/api/custom_table/create_custom_table  `;
      }

      const body = JSON.stringify({
        name: title,
      });

      fetch(url, { body, method: 'POST', headers })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw new Error(res.json());
          }
        })
        .then((json) => {
          const extractUid = (message) => {
            const regex = /uid\s*=\s*(\d+)/;
            const match = message.match(regex);
            if (match && match[1]) {
              return match[1];
            } else {
              return null;
            }
          };
          let uId = extractUid(json);
          setTableId(uId);
          // setTableId(json);
          fetchTableData();
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  //Создание колонки в customTable
  const fetchCreateColumn = (attribute, order_num) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${DenisCrmHttps}/api/custom_table/add_custom_table_field`;
    } else {
      url = `${DenisCrmHttps}/api/custom_table/add_custom_table_field`;
    }

    const body = JSON.stringify({
      table_uid: tableId,
      field_name: attribute.title,
      field_type: attribute.type_name,
      order_num: order_num,
    });

    fetch(url, { body, method: 'POST', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.json());
        }
      })
      .then((json) => {
        console.log(json);
        onGetField(tableId);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  console.log(newColName, 'test');

  //Изменение колонки в customTable
  const fetchChangeColumn = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${DenisCrmHttps}/api/custom_table/change_custom_table_field`;
    } else {
      url = `${DenisCrmHttps}/api/custom_table/change_custom_table_field`;
    }

    const body = JSON.stringify({
      table_uid: tableId,
      field_name: newColName?.newName,
      field_type: newColName?.fieldType,
      order_num: newColName?.id,
      old_field_name: oldColName,
      old_field_type: newColName?.fieldType,
    });

    fetch(url, { body, method: 'PUT', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.json());
        }
      })
      .then((json) => {
        console.log(json);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  //Удаление колонки в customTable
  const fetchDeleteColumn = (field_name) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${DenisCrmHttps}/api/custom_table/delete_custom_table_field`;
    } else {
      url = `${DenisCrmHttps}/api/custom_table/delete_custom_table_field`;
    }

    const body = JSON.stringify({
      table_uid: tableId,
      field_name: field_name,
    });

    fetch(url, { body, method: 'DELETE', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.json());
        }
      })
      .then((json) => {
        console.log(json);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleInputChange = (e, idx, type, fieldType) => {
    console.log(e, 'event');

    setNewColName({
      id: idx,
      newName: e,
      isDict: type,
      fieldType: fieldType,
    });
    setOldColName(selectedAttributes[idx][1]);
  };

  const handleClose = () => {
    close();
    setTableId(null);
    setSelectedAttributes([]);
    setTitle('');
    setShowGeneratedModal(false);
  };

  const handleDeleteSelectedAttr = (idx) => {
    setSelectedAttributes((it) => it.filter((_, i) => i !== idx));
  };

  const onChangeId = (e, idx) => {
    let attr_values;
    if (objValuesAttr[idx]) {
      attr_values = objValuesAttr[idx]?.attr_values;
    }
    const obj_values = {
      ...objValuesAttr,
      [idx]: {
        title: e,
        attr_values: attr_values,
      },
    };
    setObjValuesAttr(obj_values);
  };

  const onHandlerAttr = () => {
    const arr_common = [];
    const your_common = [];
    selectedAttributes.map((el, ind) => {
      const el_obj = Object.assign(el);

      if (el.common === true) {
        arr_common.push({
          title: objValuesAttr[ind]?.title,
          required: false,
          common_id: el.id,
          // id: undefined,
          // datatype: undefined,
          // common: undefined,
          // type_id: undefined,
          // type_name: undefined,
          is_dict: el?.is_dict ? el?.is_dict : false,
          attr_values: objValuesAttr[ind]?.attr_values,
        });
      }

      if (el.common === false) {
        your_common.push({
          title: objValuesAttr[ind]?.title,
          required: false,
          datatype: el.id,
          // id: undefined,
          is_dict: el?.is_dict ? el?.is_dict : false,
          // common: undefined,
          attr_values: objValuesAttr[ind]?.attr_values,
        });
      }
    });

    fetchCreateTable(
      arr_common.length > 0 ? arr_common : null,
      your_common.length > 0 ? your_common : null
    );
  };

  const deleteTitle = (idx) => {
    const delete_obj = objValuesAttr;
    delete_obj[idx] = false;
    const new_obj = {};
    let count = 0;
    for (let key in delete_obj) {
      if (delete_obj[key]) {
        new_obj[new String(count)] = delete_obj[key];
        count++;
      }
    }
    setObjValuesAttr(new_obj);
  };

  useEffect(() => {
    if (tableId) {
      onGetField(tableId);
      onGetData(tableId);
      onGetCustomTableName(tableId);
    }
  }, [tableId]);

  useEffect(() => {
    if (selectedAttributes && selectedAttributes.length > 0) {
      selectedAttributes.forEach((attribute, index) => {
        fetchCreateColumn(attribute, index + 1);
      });
    }
  }, [selectedAttributes]);

  useEffect(() => {
    if (!isAttrControlModalOpen) {
      onGetField(tableId);
    }
  }, [isAttrControlModalOpen]);

  useEffect(() => {
    // fetchTableTypes();
    fetchCommonAttributes();
    fetchUncommonAttributesTypes();
  }, []);

  const [showGeneratedModal, setShowGeneratedModal] = useState(false);
  const [copiedFields, setCopiedFields] = useState([]);

  const handleCheckboxChange = (fieldId) => {
    setCopiedFields((prev) =>
      prev.includes(fieldId)
        ? prev.filter((id) => id !== fieldId)
        : [...prev, fieldId]
    );
  };

  const generateRequestBody = () => {
    return {
      table_uid: tableId,
      fields_data: copiedFields.map((field) => ({
        name: field[1],
        field_uid: field[3],
      })),
    };
  };

  if (!isOpen) return null;

  return (
    <div className="modal-crm">
      <div
        className="modal-crm__main-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-crm__header">
          <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
            <h1 className="modal-crm__title">
              {!tableId ? 'Создание новой таблицы' : 'Редактирование таблицы'}
            </h1>

            {tableId && (
              <ButtonBasic
                width={'100px'}
                className="modal-crm__btn-generate"
                onClick={() => setShowGeneratedModal(!showGeneratedModal)}
                text={'Сформировать'}
              />
            )}
            {/* {showBtn && (
              <div className="val-box">
                <ButtonCopyCrm value={tableId} right={true} />
              </div>
            )} */}
          </div>
          <button className="modal-crm__btn-close" onClick={handleClose}>
            <img src={close_icon} />
          </button>
        </div>

        <div className="modal-crm__input-group-main">
          <InputDinamycPlaceholder
            changeValue={title}
            onChange={(e) => setTitle(e)}
            type="text"
            classname="table_title_input"
            placeholder="Название таблицы"
            autoComplete={false}
            size="s"
          />
        </div>

        <div className="modal-crm__btn-group-violet">
          {tableId && (
            <>
              {/* <button
                className="modal-crm__btn-violet"
                onClick={() => setIsCommonAttrSubModalOpen(true)}
              >
                Выбрать поля
              </button> */}

              <button
                className="modal-crm__btn-violet"
                onClick={() => setIsUncommonAttrSubModalOpen(true)}
              >
                Добавить поле
              </button>
            </>
          )}
        </div>

        <div className="modal-crm__content">
          {selectedAttributes &&
            selectedAttributes.map((attr, idx) => {
              if (attr?.attr_values || attr?.title == 'single_choice') {
                return (
                  <div key={attr.id} className="modal-crm__item-parametr">
                    <DropDownSelector
                      options_prop={
                        attr?.attr_values
                          ? attr?.attr_values?.map(({ id, value }) => {
                              return { label: value, value: id };
                            })
                          : []
                      }
                      state={[]}
                      setState={(e) => e}
                      placeholder={attr.title}
                    />

                    <InputDinamycPlaceholder
                      size="s"
                      key={attr.id}
                      type="text"
                      onChange={(e) => onChangeId(e, idx)}
                      placeholder={'Название стандартного поля'}
                    />

                    <button
                      className="modal-crm__btn-close"
                      onClick={() => {
                        deleteTitle(idx);
                        //setObjValuesAttrList()
                        handleDeleteSelectedAttr(idx);
                      }}
                    >
                      <img src={close_icon} />
                    </button>

                    {attr?.is_dict && (
                      <TooltipLightBlue
                        top="24px"
                        right="100%"
                        position={'absolute'}
                        text="Управлять списком"
                        child={
                          <button
                            className="modal-crm__btn-settings"
                            onClick={() => {
                              setCurrentIndex(idx);
                              setIsAttrControlModalOpen(true);
                            }}
                          >
                            <img src={settings_icon} />
                          </button>
                        }
                      />
                    )}
                  </div>
                );
              } else {
                return (
                  <div key={attr.id} className="modal-crm__item-parametr">
                    <p style={{ minWidth: '100px' }}>{attr[2]}</p>
                    {/* <InputDinamycPlaceholder
                      size="s"
                      key={attr.id}
                      type="text"
                      value={attr[2]}
                      onChange={(e) => onChangeId(e, idx)}
                      placeholder={attr[2]}
                      disabled
                    /> */}
                    <InputDinamycPlaceholder
                      type="text"
                      placeholder={Array.isArray(attr) ? attr[1] : attr.title}
                      changeValue={attr[1]}
                      onChange={(e) => {
                        handleInputChange(e, idx, attr[4], attr[2]);
                        console.log(attr, 'AAA');
                      }}
                      style={{ maxWidth: '100px' }}
                      size="s"
                      onBlur={fetchChangeColumn}
                    />
                    {showGeneratedModal && (
                      <Checkbox
                        value={copiedFields.includes(attr)}
                        onChange={() => handleCheckboxChange(attr)}
                      >
                        <p style={{ fontSize: '10px' }}>Copy ID</p>
                      </Checkbox>
                    )}

                    <button
                      className="modal-crm__btn-close"
                      onClick={() => {
                        deleteTitle(idx);
                        handleDeleteSelectedAttr(idx);
                        fetchDeleteColumn(attr[1]);
                      }}
                    >
                      <img src={close_icon} />
                    </button>

                    {attr[2] === 'dictionary' && (
                      <TooltipLightBlue
                        top="24px"
                        right="100%"
                        position={'absolute'}
                        text="Управлять списком"
                        child={
                          <button
                            className="modal-crm__btn-settings"
                            onClick={() => {
                              setCurrentIndex(idx);
                              setIsAttrControlModalOpen(true);
                              setSelectedAttributes([]);
                              setCurrentDictName(
                                Array.isArray(attr) ? attr[1] : attr.title
                              );
                            }}
                          >
                            <img src={settings_icon} />
                          </button>
                        }
                      />
                    )}
                  </div>
                );
              }
            })}
        </div>

        <div className="modal-crm__btn-group">
          <ButtonBasic
            green
            width="150px"
            text={!tableId ? 'Создать таблицу' : 'Сохранить'}
            onClick={(e) => {
              !tableId
                ? onHandlerAttr()
                : (() => {
                    handleClose();
                    onChangeCustomTableName();
                  })();
            }}
          />
        </div>

        <CrmCommonAttrSubModal
          checkedAttr={checkedAttr2}
          setCheckedAttr={(e) => setCheckedAttr2(e)}
          commonAttributes={commonAttributes}
          selectedAttributes={selectedAttributes}
          setSelectedAttributes={setSelectedAttributes}
          isOpen={isCommonAttrSubModalOpen}
          setCurrentAtt={(e) => setCurrentAtt(e)}
          close={() => setIsCommonAttrSubModalOpen(false)}
          fetchCreateColumn={() => fetchCreateColumn()}
        />
        <CrmUncommonAttrSubModal
          checkedAttr={checkedAttr1}
          setCheckedAttr={(e) => setCheckedAttr1(e)}
          uncommonAttrTypes={uncommonAttrTypes}
          selectedAttributes={selectedAttributes}
          setSelectedAttributes={setSelectedAttributes}
          isOpen={isUncommonAttrSubModalOpen}
          close={() => setIsUncommonAttrSubModalOpen(false)}
          fetchCreateColumn={() => fetchCreateColumn()}
        />
        <CrmAttrControlModal
          objValuesAttr={objValuesAttr}
          setObjValuesAttr={(e) => setObjValuesAttr(e)}
          currentIndex={currentIndex}
          setCurrentIndex={(e) => setCurrentIndex(e)}
          selectedTableType={selectedTableType}
          selectedAttributes={selectedAttributes}
          setSelectedAttributes={setSelectedAttributes}
          isOpen={isAttrControlModalOpen}
          close={() => {
            setIsAttrControlModalOpen(false);
          }}
          tableId={tableId}
          fieldName={currentDictName}
        />
      </div>
      {showGeneratedModal && (
        <div className="modal-crm__generated-modal">
          <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
            <h2>Для записи лида используйте метод:</h2>
            <button
              className="modal-crm__btn-close"
              onClick={() => {
                setShowGeneratedModal(false);
                setCopiedFields([]);
              }}
            >
              <img src={close_icon} />
            </button>
          </div>
          <div style={{ display: 'flex', gap: '12px' }}>
            <h3>URL:</h3>
            <p style={{ textDecoration: 'underline', fontSize: '18px' }}>
              https://asdb.com/add/lead
            </p>
          </div>
          <hr />
          <h3>С телом запроса:</h3>
          <pre>{JSON.stringify(generateRequestBody(), null, 2)}</pre>
        </div>
      )}
    </div>
  );
};
