import React, { useRef, useState } from 'react';
import './ModalTableBox.scss';
import { TableBox, ButtonBasic } from '@lk-gtcom/ecomlab-components';

const ModalTableBox = ({
  onClose,
  tableProps,
  onAddProductToAction,
  setCheckedProducts,
  onAddProducts,
  setIsPromoModalOpen,
}) => {
  const paginatorRef = useRef();

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal_csv_container" onClick={(e) => e.stopPropagation()}>
        <h2>Таблица товаров</h2>

        <div className="table-container">
          <TableBox
            {...tableProps}
            paginator={true}
            ref={paginatorRef}
            onCheck={(e) => {
              setCheckedProducts(e);
            }}
            // onUncheckAllDependence={[pageValue, currentDiscount, productAction]}
            child={
              <>
                <ButtonBasic
                  action
                  width="180px"
                  maxWidth="180px"
                  text={'Добавить выбранные'}
                  size="32px"
                  // disabled={!checkedProducts.length}
                  onClick={() => {
                    onAddProductToAction();
                    onClose();
                    setIsPromoModalOpen();
                  }}
                />
                <ButtonBasic
                  action
                  width="180px"
                  maxWidth="180px"
                  text={'Добавить все'}
                  size="32px"
                  // disabled={!checkedProducts.length}
                  onClick={() => {
                    onAddProducts();
                    onClose();
                    setIsPromoModalOpen();
                  }}
                />
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ModalTableBox;
