import { useState, useEffect, useRef } from 'react';
import { DropDownSelector, Tabs, DropContainer, TableBox, RangeBox } from '@lk-gtcom/ecomlab-components';
import LineChart from '../../charts/LineChart';
import {
    fetchApiFilter,
    fetchBrandFilter,
    fetchCategoryFilter,
    fetchCampaignFilter,
    fetchWarehouseFilter,
    fetchAdvCampaignFilter,
    fetchOrderSuperStatusFilter,
    fetchAdvCampaignTypeFilter,
    fetchAdvStatusFilter,
    fetchApiFilterVer3,
    fetchFilterData,
} from '../../common/filtersFunc';
import { useParams } from 'react-router-dom';
import { FilterContainer } from '../../components/FilterContainer/FilterContainer';
import { useDataTable } from '../../common/hooks';
import { useChartData } from '../../common/hooks';
import useGeneralStore from '../../store/general';
import './ParsingAdvertisingByBanner.scss'

const mp_options = [
    {
        label: 'Ozon',
        value: 1,
    },
    {
        label: 'Wildberries',
        value: 3,
    },
    {
        label: 'Yandex',
        value: 2,
    },
];

const btn_tab = [
    {
        label: 'Мои данные',
        value: 'my-details',
    },
    {
        label: 'Уведомления',
        value: 'notifications',
    },
    {
        label: 'Безопасность',
        value: 'safety',
    },
];

const tabs = [
    {
        label: 'По Баннерам',
        value: 'by_banner'
    },
    {
        label: 'По брендам',
        value: 'by_brand'
    },
    {
        label: 'По категориям',
        value: 'by_category'
    },
    {
        label: 'По товарам',
        value: 'by_product'
    },
    {
        label: 'По адресам',
        value: 'address'
    },
    {
        label: 'По типам рекламы',
        value: 'by_advertising_type'
    }
]

const ParsingAdvertisingByBanner = ({

}) => {
    const apiIdRef = useRef();
    const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const activeTab = useParams()['*'];
    const [apiData, setApiData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [brandData, setBrandData] = useState([]);
    const [campaignData, setCampaignData] = useState([]);
    const [warehouseData, setWarehouseData] = useState([]);
    const [advCampaignData, setAdvCampaignData] = useState([]);
    const [superStautsData, setSuperStatusData] = useState([]);
    const [advCampaignTypeData, setAdvCampaignTypeData] = useState([]);
    const [advStatusData, setAdvStatusData] = useState([]);
    const [pageValue, setPageValue] = useState(btn_tab[0].value);
    const [selectedMp, setSelectedMp] = useState([]);
    const [selectedApi, setSelectedApi] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState([]);
    const [selectedCampaign, setSelectedCampaign] = useState([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(['all']);

    const [showDropBox, setShowDropBox] = useState(false);
    const [tableMetrics, setTableMetrics] = useState([]);
    const [tableMetric, setTableMetric] = useState('');

    const [apiPage, setApiPage] = useState(1);
    const [categoryPage, setCategoryPage] = useState(1);
    const [brandPage, setBrandPage] = useState(1);
    const [campaignPage, setCampaignPage] = useState(1);
    const [warehousePage, setWarehousePage] = useState(1);
    const [advCampaignPage, setAdvCampaignPage] = useState(1);
    const [superStatusPage, setSuperStatusPage] = useState(1);
    const [advCampaignTypePage, setAdvCampaignTypePage] = useState(1);
    const [advStatusPage, setAdvStatusPage] = useState(1);

    const [apiQuery, setApiQuery] = useState('');
    const [categoryQuery, setCategoryQuery] = useState('');
    const [brandQuery, setBrandQuery] = useState('');
    const [campaignQuery, setCampaignQuery] = useState('');
    const [warehouseQuery, setWarehouseQuery] = useState('');
    const [advCampaignQuery, setAdvCampaignQuery] = useState('');
    const [superStatusQuery, setSuperStatusQuery] = useState('');
    const [advCampaignTypeQuery, setAdvCampaignTypeQuery] = useState('');
    const [advStatusQuery, setAdvStatusQuery] = useState('');

    const [setChartData, chartData, setChartFuncs] = useChartData();
    const { setChartLoading, resetChart } = setChartFuncs;

    const paginatorRef = useRef()
    const [setTable, tableProps, setTableFuncs] = useDataTable(paginatorRef.current?.setTotal, paginatorRef)
    const { loading, sort, filters } = tableProps
    const { setLoading, setFetchedData, setHeaders, setSort, setFilters } = setTableFuncs

    const [tableUrl, setTableUrl] = useState('')
    const [tableBody, setTableBody] = useState(null)

    const [date, setDate] = useState([
        new Date().getTime() - 604800000,
        new Date().getTime(),
    ]);
    const [dateFrom, dateTo] = date;

    const apiFilter = () => {
        fetchApiFilterVer3({
            selectedSpace: selectedSpace?.value,
            page: apiPage,
            setApiData: setApiData,
            set: setApiData,
            searchParam: apiQuery,
            selectedApi,
            selectedCategory,
            selectedBrand,
            selectedProduct,
            selectedShop: selectedMp,
            filter: 'api_id',
        });
    };

    useEffect(() => {
        setPageValue(activeTab);
      }, [activeTab]);

    useEffect(() => {
        apiFilter();
    }, [apiPage, apiQuery]);

    const superStatusFilter = () => {
        fetchOrderSuperStatusFilter({
            selectedSpace: selectedSpace?.value,
            page: superStatusPage,
            setSuperStatusData: setSuperStatusData,
            searchParam: superStatusQuery,
            selectedCategory,
            selectedBrand,
            selectedProduct,
            selectedShop: selectedMp,
            selectedCampaign,
            apiData,
            selectedApi,
            dateFrom,
            dateTo,
        });
    };

    useEffect(() => {
        superStatusFilter();
    }, [superStatusPage, superStatusQuery]);

    const campaignTypeFilter = () => {
        fetchAdvCampaignTypeFilter({
            selectedSpace: selectedSpace?.value,
            page: advCampaignTypePage,
            setSuperStatusData: setAdvCampaignTypeData,
            searchParam: advCampaignTypeQuery,
            selectedCategory,
            selectedBrand,
            selectedProduct,
            selectedShop: selectedMp,
            selectedCampaign,
            apiData,
            selectedApi,
            dateFrom,
            dateTo,
        });
    };

    useEffect(() => {
        campaignTypeFilter();
    }, [campaignPage, campaignQuery]);

    const advStatusFilter = () => {
        fetchAdvStatusFilter({
            selectedSpace: selectedSpace?.value,
            page: advStatusPage,
            setSuperStatusData: setAdvStatusData,
            searchParam: advStatusQuery,
            selectedCategory,
            selectedBrand,
            selectedProduct,
            selectedShop: selectedMp,
            selectedCampaign,
            apiData,
            selectedApi,
            dateFrom,
            dateTo,
        });
    };

    useEffect(() => {
        advStatusFilter();
    }, [advStatusQuery, advStatusPage]);

    const advCampaignFilter = () => {
        fetchAdvCampaignFilter({
            selectedSpace: selectedSpace?.value,
            page: advCampaignPage,
            setAdvCampaignData: setAdvCampaignData,
            searchParam: advCampaignQuery,
            selectedCategory,
            selectedBrand,
            selectedProduct,
            selectedShop: selectedMp,
            selectedCampaign,
            apiData,
            selectedApi,
        });
    };

    useEffect(() => {
        advCampaignFilter();
    }, [advCampaignPage, advCampaignQuery]);

    const categoryFilter = () => {
        fetchApiFilterVer3({
            selectedSpace: selectedSpace?.value,
            page: categoryPage,
            apiData,
            dateFrom,
            dateTo,
            selectedApi,
            selectedCategory,
            selectedBrand,
            selectedProduct,
            selectedShop: selectedMp,
            selectedCampaign,
            set: setCategoryData,
            setCategoryData: (e) => setCategoryData(e),
            searchParam: categoryQuery,
            filter: 'category',
        });
    };

    useEffect(() => {
        categoryFilter();
    }, [categoryPage, categoryQuery]);

    const brandFilter = () => {
        fetchApiFilterVer3({
            selectedSpace: selectedSpace?.value,
            apiData,
            categoryData,
            selectedApi,
            selectedCategory,
            selectedBrand,
            selectedProduct,
            selectedShop: selectedMp,
            selectedCampaign,
            dateFrom,
            dateTo,
            page: brandPage,
            searchParam: brandQuery,
            set: setBrandData,
            setBrandData: (e) => setBrandData(e),
            filter: 'brand',
        });
    };

    useEffect(() => {
        brandFilter();
    }, [brandPage, brandQuery]);

    const campaignFilter = () => {
        fetchApiFilterVer3({
            selectedSpace: selectedSpace?.value,
            page: campaignPage,
            apiData,
            categoryData,
            selectedApi,
            selectedCategory,
            selectedBrand,
            selectedProduct,
            selectedShop: selectedMp,
            selectedCampaign,
            brandData,
            dateFrom,
            dateTo,
            searchParam: campaignQuery,
            set: setCampaignData,
            setCampaignData: (e) => setCampaignData(e),
            filter: 'campaign',
        });
    };

    useEffect(() => {
        campaignFilter();
    }, [campaignPage, campaignQuery]);

    const warehouseFilter = () => {
        fetchApiFilterVer3({
            selectedSpace: selectedSpace?.value,
            page: warehousePage,
            apiData,
            categoryData,
            selectedApi,
            selectedCategory,
            selectedBrand,
            selectedProduct,
            selectedShop: selectedMp,
            selectedCampaign,
            brandData,
            dateFrom,
            dateTo,
            searchParam: warehouseQuery,
            setWarehouseData: (e) => setWarehouseData(e),
            filter: 'warehouse',
        });
    };

    useEffect(() => {
        warehouseFilter();
    }, [warehousePage, warehouseQuery]);

    useEffect(() => {
        apiFilter();
        categoryFilter();
        brandFilter();
        superStatusFilter();
        campaignTypeFilter();
        advStatusFilter();
        advCampaignFilter();
        campaignFilter();
        warehouseFilter();
    }, []);

    useEffect(() => {
        apiIdRef.current?.focus();
    }, []);

    const includeCampaign = Array.isArray(selectedMp)
        ? selectedMp?.some((item) => item.label === 'Все') ||
        selectedMp?.some((item) => item.label === 'Yandex')
        : false;

    const [isTableRequest, setIsTableRequest] = useState(false);

    return (
        <div className="parsing-advertising-by-keywords">
            <h1 class="parsing-advertising-by-keywords__title-main">Парсинг рекламы: по баннерам</h1>

            <div className="parsing-advertising-by-keywords__toolbar-top">
                <FilterContainer
                    onReset={(e) => {
                        setSelectedApi([]);
                        setSelectedCategory([]);
                        setSelectedBrand([]);
                        setSelectedCampaign([]);
                        setSelectedWarehouse([]);
                        setAdvCampaignData([]);
                    }}
                >

                    <DropDownSelector
                        options_prop={mp_options}
                        state={selectedMp}
                        setState={(e) => setSelectedMp(e)}
                        className="connections_page_selector"
                        placeholder="Площадка"
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        onBlur={() => {
                            apiFilter();
                            categoryFilter();
                            brandFilter();
                            superStatusFilter();
                            campaignTypeFilter();
                            advStatusFilter();
                            advCampaignFilter();
                            campaignFilter();
                            warehouseFilter();
                            setIsTableRequest(true);
                        }}
                        multi
                    />


                    <DropDownSelector
                        options_prop={categoryData}
                        state={selectedCategory}
                        setState={(e) => {
                            setSelectedCategory(e);
                        }}
                        className="connections_page_selector"
                        placeholder="Категория"
                        fetchCallback={(e) => {
                            setCategoryPage(e);
                        }}
                        setInputChange={(e) => setCategoryQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        multi
                        onBlur={() => {
                            brandFilter();
                            superStatusFilter();
                            campaignTypeFilter();
                            advStatusFilter();
                            advCampaignFilter();
                            campaignFilter();
                            warehouseFilter();
                            setIsTableRequest(true);
                        }}
                    />


                    <DropDownSelector
                        options_prop={brandData}
                        state={selectedBrand}
                        setState={(e) => setSelectedBrand(e)}
                        className="connections_page_selector"
                        placeholder="Бренд"
                        fetchCallback={(e) => setBrandPage(e)}
                        setInputChange={(e) => setBrandQuery(e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        onBlur={(e) => {
                            brandFilter();
                            superStatusFilter();
                            campaignTypeFilter();
                            advStatusFilter();
                            advCampaignFilter();
                            campaignFilter();
                            warehouseFilter();
                            setIsTableRequest(true);
                        }}
                        multi
                    />

                    <DropDownSelector
                        options_prop={[]}
                        state={[]}
                        setState={(e) => (e)}
                        placeholder="Тип рекламы"
                        fetchCallback={(e) => (e)}
                        setInputChange={(e) => (e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        onBlur={(e) => { }}
                        multi
                    />

                    <DropDownSelector
                        options_prop={[]}
                        state={[]}
                        setState={(e) => (e)}
                        placeholder="Название рекламы"
                        fetchCallback={(e) => (e)}
                        setInputChange={(e) => (e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        onBlur={(e) => { }}
                        multi
                    />

                    <DropDownSelector
                        options_prop={[]}
                        state={[]}
                        setState={(e) => (e)}
                        placeholder="Адрес"
                        fetchCallback={(e) => (e)}
                        setInputChange={(e) => (e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        onBlur={(e) => { }}
                        multi
                    />

                    <DropDownSelector
                        options_prop={[]}
                        state={[]}
                        setState={(e) => (e)}
                        placeholder="Тип баннера"
                        fetchCallback={(e) => (e)}
                        setInputChange={(e) => (e)}
                        defaultValue={[{ label: 'Все', value: 'all' }]}
                        onBlur={(e) => { }}
                        multi
                    />

                </FilterContainer>
            </div>

            <DropContainer
                showDropBox={showDropBox}
                setShowDropBox={(e) => setShowDropBox(e)}
                name={chartData?.title ? chartData?.title : ''}
            >
                <LineChart
                    selectedMetric={tableMetric}
                    setSelectedMetric={setTableMetric}
                    metricsList={tableMetrics}
                    {...chartData}
                />
            </DropContainer>

            <Tabs tabs={tabs} pageValue={pageValue} />

            <TableBox
                {...tableProps}
                paginator={true}
                ref={paginatorRef}
                fetchCallback={e => (e)}
                tableUrl={tableUrl}
                tableBody={tableBody}
            />
        </div>
    );
};

export { ParsingAdvertisingByBanner };
