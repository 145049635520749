import { useState, useEffect } from 'react';
import { InputDinamycPlaceholder } from '@lk-gtcom/ecomlab-components';
import { KonstantionAuthHttps, KonstantionAuthHttp } from '../../fetchUrls';
import { Link, Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { Mail } from '../../components/PasswordRecoveryBox/Mail/Mail';
import Cookies from 'universal-cookie';
import './PasswordRecoveryPage.scss';
import { validationEmail } from '../../common/validationFunc';
import { ModalErr } from '../../components/Modal/ModalErr/ModalErr';
import { ButtonBasic } from '@lk-gtcom/ecomlab-components';

const PasswordRecoveryPage = ({ setIsModal }) => {
  const path = useParams();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [errEmail, setErrEmail] = useState(false);

  useEffect(() => {
    if (path['*'] === '') {
      navigate('email');
    }
  }, []);

  const [inValid, setInvalid] = useState(false);
  const [incorrectEmail, setIncorrectEmail] = useState(false);
  const [activeContent, setActiveContent] = useState(true);
  // const [disabledBtn, setdisabledBtn] = useState(false)
  const [isModalErr, setIsModalErr] = useState(false);
  const [isModalMail, setIsModalMail] = useState(true);
  const auth = new Cookies().get('auth');

  const handlerSetEmail = (e) => {
    setIncorrectEmail(false);
    setErrEmail(false);
    setEmail(e);
  };

  const checkInputField = () => {
    if (!validationEmail(email)) {
      setErrEmail(true);
    }

    if (validationEmail(email)) {
      handlerSubmit();
    }
  };

  const handlerSubmit = (e) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantionAuthHttps}/web/forgot-password`;
    } else {
      url = `${KonstantionAuthHttp}/web/forgot-password`;
    }

    const headers = {
      'Content-type': 'application/json',
      Authorization: `Bearer ${auth}`,
      'portal-id': '4',
    };

    const body = JSON.stringify({
      email,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      // headers: { 'Content-type': 'application/json', 'Authorization': `Bearer ${auth}` }
    })
      .then((res) => res.json())
      .then(async (json) => {
        setIsModalMail(true);
        setActiveContent(false);
      })
      .catch((err) => {
        setIsModalErr(true);
        console.error(err);
      });
  };

  return (
    <>
      {activeContent ? (
        <div
          className="blackout"
          onClick={(e) => {
            let el = e.target;
            if (el.classList.contains('blackout')) {
              setIsModal(false);
            }
          }}
        >
          <div className="password-recovery">
            <h2 className="title" onClick={(e) => setIsModal(false)}>
              Восстановление пароля
            </h2>

            <InputDinamycPlaceholder
              onChange={(e) => handlerSetEmail(e)}
              type="e-mail"
              classname="autorization__input-field"
              placeholder="E-mail"
              err={errEmail}
              textErr="Неверный e-mail формат"
            />

            <ButtonBasic
              blue
              style={{ marginTop: '4px' }}
              width="100%"
              size="40px"
              text="Восстановить пароль"
              onClick={(e) => {
                checkInputField();
              }}
            />
          </div>
        </div>
      ) : (
        <>
          {isModalErr ? (
            <ModalErr
              isModal={isModalErr}
              setIsModal={(e) => setIsModalErr(e)}
            />
          ) : (
            <Mail
              title="Проверьте электронную почту"
              text={`Мы отправили инструкцию для восстановления пароля на ${
                email ? email : 'ваш email'
              }`}
              setIsModal={(e) => setIsModal(e)}
            />
          )}
        </>
      )}
    </>
  );
};

export { PasswordRecoveryPage };
