// import customHeaders, { getSpace } from '../../../common/headers';
// import useGeneralStore from '../../../store/general';
// import {
//   ButtonBasic,
//   InputDinamycPlaceholder,
// } from '@lk-gtcom/ecomlab-components';
// import { DownloadComponent } from '../../DownloadComponent/DownloadComponent';
// import file_icon from './img/bx-file.svg';
// import close from './img/close.svg';
// import x from './img/bx-x.svg';
// import plus_circle from './img/bx-plus-circle.svg';
// import './ModalCreateChatBot.scss';
// import { useEffect, useState } from 'react';
// import {
//   Andrey3Https,
//   AndreyFilesHttp,
//   AndreyFilesHttps,
// } from '../../../fetchUrls';

// const FileItem = ({ name = 'Нет названия' }) => {
//   return (
//     <div className="file-item">
//       <button className="file-item__btn">
//         <img src={x} />
//       </button>
//       <img src={file_icon} className="file-item__icon" />
//       <p className="file-item__name">{name}</p>
//     </div>
//   );
// };

// const DropZone2 = ({ isSaveFiles, setIsSaveFiles, filesdata, setFiles }) => {
//   const [fileData, setFilesData] = useState([]);

//   return (
//     <div className="drop-zone-2">
//       <img src={plus_circle} />

//       <DownloadComponent
//         isSaveFiles={isSaveFiles}
//         setIsSaveFiles={(e) => setIsSaveFiles(e)}
//         filesdata={filesdata}
//         setFilesArr={(e) => setFiles(e)}
//         setFilesData={(e) => setFilesData(e)}
//       />
//     </div>
//   );
// };

// const ModalCreateChatBot = ({ setIsModal }) => {
//   const selectedSpace = useGeneralStore((state) => state.selectedSpace);
//   const headers = { ...customHeaders, ...getSpace(selectedSpace) };
//   const [name, setName] = useState('');
//   const [isSaveFiles, setIsSaveFiles] = useState(false);
//   const [files, setFiles] = useState([]);
//   const [filesdata, setFilesData] = useState([]);

//   //файлы загруженные пользователем
//   const fetchBotFiles = () => {
//     let url;
//     if (!window.location.hostname.match('localhost')) {
//       url = `${AndreyFilesHttps}/file/get-file`;
//     } else {
//       // url = `${Andrey3Http}/bot/bot-list`;
//       url = `${AndreyFilesHttps}/file/get-file`;
//     }

//     fetch(url, { method: 'GET', headers })
//       .then((res) => {
//         if (res.ok) {
//           return res.json();
//         } else {
//           throw new Error('Error: get_tables_list request');
//         }
//       })
//       .then((json) => {
//         console.log(json, 'files');
//       })
//       .catch((err) => {
//         console.error(err);
//       });
//   };

//   //загрузить файл с документацией на сервер
//   const fetchUploadFile = () => {
//     let url;
//     if (!window.location.hostname.match('localhost')) {
//       url = `${AndreyFilesHttps}/file/upload-file`;
//     } else {
//       url = `${AndreyFilesHttps}/file/upload-file`;
//     }

//     const body = JSON.stringify({
//       file_uuid: [],
//     });

//     fetch(url, { body, method: 'POST', headers })
//       .then((res) => {
//         if (res.ok) {
//           return res.json();
//         } else {
//           throw new Error(res.json());
//         }
//       })
//       .then((json) => {
//         console.log(json);
//       })
//       .catch((err) => {
//         console.error(err);
//       });
//   };

//   //удалить файл с документацией
//   const fetchDeleteFile = (fileId, clientId) => {
//     let url;
//     if (!window.location.hostname.match('localhost')) {
//       url = `${AndreyFilesHttps}/file/delete-file?file_id=${fileId}&client_id=${clientId}`;
//     } else {
//       url = `${AndreyFilesHttps}/file/delete-file?file_id=${fileId}&client_id=${clientId}`;
//     }

//     fetch(url, { method: 'DELETE', headers })
//       .then((res) => {
//         if (res.ok) {
//           return res.json();
//         } else {
//           throw new Error('Failed to delete file');
//         }
//       })
//       .then((json) => {
//         console.log(json);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   //создать чат бот
//   const fetchCreateBot = () => {
//     let url;
//     if (!window.location.hostname.match('localhost')) {
//       url = `${Andrey3Https}/bot/create`;
//     } else {
//       url = `${Andrey3Https}/bot/create`;
//     }

//     const body = JSON.stringify({
//       file_uuid: [],
//     });

//     fetch(url, { body, method: 'POST', headers })
//       .then((res) => {
//         if (res.ok) {
//           return res.json();
//         } else {
//           throw new Error(res.json());
//         }
//       })
//       .then((json) => {
//         console.log(json);
//       })
//       .catch((err) => {
//         console.error(err);
//       });
//   };

//   //изменить чат бот
//   const fetchUpdateBot = (bot_id) => {
//     let url;
//     if (!window.location.hostname.match('localhost')) {
//       url = `${Andrey3Https}/bot/${bot_id}/update`;
//     } else {
//       url = `${Andrey3Https}/bot/${bot_id}/update`;
//     }

//     const body = JSON.stringify({
//       file_uuid: [],
//     });

//     fetch(url, { body, method: 'PATCH', headers })
//       .then((res) => {
//         if (res.ok) {
//           return res.json();
//         } else {
//           throw new Error(res.json());
//         }
//       })
//       .then((json) => {
//         console.log(json);
//       })
//       .catch((err) => {
//         console.error(err);
//       });
//   };

//   //удалить чат бот
//   const fetchDeleteBot = (bot_id) => {
//     let url;
//     if (!window.location.hostname.match('localhost')) {
//       url = `${Andrey3Https}/bot/${bot_id}/delete`;
//     } else {
//       url = `${Andrey3Https}/bot/${bot_id}/delete`;
//     }

//     const body = JSON.stringify({
//       file_uuid: [],
//     });

//     fetch(url, { body, method: 'POST', headers })
//       .then((res) => {
//         if (res.ok) {
//           return res.json();
//         } else {
//           throw new Error(res.json());
//         }
//       })
//       .then((json) => {
//         console.log(json);
//       })
//       .catch((err) => {
//         console.error(err);
//       });
//   };

//   useEffect(() => {
//     fetchBotFiles();
//   }, []);

//   return (
//     <div
//       className="blackout"
//       onClick={(e) => {
//         let el = e.target;
//         if (el.classList.contains('blackout')) {
//           setIsModal(false);
//         }
//       }}
//     >
//       <div className="modal-create-chat-bot">
//         <div className="modal-create-chat-bot__header">
//           <h1 className="title_main">Создание чат-бота</h1>
//           <button
//             className="modal-create-chat-bot__btn-close"
//             onClick={(e) => setIsModal(false)}
//           >
//             <img src={close} />
//           </button>
//         </div>
//         <div className="modal-create-chat-bot__content">
//           <div className="modal-create-chat-bot__input-box">
//             <InputDinamycPlaceholder
//               onChange={(e) => setName(e)}
//               placeholder="Название чат-бота"
//               size="s"
//             />
//           </div>
//           <h3 className="modal-create-chat-bot__title">Файлы для чат бота</h3>
//           <div className="modal-create-chat-bot__files-box">
//             <FileItem />
//             <FileItem />
//             <DropZone2
//               isSaveFiles={isSaveFiles}
//               setIsSaveFiles={setIsSaveFiles}
//               filesdata={filesdata}
//               setFiles={setFiles}
//             />
//           </div>
//         </div>
//         <div className="modal-create-chat-bot__btn-box">
//           <ButtonBasic
//             green
//             width="160px"
//             text="Создать чат-бота"
//             onClick={fetchCreateBot}
//           />

//           <ButtonBasic whiteGreen width="auto" text="Отменить" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export { ModalCreateChatBot };

import { useEffect, useState } from 'react';
import customHeaders, { getSpace } from '../../../common/headers';
import useGeneralStore from '../../../store/general';
import {
  ButtonBasic,
  InputDinamycPlaceholder,
} from '@lk-gtcom/ecomlab-components';
import file_icon from './img/bx-file.svg';
import close from './img/close.svg';
import plus_circle from './img/bx-plus-circle.svg';
import './ModalCreateChatBot.scss';
import { Andrey3Https, AndreyFilesHttps } from '../../../fetchUrls';
import { MoonLoader } from 'react-spinners';

const FileItem = ({ name = 'Нет названия', onDelete }) => {
  return (
    <div className="file-item">
      <button className="file-item__btn" onClick={onDelete}>
        <img src={close} alt="удалить" />
      </button>
      <img src={file_icon} className="file-item__icon" alt="file icon" />
      <p className="file-item__name">{name}</p>
    </div>
  );
};

const DropZone2 = ({ onUpload }) => {
  const handleFileUpload = (e) => {
    const uploadedFiles = Array.from(e.target.files);
    onUpload(uploadedFiles);
  };

  return (
    <div className="drop-zone-2">
      <img src={plus_circle} alt="Добавить" />
      <input
        type="file"
        multiple
        onChange={handleFileUpload}
        style={{ display: 'none' }}
        id="file-upload"
      />
      <label htmlFor="file-upload" className="file-upload-label">
        Добавить файлы
      </label>
    </div>
  );
};

const ModalCreateChatBot = ({
  setIsModal,
  isEdit,
  setIsEdit,
  botId,
  setBotId,
  chatName,
  setChatName,
}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [name, setName] = useState('');
  const [files, setFiles] = useState([]);
  const [fileBot, setFileBot] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loadingFile, setLoadingFile] = useState(false);

  const addFileToBot = async (botId, file) => {
    if (!file) {
      console.error('Файл отсутствует');
      return;
    }

    const url = `${Andrey3Https}/bot/${String(botId)}/add-file`;

    const formData = new FormData();
    formData.append('file', file);

    const updatedHeaders = { ...headers };
    delete updatedHeaders['Content-Type'];

    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: updatedHeaders,
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Ошибка добавления файла:', errorData);
      }

      const data = await response.json();
      setFileBot(data);
      fetchBotFiles(botId);
      setLoadingFile(true);
    } catch (err) {
      console.error('Ошибка запроса:', err);
      return null;
    }
  };

  const handleFileUpload = async (newFile) => {
    if (!newFile || !newFile.length) return;

    setLoadingFile(true);
    try {
      await addFileToBot(botId, newFile[0]);
    } catch (error) {
      console.error('Ошибка загрузки файла');
    } finally {
      setLoadingFile(false);
    }
  };

  const handleFileDelete = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleResetModal = () => {
    setIsEdit(false);
    setIsModal(false);
    setBotId(null);
  };

  const fetchCreateBot = () => {
    const url = `${Andrey3Https}/bot/create`;

    const body = JSON.stringify({
      bot_name: name,
    });

    fetch(url, {
      method: 'POST',
      headers,
      body,
    })
      .then((res) => {
        if (!res.ok) throw new Error('Ошибка создания чат-бота');
        return res.json();
      })
      .then((data) => {
        const { bot_id } = data;
        setBotId(bot_id);
      })
      .catch((err) => console.error(err));
  };

  const fetchBotFiles = (botId) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${Andrey3Https}/bot/${botId}/file-list`;
    } else {
      url = `${Andrey3Https}/bot/${botId}/file-list`;
    }

    fetch(url, { method: 'GET', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('Error: get_tables_list request');
        }
      })
      .then((json) => {
        console.log(json, 'files');
        setFileBot(json);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (botId) {
      fetchBotFiles(botId);
    }
    return () => {
      setChatName('');
    };
  }, []);

  return (
    <div
      className="blackout"
      // onClick={(e) => {
      //   if (e.target.classList.contains('blackout')) {
      //     setIsModal(false);
      //   }
      // }}
    >
      <div className="modal-create-chat-bot">
        <div className="modal-create-chat-bot__header">
          <h1 className="title_main">
            {!isEdit
              ? `Создание чат-бота`
              : `Редактирование чат-бота "${chatName}"`}
          </h1>
          <button
            className="modal-create-chat-bot__btn-close"
            onClick={() => {
              handleResetModal();
            }}
          >
            <img src={close} alt="закрыть" />
          </button>
        </div>
        <div className="modal-create-chat-bot__content">
          <div className="modal-create-chat-bot__input-box">
            <InputDinamycPlaceholder
              changeValue={chatName}
              onChange={(e) => setName(e)}
              placeholder="Название чат-бота"
              size="s"
            />
          </div>
          {botId && (
            <>
              <h3 className="modal-create-chat-bot__title">
                Загруженные файлы для чат-бота
              </h3>
              {loadingFile ? (
                <MoonLoader color="#22C55E" speedMultiplier={0.5} size={50} />
              ) : fileBot?.length ? (
                fileBot.map((el, ind) => (
                  <div key={el + ind} style={{ display: 'flex', gap: '12px' }}>
                    <p>{ind + 1}</p>
                    <p>{el?.file_name}</p>
                    <p>{el?.file_uuid}</p>
                  </div>
                ))
              ) : (
                <p>Файлов пока нет</p>
              )}
              <h3 className="modal-create-chat-bot__title">
                Добавить файлы для чат-бота
              </h3>
              <div className="modal-create-chat-bot__files-box">
                {files.map((file, index) => (
                  <FileItem
                    key={index}
                    name={file.name}
                    onDelete={() => handleFileDelete(index)}
                  />
                ))}
                <DropZone2 onUpload={handleFileUpload} />
              </div>
            </>
          )}
        </div>
        <div className="modal-create-chat-bot__btn-box">
          <ButtonBasic
            green
            width="160px"
            text={!botId ? 'Создать чат-бота' : 'Сохранить'}
            onClick={() => {
              !botId ? fetchCreateBot() : handleResetModal();
            }}
          />
          {/* <ButtonBasic
            whiteGreen
            width="auto"
            text="Отменить"
            onClick={() => setIsModal(false)}
          /> */}
        </div>
      </div>
    </div>
  );
};

export { ModalCreateChatBot };
