import customHeaders, { getSpace } from '../../common/headers';
import { Editor } from '../Editor/Editor';
import { RavshanHttps } from '../../fetchUrls';
import { useState, useEffect, useRef } from 'react';
import { ExecutorPlaceholder } from '../OptionsExecutor/OptionsExecutor';
import useGeneralStore from '../../store/general';
import "./Comment.scss"

const parse = require('html-react-parser')

const Comment = ({
    name,
    email,
    event_id,
    color,
    fetchUpdateComms = false,
    commentValue = false,
    changeCommentValue,
    disabled,
    filesDataChange,
    setFilesDataChange,
    setFilesChange,
    isEdit,
    hideBtn,
    currentMessageAnswer,
    setCurrentMessageAnswer,
    setIsChanges = false,
    reply_to,
    setSaveFile,
    type,
    fetchAddFiles,
    fetchGetFiles,
    comment_id,
    eventId,
    fetchDeleteFiles,
    saveFile,
    update,
    setCommentPage,
    setComments
}) => {

    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }
    const [comment, setComment] = useState(commentValue ? commentValue : '')
    const editorRef = useRef()

    const [filesData, setFilesData] = useState([])
    const [files, setFiles] = useState([])

    const fetchCreateComment = () => {
        const url = `${RavshanHttps}/api/tasks/create_comment`

        const reply_to = currentMessageAnswer?.id ? { reply_to: currentMessageAnswer?.id } : {}

        const body = JSON.stringify({
            text: comment,
            event_id,
            files: saveFile,
            ...reply_to
        })

        fetch(url, { body, method: 'POST', headers: headers })
            .then(res => {
                if (res.ok) {
                    setComment('')
                    editorRef?.current?.reset()
                    //setCommentPage(1)
                    if (fetchUpdateComms) setTimeout(() => fetchUpdateComms(), 300)
                    setFiles([])
                    setFilesData([])
                    setSaveFile([])
                    update()
                } else {
                    throw new Error('bad attendees response')
                }
            })
            .catch((e) => console.error(e))
    }

    useEffect(() => {
        if ((comment?.length > 0) || (filesData?.length || filesDataChange?.length)) {
            if (commentValue) {
                if (changeCommentValue) {
                    changeCommentValue(comment)
                }
                return
            }
            fetchCreateComment(currentMessageAnswer?.id)
            setCurrentMessageAnswer(null)
        }
    }, [comment])

    return (
        <>
            {currentMessageAnswer && <div className='comment-answer'>
                <p className='comment-answer__author'>В ответ {currentMessageAnswer?.author?.name}</p>
                <p className='comment-answer__text'>{parse(currentMessageAnswer?.text)}</p>
            </div>}

            <div className={'comment'} >
                <div className='comment__content'>
                    <Editor
                        type={'comments'}
                        eventId={eventId}
                        fetchDeleteFiles={fetchDeleteFiles}
                        fetchGetFiles={fetchGetFiles}
                        comment_id={comment_id}
                        setSaveFile={setSaveFile}
                        fetchAddFiles={fetchAddFiles}
                        setIsChanges={(e) => setIsChanges(e)}
                        hideEdit
                        hideBtn={hideBtn}
                        disabled={disabled}
                        comment
                        isEditComment={isEdit}
                        name={name}
                        bounds='.main-info'
                        title={null}
                        placeholder='Оставьте комментарий'
                        description={comment}
                        hideModules
                        saveOnBtn={!commentValue}
                        setDescription={e => setComment(e)}
                        editValue={commentValue}
                        ref={editorRef}
                        filesdata={filesDataChange ? filesDataChange : filesData}
                        setFiles={setFilesDataChange ? setFilesDataChange : setFiles}
                        authorEmail={email}
                        setFilesData={setFilesChange ? setFilesDataChange : setFilesData}
                        setCurrentMessageAnswer={(e) => setCurrentMessageAnswer(e)}
                        saveFile={saveFile}
                    />
                </div>

            </div>
        </>
    )
}

export { Comment }