import { useState } from 'react'
import { LineChart } from '../../charts/LineChart/LineChart'
import { TableBox, DropContainer, Tabs } from "@lk-gtcom/ecomlab-components"
import "./ProductCardExtended_StockBalances.scss"

// const btn_tab = [
//     {
//         label: 'По ключевым словам',
//         value: 'by_keywords',
//     },
//     {
//         label: 'По категориям',
//         value: 'by_category',
//     },
//     {
//         label: 'По брендам',
//         value: 'by_brand',
//     },
//     {
//         label: 'По продавцу',
//         value: 'by_seller',
//     }
// ]

const ProductCardExtended_StockBalances = ({
    tableData, 
    chartData, 
    tableCallback,
    paginatorRef
}) => {

    const [showDropBox, setShowDropBox] = useState(true)
    // const [pageValue, setPageValue] = useState(btn_tab[0].value)

    return (
        <section className='stock-balances-inside-content'>
            {/* <DropContainer
                showDropBox={showDropBox}
                setShowDropBox={(e) => setShowDropBox(e)}
            >
                <LineChart
                    {...chartData}
                />
            </DropContainer> */}

            {/* <Tabs tabs={btn_tab} pageValue={pageValue} setPageValue={e => setPageValue(e)} stopRedirect /> */}

            <TableBox
                {...tableData}
                ref={paginatorRef}
                paginator={true}
                fetchCallback={tableCallback}
            />
        </section>
    )
}

export { ProductCardExtended_StockBalances }