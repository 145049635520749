import customHeaders, { getSpace } from '../../common/headers';
import { useDataTable } from '../../common/hooks';
import { useState, useRef, useEffect } from 'react';
import { AndreyHttps, AndreyHttp, Andrey2Https } from '../../fetchUrls';
import { TableBox } from '@lk-gtcom/ecomlab-components';
import useGeneralStore from '../../store/general';
import './TopKeywords.scss';

const TopKeywords = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);

  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { setLoading, setFetchedData, setHeaders, setSort, setFilters } =
    setTableFuncs;
  const [checkFbs, setCheckFbs] = useState(false);
  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);

  const resetTable = () => {
    paginatorRef.current.reset();
    paginatorRef.current.setTotal(0);
    // setCheckedProducts([])
    setFetchedData([]);
    setHeaders([]);
    setSort({});
    setFilters([]);
  };

  const fetchTableData = (params, abortController = new AbortController()) => {
    // if (!!pageValue & searchValue?.length >= 3) {
    let sort;
    let filtersParam;

    if (params) {
      [sort, filtersParam] = params;
    }

    setLoading(true);
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${Andrey2Https}/api/v1/ecomru/keywords/search_requests/top_keywords/table/`;
    } else {
      url = `${Andrey2Https}/api/v1/ecomru/keywords/search_requests/top_keywords/table/`;
    }

    let [date_from, date_to] = date;

    date_from = new Date(date_from + 10)?.toISOString().split('T')?.[0];
    date_to = new Date(date_to)?.toISOString().split('T')?.[0];

    const body = JSON.stringify({
      date_from,
      date_to,
      page: paginatorRef.current.page,
      limit: paginatorRef.current.limit,
      fbs: checkFbs,
      filters: filtersParam,
      sort_name: sort ? sort[0] : 'Поисковой запрос',
      sort_order: sort ? sort[1] : 'DESC',
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setTable(json);
        setTableUrl(url);
        setTableBody(body);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
        setLoading(false);
      });
    // }
  };

  useEffect(() => {
    const abortController = new AbortController();

    resetTable();
    fetchTableData([], abortController);

    return () => abortController.abort();
  }, [selectedSpace]);

  return (
    <div className="top-keywords">
      <div className="top-keywords__header" onClick={fetchTableData}>
        <h1 className="title_main">Топ ключевых слов</h1>
      </div>
      <TableBox
        {...tableProps}
        tableUrl={tableUrl}
        tableBody={tableBody}
        fetchCallback={fetchTableData}
        ref={paginatorRef}
        paginator={true}
      />
    </div>
  );
};

export { TopKeywords };
