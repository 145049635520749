import { useState } from 'react'
import { LineChart } from '../../charts/LineChart/LineChart'
import { TableBox, DropContainer, Tabs } from "@lk-gtcom/ecomlab-components"
import "./ProductCardExtended_ProductVariants.scss"

const ProductCardExtended_ProductVariants = ({tableData, chartData, tableCallback, paginatorRef}) => {

    const [showDropBox, setShowDropBox] = useState(true)

    return (
        <section className='product-variants-inside-content'>
            {/* <DropContainer
                showDropBox={showDropBox}
                setShowDropBox={(e) => setShowDropBox(e)}
            >
                <LineChart
                    {...chartData}
                />
            </DropContainer> */}

            <TableBox
                {...tableData}
                ref={paginatorRef}
                paginator={true}
                sortingFunc={tableCallback}
                fetchCallback={tableCallback}
            />
        </section>
    )
}

export { ProductCardExtended_ProductVariants }