import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import { Checkbox } from '@lk-gtcom/ecomlab-components';
import { RavshanHttps, RavshanHttp } from '../../../../fetchUrls';
import customHeaders, { getSpace } from '../../../../common/headers';
import { CommentItem } from '../../../CommentItem/CommentItem';
import useGeneralStore from '../../../../store/general';
import './ModalShowFixedMessage.scss'

const ModalShowFixedMessage = ({
    setIsModal,
    eventId,
    comment_id,
    pinMessage
}) => {

    return (
        <div className='blackout'
            style={{ zIndex: 20000 }}
            onClick={(e) => {
                let el = e.target
                if (el.classList.contains('blackout')) {
                    setIsModal(false)
                }
            }}>
            <div className='modal-show-fixed-message'>
                <h2 className='modal-fixed-message__title'>Закрепленное сообщение</h2>
                <div className='modal-add-task__list-message'>
                    {pinMessage?.map((props, ind) => {
                        const { id, date } = props;
                        let date_message = null;

                        date_message = date;

                        return (
                            <>
                                {date_message && (
                                    <p
                                        className={
                                            'modal-add-task__date-message'
                                        }
                                    >
                                        {new Date(
                                            date_message
                                        )?.toLocaleDateString('ru-RU', {
                                            day: '2-digit',
                                            month: 'long',
                                            year: 'numeric',
                                        })}
                                    </p>
                                )}

                                <CommentItem
                                    // setSaveFileComment={setSaveFileComment}
                                    // saveFile={saveFileComment}
                                    // setSaveFile={setSaveFileComment}
                                    type={'comments'}
                                    // fetchAddFiles={fetchAddFiles}
                                    // fetchGetFiles={fetchGetFiles}
                                    // fetchDeleteFiles={fetchDeleteFiles}
                                    hideTools
                                    eventId={eventId}
                                    // setCurrentMessageAnswer={(e) =>
                                    //     setCurrentMessageAnswer(e)
                                    // }
                                    disabled={true}
                                    comment_id={id}
                                    key={id}
                                    {...props}
                                    event_id={eventId}
                                // setIsModalFixed={setIsModalFixed}
                                // fetchComments={(e) => fetchComments(e)}
                                // fetchPinComment={fetchPinComment}
                                />
                            </>
                        );

                    })}
                </div>
            </div>
        </div>
    )
}

export { ModalShowFixedMessage }

