import React, { useState, useEffect, useRef } from 'react';
import { Andrey3Https } from '../../fetchUrls';
import './ChatWidget.scss';

const generateUUID4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
    const random = (Math.random() * 16) | 0;
    const value = char === 'x' ? random : (random & 0x3) | 0x8;
    return value.toString(16);
  });
};

const ChatWidget = ({ botId, handleKillChat, chatName }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [chatId, setChatId] = useState(null);
  const [isTyping, setIsTyping] = useState(false);

  const [sessionId] = useState(generateUUID4());
  const messageIds = new Set();

  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (botId) {
      const apiUrl = `${Andrey3Https}/chat-bot/chat/${botId}/create?session_id=${sessionId}`;
      fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      })
        .then((response) => response.json())
        .then((data) => setChatId(data.chat_id))
        .catch((error) => console.error('Ошибка создания чата:', error));
    }
  }, [botId, sessionId]);

  const fetchMessages = async () => {
    if (!chatId) return;

    const apiUrl = `${Andrey3Https}/chat-bot/chat/${chatId}/get-message?session_id=${sessionId}`;
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });

      const messagesData = await response.json();
      const unreadMessageIds = [];
      const newMessages = [];

      messagesData.forEach((msg) => {
        if (!msg.is_read) {
          unreadMessageIds.push(msg.message_id);
        }

        if (!messageIds.has(msg.message_id)) {
          newMessages.push({
            sender: 'bot',
            content: msg.text,
            messageId: msg.message_id,
          });
          messageIds.add(msg.message_id);
        }
      });

      if (unreadMessageIds.length > 0) {
        await markMessagesAsRead(unreadMessageIds);
      }

      if (newMessages) {
        setIsTyping(false);
      }

      setMessages((prevMessages) => [...prevMessages, ...newMessages]);
    } catch (error) {
      console.error('Ошибка получения сообщений:', error);
    }
  };

  const markMessagesAsRead = async (messageIdList) => {
    const apiUrl = `${Andrey3Https}/chat/chat/${chatId}/read-message?session_id=${sessionId}`;
    try {
      await fetch(apiUrl, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message_id_list: messageIdList }),
      });
    } catch (error) {
      console.error('Ошибка пометки сообщений как прочитанных:', error);
    }
  };

  const handleSendMessage = () => {
    if (!inputValue.trim()) return;

    const text = inputValue.trim();
    const apiUrl = `${Andrey3Https}/chat-bot/chat/${chatId}/send-message?session_id=${sessionId}`;
    fetch(apiUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ message_text: text, parent_message_id: 0 }),
    })
      .then((response) => response.json())
      .then((message) => {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            sender: 'user',
            content: message.text,
            messageId: message.message_id,
          },
        ]);
        setInputValue('');
        setIsTyping(true);
      })
      .catch((error) => console.error('Ошибка отправки сообщения:', error));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const intervalId = setInterval(fetchMessages, 5000);
    return () => clearInterval(intervalId);
  }, [chatId]);

  return (
    <div>
      <div
        className="chat-widget-circle"
        onClick={() => {
          setIsChatOpen(!isChatOpen);
          if (!isChatOpen) {
            fetchMessages();
          }
        }}
      >
        Чат
      </div>

      {isChatOpen && (
        <div className="chat-modal">
          <div className="chat-modal-header">
            <span>Чат с ботом "{chatName}"</span>
            <button className="close-btn" onClick={handleKillChat}>
              &times;
            </button>
          </div>

          <div className="chat-messages">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`chat-message ${
                  message.sender === 'bot' ? 'bot-message' : 'user-message'
                }`}
              >
                <div className="message-content">{message.content}</div>
              </div>
            ))}
            {isTyping && (
              <div className="chat-typing-indicator">
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>

          <div className="chat-input">
            <input
              type="text"
              placeholder="Введите сообщение..."
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleKeyPress}
            />
            <button onClick={handleSendMessage}>Отправить</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatWidget;
