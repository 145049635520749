import customHeaders, { getSpace } from '../../common/headers';
import refresh_icon from './img/refresh_icon.svg';
import { useDataTable } from '../../common/hooks';
import { FilterContainer } from '../FilterContainer/FilterContainer';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import {
  DropDownSelector,
  SearchComponent,
  ButtonBasic,
  TableBox,
} from '@lk-gtcom/ecomlab-components';
import {
  fetchMPApiFilter,
  fetchMPCategoryFilter,
  fetchMPBrandFilter,
} from '../../common/filtersFunc';
import {
  KonstantinCreateProductHttps,
  KonstantinMasterHttps,
  KonstantinMasterHttp,
} from '../../fetchUrls';
import useGeneralStore from '../../store/general';
import './SetPriceAndMargin.scss';

const SetPriceAndMargin = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [apiData, setApiData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);

  const [apiPage, setApiPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);

  const [apiQuery, setApiQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');

  const [selectedApi, setSelectedApi] = useState([
    { label: 'Все', value: 'all' },
  ]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchData, setSearchData] = useState([]);

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [dateFrom, dateTo] = date;
  const [isDisabledBtn, setIsDisabledBtn] = useState(true);

  const paginatorRef = useRef();
  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { setLoading, setFetchedData, setHeaders, setSort } = setTableFuncs;
  const [isClear, setIsClear] = useState(false);

  const fetchTableData = (params, abortController = new AbortController()) => {
    let sort;
    let filtersParam;

    if (params) {
      [sort, filtersParam] = params;
    }

    setLoading(true);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/mp-products/tables/prices${
        sort?.length > 0
          ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
          : ''
      }`;
    } else {
      url = `${KonstantinMasterHttp}/mp-products/tables/prices${
        sort?.length > 0
          ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
          : ''
      }`;
    }

    let api_id_list;
    let brand_id_list;
    let category_id_list;
    let offer_id_name_search = searchValue;

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi?.map((el) => el?.value);
    }

    const body = JSON.stringify({
      category_id_list,
      brand_id_list,
      api_id_list,
      page: paginatorRef.current.page,
      limit: paginatorRef.current.limit,
      filters: filtersParam,
      offer_id_name_search,
    });

    // const body = JSON.stringify({
    //     page: paginatorRef.current.page,
    //     limit: paginatorRef.current.limit,
    //     filters: filtersParam,
    //     api_id_list: selectedApi?.map(el => el?.value)
    // })

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setTable(json);
        setTableUrl(url);
        setTableBody(body);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setLoading(false);
        }
      });
  };

  const fetchSearch = (
    [search, page = 1],
    abortController = new AbortController()
  ) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/mp-products/filters/offer-id-name`;
    } else {
      url = `${KonstantinMasterHttp}/mp-products/filters/offer-id-name`;
    }

    // let [date_from, date_to] = date
    // date_from = new Date(date_from+10)?.toISOString().split('T')?.[0]
    // date_to = new Date(date_to)?.toISOString().split('T')?.[0]

    let api_id_list;
    let brand_id_list;
    let category_id_list;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi?.map((el) => el.value);
    }

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    const body = JSON.stringify({
      api_id_list,
      category_id_list,
      brand_id_list,
      page: page,
      limit: 10,
      search_param: search,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        let result = [];
        if (json) {
          result = json?.map(({ id, name, image }, ind) => {
            return { label: name, value: id, image: image };
          });
        }
        setSearchData(result);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const filtersApi = () => {
    fetchMPApiFilter({
      selectedSpace: selectedSpace?.value,
      page: apiPage,
      setApiData: (e) => setApiData(e),
      searchParam: apiQuery,
      withAll: false,
    });
  };

  const filtersCategory = () => {
    fetchMPCategoryFilter({
      selectedSpace: selectedSpace?.value,
      page: categoryPage,
      apiData,
      dateFrom,
      dateTo,
      brandData,
      selectedBrand,
      selectedApi,
      setCategoryData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
    });
  };

  const filtersBrand = () => {
    fetchMPBrandFilter({
      selectedSpace: selectedSpace?.value,
      page: brandPage,
      apiData,
      categoryData,
      selectedCategory,
      dateFrom,
      dateTo,
      searchParam: brandQuery,
      selectedApi,
      setBrandData: (e) => setBrandData(e),
    });
  };

  useEffect(() => {
    filtersApi();
  }, [apiPage, apiQuery]);

  useEffect(() => {
    filtersCategory();
  }, [
    // apiData,
    isClear,
    dateFrom,
    dateTo,
    categoryPage,
    categoryQuery,
  ]);

  useEffect(() => {
    filtersBrand();
  }, [
    isClear,
    dateFrom,
    dateTo,
    //  categoryData,
    brandPage,
    brandQuery,
  ]);

  const fetchTable = () => {
    const abortController = new AbortController();
    if (selectedApi?.length > 0) {
      fetchTableData([], abortController);
    }

    return () => abortController.abort();
  };

  const fetchOnePrice = (
    mpId,
    apiId,
    offerId,
    newPrice,
    setStatus,
    colName
  ) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinCreateProductHttps}/mp_products/prices`;
    } else {
      url = `${KonstantinCreateProductHttps}/mp_products/prices`;
    }

    const prices = [
      {
        obj_to_change: colName === 'Цена' ? 'price' : 'old_price',
        offer_id: offerId,
        ...(colName === 'Цена' ? { price: newPrice } : { old_price: newPrice }),
      },
    ];

    const body = JSON.stringify({
      mp_id: mpId,
      api_id: apiId,
      prices,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          setStatus('success');
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        // const abortController = new AbortController();
        // fetchTableData([], abortController);
        // return () => abortController.abort();
      })
      .catch((err) => {
        setStatus('error');
        console.error(err);
      });
  };

  const handleSaveOnePrice = (e) => {
    const { inputEvent, actionEvent, setStatus } = e;
    const offerId = inputEvent?.id;
    const colName = actionEvent?.[2];
    const mpId = actionEvent?.[0]?.mp_id;
    const apiId = actionEvent?.[0]?.api_id;

    let newPrice;

    newPrice = +inputEvent?.value;

    if (newPrice) {
      setStatus('loading');
      fetchOnePrice(mpId, apiId, offerId, newPrice, setStatus, colName);
    } else {
      setStatus('error');
    }
  };

  return (
    <div className="set-price-and-margin-master-product">
      <h1 className="title_main">Цены товаров</h1>

      <div className="toolbar-top">
        <FilterContainer
          onReset={(e) => {
            setSelectedApi([]);
            setSelectedCategory([]);
            setSelectedBrand([]);
            setIsClear(!isClear);
          }}
        >
          <DropDownSelector
            options_prop={apiData}
            state={selectedApi}
            setState={(e) => setSelectedApi(e)}
            placeholder="Площадка"
            multi
            fetchCallback={(e) => setApiPage(e)}
            setInputChange={(e) => setApiQuery(e)}
            onBlur={() => {
              filtersCategory();
              filtersBrand();
              fetchTable();
            }}
          />

          <DropDownSelector
            options_prop={categoryData}
            state={selectedCategory}
            setState={(e) => setSelectedCategory(e)}
            placeholder="Категория"
            multi={true}
            fetchCallback={(e) => setCategoryPage(e)}
            setInputChange={(e) => setCategoryQuery(e)}
            defaultValue={[{ label: 'Все', value: 'all' }]}
            onBlur={() => {
              filtersBrand();
              fetchTable();
            }}
          />

          <DropDownSelector
            options_prop={brandData}
            state={selectedBrand}
            setState={(e) => setSelectedBrand(e)}
            placeholder="Бренд"
            multi={true}
            fetchCallback={(e) => setBrandPage(e)}
            setInputChange={(e) => setBrandQuery(e)}
            defaultValue={[{ label: 'Все', value: 'all' }]}
            onBlur={() => {
              filtersCategory();
              fetchTable();
            }}
          />

          <SearchComponent
            searchValue={searchValue}
            errSearch={false}
            placeholder="Поиск товара"
            errText="Неверный товар"
            setSearchValue={setSearchValue}
            fetchData={fetchTableData}
            fetchCallback={fetchSearch}
            data={searchData}
            onClearDependence={[isClear]}
          />
        </FilterContainer>

        <ButtonBasic
          violet
          disabled={isDisabledBtn}
          text={
            <div className="content-btn">
              Обновить цены
              <img src={refresh_icon} />
            </div>
          }
          width="auto"
          size="40px"
        />
      </div>

      <TableBox
        {...tableProps}
        paginator={true}
        ref={paginatorRef}
        fetchCallback={fetchTableData}
        tableUrl={tableUrl}
        tableBody={tableBody}
        upload
        onInputAction={(e) => handleSaveOnePrice(e)}
      />
    </div>
  );
};

export { SetPriceAndMargin };
