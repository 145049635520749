import React from 'react'
import customHeaders, { getSpace } from '../../common/headers'
import add_icon from './img/add_icon.svg'
import card_img from './img/card_img.svg'
import info_icon from './img/info_icon.svg'
import { RavshanHttps } from '../../fetchUrls'
import { FilterContainer } from '../../components/FilterContainer/FilterContainer'
import { useState, useEffect } from 'react'
import { DropDownSelector, ButtonBasic, InputDynamicWidth } from "@lk-gtcom/ecomlab-components";
import { TooltipBlack } from '../../components/Tooltip/TooltipBlack/TooltipBlack'
import { ReactComponent as ArrowDown } from './img/arrow_down.svg'
import { ModalAddNewWiki } from '../../components/Modal/ModalAddNewWiki/ModalAddNewWiki'
import { ModalDeleteWiki } from '../../components/Modal/ModalDeleteWiki/ModalDeleteWiki'
import useGeneralStore from '../../store/general'
import "../../components/NetworkGroups/NetworkGroups.scss"
import './WikiList.scss'
import { useNavigate } from 'react-router-dom'

const btn_sort2 = [
    {
        label: <span>A<br />Z</span>,
        value: 'name'
    },
    {
        label: 'По дате создания',
        value: 'created_at'
    }
]

const CardGroup = ({ ...props }) => {
    const { name, created_at, id, onClick, setIsModalDelete, isDrop, setIsDrop, setId} = props


    return (
        <div className='card-item'
            onClick={onClick}
        >
            <div className='card-item__top-content'>
                <div className='card-item__header'>
                    <div className='statistic-tasks'>
                        <div className='statistic-tasks__item'>
                            Дата создания-
                            <span>
                                {created_at ? new Date(created_at)?.toLocaleDateString('ru-RU') : 'неизвестная дата'}
                            </span>
                        </div>
                    </div>

                    <div className='drop-and-btn'
                        onClick={(e) => {
                            e.stopPropagation()
                            setIsDrop(id)
                        }}
                    >
                        <button className='btn-points'>
                            <img src={info_icon} />
                        </button>
                        {(isDrop === id) && <div className='drop-content' style={{ gap: '.5em' }}>
                            <span className='drop-content__item'
                                onClick={e => {
                                    setIsModalDelete(true)
                                    e.stopPropagation()
                                    setIsDrop(-1)
                                    setId(id)
                                }}
                            >Удалить</span>
                            <span className='drop-content__item'
                                onClick={e => {
                                    // setIsModalDelete(true)
                                    e.stopPropagation()
                                    setIsDrop(-1)
                                    setId(id)
                                }}
                            >Изменить название</span>
                        </div>}
                    </div>
                </div>
            </div>


            <div className='card-item__medium-content'>
                <h3 className='title'>{name}</h3>
            </div>
        </div>

   
    )
}

const WikiList = ({ ...props }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }
    const { platforms = [], statuses = [], fetchFilters } = props
    const portalId = 'add-new-connection-portal'
    const [isModal, setIsModal] = useState(false)
    const [isModalDelete, setIsModalDelete] = useState(false)
    const [loading, setLoading] = useState(false)
    const [wikiList, setWikiList] = useState([])
    const [isDrop, setIsDrop] = useState(-1)
    const [id, setId] = useState(-1)
    const navigate = useNavigate()

    const [currentWiki, setCurrentWiki] = useState({})

    const [currentSort, setCurrentSort] = useState(null)
    const [sort, setSort] = useState(null)
    const [searchInput, setSearchInput] = useState('')

    const fetchWikiList = (signal = new AbortController(), delay = false) => {
        setLoading(true)
        const request = () => {
            const url = `${RavshanHttps}/api/v2/article/wiki/get`

            fetch(url, { method: 'GET', headers, signal })
                .then(res => {
                    if (!res?.ok) { throw new Error('bad response') }
                    return res.json()
                })
                .then(json => {
                    setWikiList(json)
                    
                })
                .catch(e => console.log(e))
                .finally(() => {
                    if (signal.aborted) return
                    setLoading(false)
                })
        }
        if (delay) {
            setTimeout(() => { request() }, delay)
        } else {
            request()
        }

    }

    const editWiki = (name, wiki_id) => {
        const url = `${RavshanHttps}/api/v2/article/wiki/create`

        const body = JSON.stringify({
            name,
            wiki_id
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => {
                if (!res?.ok) { throw new Error('bad response') }
                return res.json()
            })
            .then(json => {
                fetchWikiList()
            })
            .catch(e => console.log(e))
    }

    const onDeleteHandler = (id) => {
        const url = `${RavshanHttps}/api/v2/article/wiki/delete/${id}`

        const body = JSON.stringify({
            wiki_id: id
        })

        fetch(url, { body, method: 'DELETE', headers })
            .then(res => {
                if (!res?.ok) { throw new Error('bad response') }
                return res.json()
            })
            .then(() => {
                fetchWikiList()
            })
            .catch(e => console.log(e))
    }

    useEffect(() => {
        if (!isModal) {
            const abortController = new AbortController()

            fetchWikiList(abortController.signal, 100)

            return () => {
                abortController.abort()
            }
        }
    }, [isModal, selectedSpace, sort, currentSort, searchInput])

    useEffect(() => {
        const closeMenu = (e) => {
            let el = e.target
            if (!el.closest('.drop-and-btn')) {
                setIsDrop(null)
            }
        }
        document.addEventListener('click', closeMenu)

        return () => {
            document.addEventListener('click', closeMenu)
        }
    }, [])

    useEffect(() => {
        fetchWikiList()
    }, [selectedSpace]);

    return (
        <>
            {isModal && <ModalAddNewWiki
                setIsModal={(e) => setIsModal(e)}
                currentWiki={currentWiki}
                setCurrentWiki={setCurrentWiki}
                fetchWikiList={fetchWikiList}
            />}

            
            {isModalDelete && <ModalDeleteWiki
                setIsModal={(e) => setIsModalDelete(e)}
                id={id}
                setId={(e) => setId(e)}
                onDeleteHandler={(e) => onDeleteHandler(e)}
            />}

            <div id={portalId} className='network-groups'>
                <h1 className='title_main'>Мои wiki</h1>
                <div className='network-groups__toolbar-top'>
                    <ButtonBasic
                        width='150px'
                        onClick={(e) => setIsModal(true)}
                        text={<div className='btn-content'>Создать wiki <img src={add_icon} /></div>}
                    />

                    <div className='tab-sort'>
                        {btn_sort2.map((el) =>
                            <button className={currentSort?.label == el.label ? 'btn_active' : 'btn'}
                                key={el.label}
                                onClick={(e) => {
                                    if (currentSort?.label == el.label) {
                                        setSort(sort == 'asc' ? 'desc' : 'asc')
                                    } else setSort('asc')
                                    setCurrentSort(el)
                                }}
                            >{el.label}
                                <ArrowDown
                                    className={(currentSort?.label == el?.label) ? (sort == 'asc' ? 'arrow' : 'arrow_up') : 'arrow'}
                                    style={(currentSort?.label == el?.label) ? { stroke: 'white' } : { stroke: '#64748B' }}
                                />
                            </button>
                        )}
                    </div>

                    <InputDynamicWidth
                        position='left'
                        maxWidth='100%'
                        isOpen={true}
                        onChange={value => {
                            setSearchInput(value)
                        }}
                        placeholder='Поиск'
                    />
                </div>

                <div className='card-group'>
                    {wikiList?.map((props, ind) => {
                        const { id, name } = props
                        return (
                            <CardGroup
                                key={id}
                                {...props}
                                onClick={e => {
                                    localStorage.setItem('wiki_id', id)
                                    localStorage.setItem('wiki_name', name)
                                    navigate('/wiki/main/articles')
                                }}
                                setId={setId}
                                setIsDrop={setIsDrop}
                                isDrop={isDrop}
                                setIsModalDelete={e => setIsModalDelete(true)}
                            />
                        )
                    }
                    )}
                </div>

            </div >
        </>

    )
}

export default WikiList 