import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import "./ProfileSettings.scss"

const ProfileSettings = ({ onLogOutHandler }) => {

    const navigate = useNavigate();

    return (
        <div className='profile-settings'>
            <button className='profile-settings__btn' onClick={(e) => navigate(`/settings/space`)}> Настройки </button>
            <button className='profile-settings__btn'>Язык</button>
            <button className='profile-settings__btn profile-settings__btn_exit' onClick={e => onLogOutHandler(e)}>Выход</button>
        </div>
    )
}

export { ProfileSettings }