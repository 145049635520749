import all_tab from './img/all_tab.svg';
import participate_tab from './img/participate_tab.svg';
import not_participate_tab from './img/not-participate_tab.svg';
import { TabsGreyBtn } from '../TabsGreyBtn/TabsGreyBtn';
import { CardDiscount } from '../CardDiscount/CardDiscount';
import { useParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { OlegActionsHttps, OlegHttps } from '../../fetchUrls';
import {
  TableBox,
  Tabs,
  ButtonBasic,
  ModalCsv,
} from '@lk-gtcom/ecomlab-components';
import useGeneralStore from '../../store/general';
import './JoinedPromotions.scss';
import { ModalAlert } from '../Modal/ModalAlert/ModalAlert';
import customHeaders, { getSpace } from '../../common/headers';
import FilePromotionModal from '../Modal/FilePromotionModal/FilePromotionModal';
import usePushStore from '../../store/toast.js';

const btn_tab = [
  {
    label: 'Все',
    value: 'all',
    icon: all_tab,
  },
  {
    label: 'Участвуют',
    value: 'participate',
    icon: participate_tab,
  },
  {
    label: 'Не участвуют',
    value: 'not_participate',
    icon: not_participate_tab,
  },
];

const discounts = [
  {
    label: 'Все',
    value: null,
  },
  {
    label: '0 %',
    value: '0',
  },
  {
    label: '0-5 %',
    value: '0_5',
  },
  {
    label: '5-10 %',
    value: '5_10',
  },
  {
    label: '10-15%',
    value: '10_15',
  },
  {
    label: '15% и более',
    value: '15',
  },
];

const MAX_FILES_PER_DAY = 1;

const JoinedPromotions = ({
  selectedApi,
  selectedCategory,
  selectedBrand,
  selectedAction,
  check,
}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [pageValue, setPageValue] = useState('all');
  const [tableData, setTableData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [columnSize, setColumnSize] = useState([]);
  const [filters, setFilters] = useState([]);
  const [checkedProducts, setCheckedProducts] = useState([]);
  const [productAction, setProductAction] = useState(false);
  const [metricsData, setMetricsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState({});
  const [currentDiscount, setCurrentDiscount] = useState(discounts[0].value);
  const paginatorRef = useRef();
  const activeTab = useParams()['*'];

  const [isModalFilter, setIsModalFilter] = useState(false);
  const [filterValue, setFilterValue] = useState('');

  const [isPromoModalOpen, setIsPromoModalOpen] = useState(false);

  useEffect(() => {
    setPageValue(activeTab);
    setCheckedProducts([]);
  }, [activeTab]);

  useEffect(() => {
    setCheckedProducts([]);
  }, [pageValue]);

  useEffect(() => {
    setCheckedProducts([]);
    setTableData([]);
    paginatorRef.current?.reset();
    fetchTable();
    fetchMetrics();
  }, [
    selectedApi,
    selectedCategory,
    selectedBrand,
    selectedAction,
    pageValue,
    check,
    currentDiscount,
  ]);

  const fetchTable = (params = [], signal = new AbortController().signal) => {
    if (selectedApi?.length) {
      setLoading(true);
      let sort, filters;
      if (params) {
        [sort, filters] = params;
      }
      setTableData([]);
      setCheckedProducts([]);
      const url = `${OlegHttps}/api/v2/marketing/action/product/table${
        sort?.length > 0
          ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
          : ''
      }`;

      let api_id_list = selectedApi?.map((el) => el?.value);
      let brand_id_list = selectedBrand?.map((el) => el?.value);
      let category_id_list = selectedCategory?.map((el) => el?.value);
      let current_status =
        btn_tab.find((el) => el?.value === pageValue)?.value || '';
      let filtersOffer;
      if (filterValue?.length > 0) {
        filtersOffer = ['offer_id', 'text', '=', filterValue?.join(',')];
      }

      const body = JSON.stringify({
        discount: currentDiscount,
        category_id_list,
        brand_id_list,
        api_id_list,
        status: current_status === 'all' ? null : current_status,
        page: paginatorRef.current?.page,
        limit: paginatorRef.current?.limit,
        filters,
        offer_id_list: filtersOffer,
        action_id_list: selectedAction?.map((el) => String(el?.value)),
        order_param: sort?.[0],
        order_param_direction: sort?.[1],
        with_stock: check,
      });

      fetch(url, { body, method: 'POST', headers, signal })
        .then((res) => res?.json())
        .then((json) => {
          const { total, labels, data, sort, filters, column_size } = json;
          if (labels) setTableHeaders(labels);
          if (data) setTableData(data);
          if (sort) setSort(sort);
          if (column_size) setColumnSize(column_size);
          if (filters) setFilters(filters);
          if (total) paginatorRef.current?.setTotal(total);
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    }
  };

  const fetchMetrics = () => {
    setMetricsData([]);
    discounts.forEach((discount) => fetchBlock(discount));
  };

  const fetchBlock = (discount, signal = new AbortController().signal) => {
    const { label, value } = discount;
    if (selectedApi?.length) {
      const url = `${OlegHttps}/api/v2/marketing/action/product/block`;

      let api_id_list = selectedApi?.map((el) => el?.value);
      let brand_id_list = selectedBrand?.map((el) => el?.value);
      let category_id_list = selectedCategory?.map((el) => el?.value);
      let action_id_list = selectedAction?.map((el) => el?.value);

      const body = JSON.stringify({
        category_id_list,
        brand_id_list,
        api_id_list,
        discount: value,
        action_id_list,
        with_stock: check,
      });

      fetch(url, { body, method: 'POST', headers })
        .then((res) => res.json())
        .then((json) => {
          const { labels, data } = json;
          const { header } = labels;
          const dataObj = { value, data, header, title: label };
          // setMetricsData((prev) => [...prev, dataObj]);
          setMetricsData((prev) => {
            if (!prev.some((item) => item.value === value)) {
              return [...prev, dataObj];
            }
            return prev;
          });
        })
        .catch((err) => console.error(err));
      // .finally(() => setLoading(false));
    }
  };

  const onProductAction = (json) => {
    setCheckedProducts([]);
    setProductAction(false);
    fetchTable();
  };

  const onAddAllProductToAction = () => {
    if (selectedApi?.length > 0) {
      const url = `${OlegActionsHttps}/api/v1/promotion/all/add`;
      setProductAction(true);
      const body = JSON.stringify({
        discount: currentDiscount || '0',
        category_id_list: selectedCategory.map((el) => el?.value),
        brand_id_list: selectedBrand.map((el) => el?.value),
        api_id_list: selectedApi.map((el) => el?.value),
        action_id_list: selectedAction?.map((el) => String(el?.value)),
        with_stock: check,
      });

      fetch(url, {
        body,
        method: 'POST',
        headers,
      })
        .then((res) => res.json())
        .then((json) => {
          onProductAction(json);
          addSuccessPush();
        })
        .catch((err) => {
          console.error(err);
          addSuccessPush();
        });
      // .finally(() => setLoading(false));
    }
  };

  const onRemoveAllProductFromAction = () => {
    if (
      selectedApi?.length ||
      selectedBrand?.length ||
      selectedCategory?.length
    ) {
      const url = `${OlegActionsHttps}/api/v1/promotion/all/remove`;
      setProductAction(true);

      const body = JSON.stringify({
        discount: currentDiscount || '0',
        category_id_list: selectedCategory.map((el) => el?.value),
        brand_id_list: selectedBrand.map((el) => el?.value),
        api_id_list: selectedApi.map((el) => el?.value),
        action_id_list: selectedAction?.map((el) => String(el?.value)),
        with_stock: check,
      });

      fetch(url, {
        body,
        method: 'POST',
        headers,
      })
        .then((res) => res.json())
        .then((json) => {
          onProductAction(json);
        })
        .catch((err) => console.error(err));
      // .finally(() => setLoading(false));
    }
  };

  const onAddProductToAction = () => {
    if (selectedApi?.length) {
      const url = `${OlegActionsHttps}/api/v1/promotion/add`;
      setProductAction(true);

      let actionsObj = {};

      checkedProducts?.forEach((el) => {
        const { product_id, action_id, api_id } = el;
        const action_price = el?.max_action_price;

        if (!actionsObj[api_id]) {
          actionsObj[api_id] = [];
        }

        const existingAction = actionsObj[api_id].find(
          (action) => action.action_id === action_id
        );
        if (existingAction) {
          existingAction.products.push({ action_price, product_id });
        } else {
          actionsObj[api_id].push({
            action_id,
            products: [{ action_price, product_id }],
          });
        }
      });

      const apiIds = Object.keys(actionsObj);

      if (apiIds.length > 0) {
        const body = JSON.stringify({
          api_id: apiIds[0],
          actions: actionsObj[apiIds[0]],
        });

        fetch(url, {
          body,
          method: 'POST',
          headers,
        })
          .then((res) => {
            if (!res.ok) {
              throw new Error('Network response was not ok');
            }
            return res.json();
          })
          .then((json) => {
            onProductAction(json);
            addSuccessPush();
          })
          .catch((err) => {
            console.error('Error:', err);
            addSuccessPush();
          });
      } else {
        // setLoading(false);
      }
    }
  };

  const onRemoveProductFromAction = () => {
    if (selectedApi?.length) {
      const url = `${OlegActionsHttps}/api/v1/promotion/remove`;
      setProductAction(true);

      let actionsObj = {};
      checkedProducts?.forEach((el) => {
        const { product_id, action_id, api_id } = el;
        const productIdStr = String(product_id);

        if (!actionsObj[api_id]) {
          actionsObj[api_id] = {};
        }

        if (actionsObj[api_id][action_id]) {
          actionsObj[api_id][action_id].push(productIdStr);
        } else {
          actionsObj[api_id][action_id] = [productIdStr];
        }
      });

      const body = {
        api_id: String(Object.keys(actionsObj)[0]),
        actions: Object.keys(actionsObj[Object.keys(actionsObj)[0]]).map(
          (action_id) => ({
            action_id: String(action_id),
            products: actionsObj[Object.keys(actionsObj)[0]][action_id],
          })
        ),
      };

      fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error('Network response was not ok');
          }
          return res.json();
        })
        .then((json) => {
          onProductAction(json);
        })
        .catch((err) => console.error(err));
      // .finally(() => setLoading(false));
    }
  };

  const [fileId, setFileId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const incrementUploadCount = () => {
    const today = new Date().toISOString().split('T')[0];
    const uploadData = JSON.parse(localStorage.getItem('fileUploadData')) || {};
    const updatedData = {
      date: today,
      count: (uploadData.count || 0) + 1,
    };

    localStorage.setItem('fileUploadData', JSON.stringify(updatedData));
  };

  const checkUploadLimit = () => {
    const today = new Date().toISOString().split('T')[0];
    const uploadData = JSON.parse(localStorage.getItem('fileUploadData')) || {};

    if (uploadData.date !== today) {
      localStorage.setItem(
        'fileUploadData',
        JSON.stringify({ date: today, count: 0 })
      );
      return true;
    }

    return uploadData.count < MAX_FILES_PER_DAY;
  };

  const onAddFileClick = () => {
    if (!checkUploadLimit()) {
      addErrorPush('Вы можете загрузить только один файл в день.');
      return;
    }
    setIsPromoModalOpen(true);
  };

  const onUploadFileToPromotion = (file) => {
    if (!file) {
      return;
    }

    setIsLoading(true);

    const url = `${OlegActionsHttps}/api/v1/promotion/file/upload`;

    const formData = new FormData();
    formData.append('file', file);

    const updatedHeaders = { ...headers };
    delete updatedHeaders['Content-Type'];

    fetch(url, {
      method: 'POST',
      updatedHeaders,
      headers: updatedHeaders,
      body: formData,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Upload failed: ${res.statusText}`);
        }
        return res.json();
      })
      .then((data) => {
        incrementUploadCount();
        setFileId(data);
        console.log(data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setIsLoading(false));
  };

  const onAddAllFileProductsToPromotion = () => {
    if (!fileId) {
      return;
    }

    const url = `${OlegActionsHttps}/api/v1/promotion/file/all/add`;

    const body = JSON.stringify({
      file_id: fileId?.file_id,
    });

    fetch(url, {
      method: 'POST',
      headers,
      body,
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((error) => {
            throw new Error(
              `Add all products failed: ${error.detail || res.statusText}`
            );
          });
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);
        addSuccessPush();
      })
      .catch((err) => {
        console.error(err);
        addErrorPush();
      });
  };

  const onRemoveFileFromPromotion = () => {
    if (!fileId) {
      return;
    }

    const url = `${OlegActionsHttps}/api/v1/promotion/file/remove/${fileId?.file_id}`;

    fetch(url, {
      method: 'POST',
      headers,
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((error) => {
            throw new Error(`Remove failed: ${error.detail || res.statusText}`);
          });
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.error(err);
        addErrorPush();
      });
  };

  useEffect(() => {
    if (!isModalFilter) {
      fetchTable();
    }
  }, [isModalFilter]);

  const addPush = usePushStore((state) => state.addPush);

  const addErrorPush = (text = 'Это информационное сообщение') => {
    addPush({
      type: 'error',
      title: 'Ошибка',
      message: text,
      timeout: 3000,
    });
  };

  const addSuccessPush = () => {
    addPush({
      type: 'success',
      title: 'Информация',
      message: 'Товары успешно добавлены',
      timeout: 5000,
    });
  };

  return (
    <div className="joined-promotions">
      {isPromoModalOpen && (
        <FilePromotionModal
          fileId={fileId?.file_id}
          isLoading={isLoading}
          onClose={() => setIsPromoModalOpen(false)}
          onUploadFile={onUploadFileToPromotion}
          onAddProducts={onAddAllFileProductsToPromotion}
          onRemoveFile={onRemoveFileFromPromotion}
          onAddProductToAction={onAddProductToAction}
          setCheckedProducts={setCheckedProducts}
          setIsPromoModalOpen={setIsPromoModalOpen}
        />
      )}

      {isModalFilter && (
        <ModalCsv
          setIsModal={(e) => setIsModalFilter(e)}
          filterValue={filterValue}
          setFilterValue={(e) => setFilterValue(e)}
        />
      )}
      <section className="metrics-items">
        {metricsData.length > 0
          ? metricsData
              .sort((a, b) => {
                const aArr = a?.value?.split('_');
                const bArr = b?.value?.split('_');
                const sortRes = aArr[aArr?.length - 1] - bArr[bArr?.length - 1];
                if (sortRes === 0) {
                  return bArr?.length - aArr?.length;
                }
                return sortRes;
              })
              .map((el, i) => (
                <CardDiscount
                  key={i + el.title}
                  header={el.header}
                  data={el.data}
                  title={el.title}
                  period=""
                  loading={null}
                />
              ))
          : [...Array(5)].map((_, i) => (
              <CardDiscount
                key={i}
                data={[]}
                title=""
                period="7"
                loading={null}
              />
            ))}
      </section>

      <Tabs tabs={btn_tab} pageValue={pageValue} />

      <TableBox
        loading={loading}
        fetchedData={tableData}
        fetchCallback={(e) => fetchTable(e)}
        headers={tableHeaders}
        sort={sort}
        paginator={true}
        ref={paginatorRef}
        columnSize={columnSize}
        filters={filters}
        onCheck={(e) => {
          setCheckedProducts(e);
        }}
        onUncheckAllDependence={[pageValue, currentDiscount, productAction]}
        child={
          <div className="child-content">
            <TabsGreyBtn
              title="Скидка"
              btns={discounts}
              currentBtn={currentDiscount}
              setCurrentBtn={(e) => setCurrentDiscount(e)}
              checkValue={true}
            />
            {pageValue !== 'all' && (
              <>
                <ButtonBasic
                  action
                  text={
                    pageValue !== 'participate' ? 'Добавить все' : 'Убрать все'
                  }
                  size="32px"
                  maxWidth="140px"
                  width="140px"
                  onClick={() =>
                    pageValue !== 'participate'
                      ? onAddAllProductToAction()
                      : onRemoveAllProductFromAction()
                  }
                />
                <ButtonBasic
                  action
                  width="180px"
                  maxWidth="180px"
                  text={
                    pageValue !== 'participate'
                      ? 'Добавить выбранные'
                      : 'Убрать выбранные'
                  }
                  size="32px"
                  disabled={!checkedProducts.length}
                  onClick={() =>
                    pageValue !== 'participate'
                      ? onAddProductToAction()
                      : onRemoveProductFromAction()
                  }
                />
              </>
            )}
            <ButtonBasic
              width="auto"
              text={`Фильтрация по артикулу`}
              size="32px"
              onClick={(e) => setIsModalFilter(true)}
            />
            <ButtonBasic
              width="auto"
              text={`Добавить товары из файла`}
              size="32px"
              onClick={(e) =>
                // setIsPromoModalOpen(true);
                onAddFileClick()
              }
            />
          </div>
        }
      />
    </div>
  );
};

export { JoinedPromotions };
