import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import list_check from './img/bx-list-check.svg'
import list_ol from './img/bx-list-ol.svg'
import spreadsheet from './img/bx-spreadsheet.svg'
import calendar from './img/bx-calendar.svg'
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import { useRef } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import './PrintableVersion.scss'
import dayjs from 'dayjs';
const parse = require('html-react-parser')


const PrintableVersion = ({
    title,
    description,
    eventId,
    selectedStatusTask,
    selectedPriority,
    executors,
    coExecutors,
    selectedProject,
    endDate2,
    setIsPrint
}) => {

    const reportTemplateRef = useRef(null);

    const handleGeneratePdf = async () => {
        const canvas = await html2canvas(reportTemplateRef.current)
        const imgData = canvas.toDataURL("image/png")
        const doc = new jsPDF({
            format: 'a4',
            unit: 'px',
        });

        const width = doc.internal.pageSize.getWidth()
        const height = (canvas.height * width) / canvas.width
        doc.addImage(imgData, "PNG", 0, 0, width, height)
        doc.save('document')
    };

    const link = "https://example.com";

    const handleClick = () => {
        const subject = "Тема письма";
        const body = `Привет! Вот ссылка: ${link}`;
        // Создаем URL для открытия почтового клиента
        const mailtoLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        // Открываем почтовое приложение
        window.location.href = mailtoLink;
    };

    const handlePrint = () => {
        window.print();
    };

    const handleSendEmail = () => {
        const email = "example@example.com";  // Почтовый адрес получателя
        const subject = "Subject of the email";  // Тема письма
        const body = "Hello, this is the body of the email.";  // Текст письма

        // Создаем ссылку mailto, которая откроет почтовый клиент с заполненными полями
        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

        // Открытие почтового клиента
        window.location.href = mailtoLink;
    };

    return (
        <div className='printable-version__page'
            onClick={(e) => {
                let el = e.target
                if (el.classList.contains('printable-version__page')) {
                    setIsPrint(false)
                }
            }}
        >

            <div className='printable-version__group-btn'>
                <ButtonBasic
                    green
                    text='Печать'
                    onClick={() => handlePrint()}
                />

                {/* <ButtonBasic
                    green
                    text='Отправить на почту'
                    onClick={handleClick}
                /> */}



                {/* <ButtonBasic
                    green
                    text='Сгенерировать .pdf'
                    onClick={() => handleGeneratePdf()}
                /> */}
            </div>


            <div className='printable-version' id="printable-area" ref={reportTemplateRef}>
                <div className='printable-version__content'>
                    <div className='printable-version__row-sb'>
                        <div className='printable-version__row'>
                            {eventId && <div className='printable-version__task-id'><img src={spreadsheet} />{eventId}</div>}
                            {selectedProject?.label && <div className='printable-version__task-id'>{selectedProject?.label}</div>}
                        </div>

                        <div className='printable-version__row'>
                            {selectedProject?.label &&
                                <div className='printable-version__row'>
                                    <div className='printable-version__task-id'>
                                        <img src={calendar} />
                                        {(dayjs(endDate2)).format('DD.MM')}
                                    </div>
                                </div>}

                            <div className='printable-version__task-id_green'>
                                <p className='printable-version__text_green'>10 дней</p>
                            </div>
                        </div>
                    </div>

                    <div className='printable-version__row'>
                        <div className='printable-version__row'>
                            <img src={list_check} />
                            <div className='printable-version__task-id'>
                                <p>{selectedStatusTask?.label}</p>
                            </div>
                        </div>
                        <div className='printable-version__row'>
                            <img src={list_check} />
                            <div className='printable-version__task-id'>
                                <p>{selectedPriority?.label}</p>
                            </div>
                        </div>
                    </div>

                    <div className='printable-version__row'>
                        <img src={list_ol} />
                        <p className='printable-version__label'>Создатель</p>
                        <p className='printable-version__text'>{executors[0]?.name}</p>
                    </div>

                    <div className='printable-version__row'>
                        <img src={list_ol} />
                        <p className='printable-version__label'>Исполнитель</p>
                        <p className='printable-version__text'>{executors[0]?.name}</p>
                    </div>

                    <div className='printable-version__row'>
                        <img src={list_ol} />
                        <p className='printable-version__label'>Соисполнитель</p>
                        <p className='printable-version__text'>
                            {coExecutors.map(({ name }, ind) => {
                                return <>{name}{ind == coExecutors?.length - 1 ? '' : ', '}</>
                            })}
                        </p>
                    </div>

                    {title && <h2 className='printable-version__title'>{title}</h2>}
                    {description && <h2 className='printable-version__description'>{parse(description)}</h2>}
                </div>

            </div>
        </div>
    )
}

export { PrintableVersion }