import nodata from './img/nodata.svg';
import customHeaders, { getSpace } from '../../common/headers';
import spakler_icon from './img/spakler_icon.svg';
import { MoonLoader } from 'react-spinners';
import { useDebounce } from '../../hooks/useDebounce';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ModalProductImprovement } from '../../components/Modal/ModalProductImprovement/ModalProductImprovement';
import { ProductImprovement_EnhancePhoto } from '../ProductImprovement_EnhancePhoto/ProductImprovement_EnhancePhoto';
import { ProductImprovement_EnhanceVideo } from '../ProductImprovement_EnhanceVideo/ProductImprovement_EnhanceVideo';
import { ProductImprovement_EmojiAddition } from '../ProductImprovement_EmojiAddition/ProductImprovement_EmojiAddition';
import { ProductImprovement_CreateRICHContent } from '../ProductImprovement_CreateRICHContent/ProductImprovement_CreateRICHContent';
import {
  RavshanHttps,
  RavshanHttp,
  KonstantinMasterHttps,
  KonstantinMasterHttp,
} from '../../fetchUrls';
import {
  fetchMasterBrandFilter,
  fetchMasterCategoryFilter,
} from '../../common/filtersFunc';
import {
  DropDownSelector,
  ButtonBasic,
  Tabs,
  Checkbox,
  InputDynamicWidth,
  SearchComponent,
} from '@lk-gtcom/ecomlab-components';
import useGeneralStore from '../../store/general';
import './MassImprovement.scss';

const btn_tab_1 = [
  {
    label: 'Все',
    value: 'all',
  },
  {
    label: 'Плохое описание',
    value: 'bad_description',
  },
  {
    label: 'Без маппинга',
    value: 'no_mapping',
  },
  {
    label: 'Удаленные (до 30 дней)',
    value: 'deleted',
  },
];

const btn_tab_2 = [
  {
    label: 'Эмоджи',
    value: 'emoji',
  },
  {
    label: 'Улучшить фото',
    value: 'enhance_photo',
  },
  {
    label: 'Улучшить видео',
    value: 'enhance_video',
  },
  {
    label: 'Создать RICH content',
    value: 'create_RICH_content',
  },
];

const ProductItem = ({ id, img, title, product, brand, onClick, isActive }) => {
  return (
    <div
      className="product_list_item"
      onClick={onClick}
      style={isActive ? { border: '1.5px solid #66cff1' } : {}}
    >
      {img ? (
        <img
          src={img}
          alt="product image"
          className="product_list_item_image"
        />
      ) : (
        <img
          src={nodata}
          alt="product image"
          className="product_list_item_image"
        />
      )}
      <div className="product_list_item_info_container">
        <h3 className="product_list_item_header">{title}</h3>
        <p className="product_list_item_substr">{product}</p>
        <p className="product_list_item_substr">{brand}</p>
      </div>
    </div>
  );
};

const MassImprovement = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const navigate = useNavigate();
  const [isAll, setIsAll] = useState(false);
  const [isModalParams, setIsModalParams] = useState(false);
  const [pageValue1, setPageValue1] = useState(btn_tab_1?.[0]?.value);
  const [pageValue2, setPageValue2] = useState(btn_tab_2?.[0]?.value);
  const [productIds, setProductIds] = useState([]);
  const [isStatus, setIsStatus] = useState(false);
  const [statusKey, setStatusKey] = useState('');
  const [status, setStatus] = useState({});

  const [oldDesc, setOldDesc] = useState('');
  const [newDesc, setNewDesc] = useState('');

  const [oldImages, setOldImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [oldVideo, setOldVideo] = useState([]);
  const [newVideo, setNewVideo] = useState([]);

  const [productsId, setProductsId] = useState([]);

  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(-1);

  const [searchValue, setSearchValue] = useState('');
  const [searchData, setSearchData] = useState([]);

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [dateFrom, dateTo] = date;

  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);

  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);

  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');

  const componentsInContainer = {
    emoji: (
      <ProductImprovement_EmojiAddition oldDesc={oldDesc} newDesc={newDesc} />
    ),
    enhance_photo: (
      <ProductImprovement_EnhancePhoto
        oldImages={oldImages}
        newImages={newImages}
      />
    ),
    enhance_video: (
      <ProductImprovement_EnhanceVideo
        oldVideo={oldVideo}
        newVideo={newVideo}
      />
    ),
    create_RICH_content: (
      <ProductImprovement_CreateRICHContent
        // setIsModal={(e) => {
        //   // setIsModal(e)
        //   navigate('./');
        // }}
        product_id={selectedProduct}
      />
    ),
  };

  const fetchSearch = (
    [search, page = 1],
    abortController = new AbortController()
  ) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/master-products/filters/offer-id-name`;
    } else {
      url = `${KonstantinMasterHttp}/master-products/filters/offer-id-name`;
    }

    let brand_id_list;
    let category_id_list;

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }

    if (selectedCategory?.value) {
      category_id_list = [selectedCategory?.value];
    }

    const body = JSON.stringify({
      category_id_list,
      brand_id_list,
      page: page,
      limit: 10,
      search_param: search,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        if (page > 1) {
          setSearchData((prev) => [
            ...prev,
            ...json.map(({ id, name, image }) => ({
              label: name,
              extra: id,
              value: id,
              image,
            })),
          ]);
        } else {
          setSearchData(
            json.map(({ id, name, image }) => ({
              label: name,
              extra: id,
              value: id,
              image,
            }))
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const renderingItem = () =>
    componentsInContainer[pageValue2] || componentsInContainer.emoji;

  const getProductsByFilter = (brand_name_list, category_id, search) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/products/get_product_by_filter`;
    } else {
      url = `${RavshanHttp}/api/products/get_product_by_filter`;
    }

    const body = JSON.stringify({
      brand_name_list,
      category_id,
      search,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        const products = json.map(({ product_id, product_name }) => ({
          id: product_id,
          title: product_name,
        }));
        setProductList(products);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const debouncedGetProductsByFilter = useDebounce(getProductsByFilter, 500);

  const onSaveHandler = () => {
    saveNewDesc();
    saveNewImages();
    saveNewVideo();
    createNewVideo();
  };

  const fetchStatus = () => {
    const url = `${RavshanHttps}/api/products/get_improvement_progress`;

    const body = JSON.stringify({
      key: statusKey,
      name:
        pageValue2 === 'emoji'
          ? 'description'
          : pageValue2 === 'enhance_photo'
            ? 'photo'
            : 'video',
      ...(!isAll && { product_id: selectedProduct }),
    });

    fetch(url, { body, method: 'POST', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        // if (json?.current >= json?.total || json?.status !== 'в работе') {
        //   setIsStatus(false);
        //   setStatus({});
        //   setStatusKey('');
        // }
        if (
          (!isAll && json !== 'в работе') ||
          (isAll &&
            (json?.current >= json?.total || json?.status !== 'в работе'))
        ) {
          setIsStatus(false);
          setStatus({});
          setStatusKey('');
        }
        setStatus(json);
      })
      .catch((err) => console.error(err));
  };

  const getOldDesc = () => {
    if (pageValue2 === btn_tab_2[0].value) {
      const url = `${RavshanHttps}/api/products/get_old_product_description`;

      const body = JSON.stringify({
        product_id: selectedProduct,
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          setOldDesc(json);
        })
        .catch((err) => console.error(err));
    }
  };

  const getNewDesc = () => {
    if (pageValue2 === btn_tab_2[0].value) {
      const url = `${RavshanHttps}/api/products/get_new_product_description`;

      const body = JSON.stringify({
        product_id: selectedProduct,
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          setNewDesc(json ? json : '');
        })
        .catch((err) => {
          setNewDesc('');
          console.error(err);
        });
    }
  };

  const saveNewDesc = () => {
    if (pageValue2 === btn_tab_2[0].value) {
      const url = `${RavshanHttps}/api/products/save_new_description`;

      const body = JSON.stringify({
        product_id_list: [selectedProduct],
        new_description: newDesc,
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          console.log(json);
        })
        .catch((err) => console.error(err));
    }
  };

  const getOldImages = () => {
    if (pageValue2 === btn_tab_2[1].value) {
      const url = `${RavshanHttps}/api/products/get_product_images`;

      const body = JSON.stringify({
        product_id: selectedProduct,
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          setOldImages(json);
        })
        .catch((err) => console.error(err));
    }
  };

  const getNewImages = () => {
    if (pageValue2 === btn_tab_2[1].value) {
      const url = `${RavshanHttps}/api/products/get_new_product_images`;

      const body = JSON.stringify({
        product_id: selectedProduct,
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          setNewImages(json);
        })
        .catch((err) => {
          setNewImages([]);
          console.error(err);
        });
    }
  };

  const saveNewImages = () => {
    if (pageValue2 === btn_tab_2[1].value) {
      const url = `${RavshanHttps}/api/products/save_new_photo_product`;

      const body = JSON.stringify({
        product_id_list: [selectedProduct],
        new_description: newImages,
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          console.log(json);
        })
        .catch((err) => console.error(err));
    }
  };

  const getOldVideo = () => {
    if (pageValue2 === btn_tab_2[2].value) {
      const url = `${RavshanHttps}/api/products/get_old_video_product`;

      const body = JSON.stringify({
        product_id: selectedProduct,
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          setOldVideo(json);
        })
        .catch((err) => console.error(err));
    }
  };

  const getNewVideo = () => {
    if (pageValue2 === btn_tab_2[2].value) {
      const url = `${RavshanHttps}/api/products/get_new_video_product`;

      const body = JSON.stringify({
        product_id: selectedProduct,
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          setNewVideo(json);
        })
        .catch((err) => {
          setNewVideo([]);
          console.error(err);
        });
    }
  };

  const saveNewVideo = () => {
    if (pageValue2 === btn_tab_2[2].value) {
      const url = `${RavshanHttps}/api/products/save_new_video_product`;

      const body = JSON.stringify({
        product_id_list: [selectedProduct],
        new_description: newVideo,
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          console.log(json);
        })
        .catch((err) => console.error(err));
    }
  };

  const createNewVideo = () => {
    if (pageValue2 === btn_tab_2[2].value) {
      const url = `${RavshanHttps}/api/products/generate_new_product_video`;

      const body = JSON.stringify({
        product_id_list: [selectedProduct],
        // attribute_list: selectedAttributes,
        // emoji_code: selectedEmoji.join(''),
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          const { key } = json;
          setIsStatus(true);
          setStatusKey(key);
          // sessionStorage.setItem(productId, key)
          // setIsModal(false)
        })
        .catch((err) => console.error(err));
    }
  };

  useEffect(() => {
    if (selectedProduct) {
      getOldDesc();
      getNewDesc();
      getOldImages();
      getNewImages();
      getOldVideo();
      getNewVideo();
    }
  }, [selectedProduct, pageValue2]);

  useEffect(() => {
    if (statusKey?.length > 0) {
      fetchStatus();
      const interval = setInterval(() => {
        fetchStatus();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [statusKey, selectedSpace]);

  useEffect(() => {
    if (status?.status === 'готово') {
      if (pageValue2 === btn_tab_2[0].value) {
        getNewDesc();
      } else if (pageValue2 === btn_tab_2[1].value) {
        getNewImages();
      } else if (pageValue2 === btn_tab_2[2].value) {
        getNewVideo();
      }
    }
  }, [status]);

  useEffect(() => {
    const brandNameList = selectedBrand.length
      ? selectedBrand.map((brand) => brand.value)
      : ['all'];
    const categoryId = selectedCategory.value || 'all';
    const searchQuery = searchValue || '';
    debouncedGetProductsByFilter(brandNameList, categoryId, searchQuery);
  }, [searchValue, selectedSpace]);

  const filtersMasterCategory = () => {
    const abortController = new AbortController();
    fetchMasterCategoryFilter({
      abortController,
      selectedSpace: selectedSpace?.value,
      page: categoryPage,
      dateFrom,
      dateTo,
      brandData,
      selectedBrand,
      setCategoryData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
    });
    return () => abortController.abort();
  }

  const filtersMasterBrand = () => {
    const abortController = new AbortController();
    fetchMasterBrandFilter({
      abortController,
      selectedSpace: selectedSpace?.value,
      page: brandPage,
      categoryData,
      selectedCategory,
      dateFrom,
      dateTo,
      searchParam: brandQuery,
      setBrandData: (e) => setBrandData(e),
    });
    return () => abortController.abort();
  }

  useEffect(() => {
    filtersMasterCategory()
  }, [
    dateFrom,
    dateTo,
    categoryPage,
    categoryQuery
  ]);

  useEffect(() => {
    filtersMasterBrand()
  }, [
    dateFrom,
    dateTo,
    brandPage,
    brandQuery]);

  return (
    <div className="mass-improvement">
      {isModalParams && (
        <ModalProductImprovement
          page={pageValue2}
          setIsModal={setIsModalParams}
          productId={selectedProduct}
          setIsStatus={setIsStatus}
          setStatusKey={setStatusKey}
          productList={productList}
          isAll={isAll}
        />
      )}

      <h1 className="mass-improvement__title-main">
        Массовое улучшение товаров
      </h1>

      <div className="mass-improvement__toolbar">
        <DropDownSelector
          options_prop={categoryData}
          state={selectedCategory}
          setState={(e) => setSelectedCategory(e)}
          placeholder="Категория"
          multi={false}
          fetchCallback={(e) => setCategoryPage(e)}
          setInputChange={(e) => setCategoryQuery(e)}
          onBlur={(() => {
            filtersMasterBrand()
            const brandNameList = selectedBrand.length
              ? selectedBrand.map((brand) => brand.value)
              : ['all'];
            const categoryId = selectedCategory.value || 'all';
            const searchQuery = searchValue || '';
            getProductsByFilter(brandNameList, categoryId, searchQuery);
          })}
        />
        <DropDownSelector
          options_prop={brandData}
          state={selectedBrand}
          setState={(e) => setSelectedBrand(e)}
          placeholder="Бренд"
          multi={true}
          fetchCallback={(e) => setBrandPage(e)}
          setInputChange={(e) => setBrandQuery(e)}
          defaultValue={[{ label: 'Все', value: 'all' }]}
          onBlur={(() => {
            const brandNameList = selectedBrand.length
              ? selectedBrand.map((brand) => brand.value)
              : ['all'];
            const categoryId = selectedCategory.value || 'all';
            const searchQuery = searchValue || '';
            getProductsByFilter(brandNameList, categoryId, searchQuery);
          })}
        />

        <SearchComponent
          searchValue={searchValue}
          errSearch={false}
          placeholder="Поиск по артукулу или названию"
          errText="Неверный артикул или название"
          setSearchValue={setSearchValue}
          fetchCallback={(e) => {
            fetchSearch(e);
          }}
          data={searchData}
          callbackOnReset
        />

        <ButtonBasic
          width="150px"
          blue
          onClick={(e) => e}
          text={'Посмотреть товар'}
        />
      </div>

      <Tabs
        tabs={btn_tab_1}
        pageValue={pageValue1}
        setPageValue={(e) => setPageValue1(e)}
      />

      <div className="mass-improvement__content">
        <div className="toolbar">
          <Tabs
            tabs={btn_tab_2}
            pageValue={pageValue2}
            stopRedirect
            setPageValue={(e) => setPageValue2(e)}
          />
          <div style={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
            <ButtonBasic
              width="210px"
              grey
              onClick={(e) => setIsModalParams(true)}
              text={
                <p className="content-btn">
                  <img src={spakler_icon} />
                  Изменить параметры
                </p>
              }
            />
            <ButtonBasic
              green
              // style={{ marginTop: '12px' }}
              text="Сохранить"
              width="162px"
              onClick={(e) => onSaveHandler()}
            />
          </div>
        </div>
        <div className="list_container">
          <div className="product_list">
            <div className="product_list_header">
              <p className="product_list_header_text">
                Товаров: {productList?.length}
              </p>
              <p className="product_list_header_checkbox">
                <Checkbox onChange={(value) => setIsAll(value)} /> Для всех
              </p>
            </div>
            <div className="product_list_container">
              {productList?.map((el) => {
                const id = el?.id;
                return (
                  <ProductItem
                    key={id}
                    {...el}
                    isActive={selectedProduct === id}
                    onClick={(e) => setSelectedProduct(id)}
                  />
                );
              })}
            </div>
          </div>
          <div className="product-improvement-inside-content__changed-content">
            {!isStatus ? (
              selectedProduct !== -1 ? (
                renderingItem()
              ) : (
                <div className="nodata_wrapper">
                  <div className="container">
                    <h3 className="container_header">
                      Выберете товар из списка
                    </h3>
                    <p className="container_description">
                      Выберите товар из списка который вы хотите улучшить
                    </p>
                    <img
                      className="nodata_image"
                      src={nodata}
                      alt="no data image"
                    />
                  </div>
                </div>
              )
            ) : (
              <div className="status_wrapper">
                <div className="container">
                  <div className="spinner_container">
                    <MoonLoader color="#7b7b7b" speedMultiplier={0.5} />
                    {/* <div className="counter">
                      {status?.current} из{' '}
                      <span className="status_total">{status?.total}</span>
                    </div> */}
                    {isAll ? (
                      <>
                        {status?.current} из{' '}
                        <span className="status_total">{status?.total}</span>
                      </>
                    ) : (
                      status === 'в работе' && <span>{status}</span>
                    )}
                  </div>
                  <h3 className="container_header">≈40 минут</h3>
                  <p className="container_description">
                    по завершению мы вас оповестим, можете заняться своими
                    делами
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { MassImprovement };
