import { useState, useEffect } from 'react';
import { useRef } from 'react';
import { DenisCrmHttps } from '../../fetchUrls';
import customHeaders, { getSpace } from '../../common/headers';
import { useNavigate, useParams } from 'react-router-dom';
import { useDataTable } from '../../common/hooks';
import useGeneralStore from '../../store/general';
import './CrmTablesList.scss';

import {
  Tabs,
  TableBox,
  ButtonBasic,
  DropDownSelector,
} from '@lk-gtcom/ecomlab-components';
import { CrmModal } from '../CrmCreateTable/CrmModal';

const CrmTabs = [
  {
    label: 'Все',
    value: 'all',
  },
  {
    label: 'Активные',
    value: 'notifications',
  },
  {
    label: 'Неактивные',
    value: 'unactiv',
  },
];

const CrmTablesList = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const navigate = useNavigate();
  const [tableList, setTableList] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [pageValue, setPageValue] = useState(null);
  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { loading, sort, filters } = tableProps;
  const { setLoading, setFetchedData, setHeaders, setSort, setFilters } =
    setTableFuncs;

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);

  const contextMenuArr = [
    {
      label: 'Изменить',
      onClick: ({ uid }) => {
        setSelectedRowId(uid);
        setIsModalOpen(true);
      },
    },
    {
      label: 'Удалить',
      onClick: ({ uid }) => {
        onDeleteHandler(uid);
      },
    },
  ];

  //таблица таблиц
  const fetchTableData = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${DenisCrmHttps}/api/custom_table/get_custom_table_list`;
    } else {
      url = `${DenisCrmHttps}/api/custom_table/get_custom_table_list`;
    }

    const body = JSON.stringify({
      limit: paginatorRef.current.limit,
      page: paginatorRef.current.page,
    });

    fetch(url, { method: 'POST', headers, body })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('Error: get_tables_list request');
        }
      })
      .then((json) => {
        setTable(json);
        setTableUrl(url);
        setTableBody(body);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  //удаление таблицы
  const onDeleteHandler = (table_uid) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${DenisCrmHttps}/api/custom_table/delete_custom_table`;
    } else {
      url = `${DenisCrmHttps}/api/custom_table/delete_custom_table`;
    }

    const body = JSON.stringify({
      table_uid,
    });

    fetch(url, { body, method: 'DELETE', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('error: delete_table request');
        }
      })
      .then((json) => {
        alert(json);
        fetchTableData();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const activeTab = useParams()['*'];
  useEffect(() => {
    setPageValue(activeTab);
  }, [activeTab]);

  const onRedirect = (e) => {
    window.open(e[1], '_blank');
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  return (
    <div className="CRM">
      <h2 className="title_main">Список таблиц</h2>

      <div className="toolbar-top">
        <DropDownSelector
          className="connections_page_selector"
          placeholder="Фильтр"
          options_prop={[]}
          multi
        />
        <DropDownSelector
          className="connections_page_selector"
          placeholder="Фильтр"
          multi
          options_prop={[]}
        />
        <DropDownSelector
          className="connections_page_selector"
          placeholder="Фильтр"
          multi
          options_prop={[]}
        />

        <ButtonBasic
          text={'Создать таблицу'}
          onClick={() => setIsModalOpen(true)}
        />
      </div>

      <Tabs
        tabs={CrmTabs}
        pageValue={pageValue}
        setPageValue={(e) => setPageValue(e)}
      />

      <div style={{ minWidth: '100wh' }}>
        <TableBox
          {...tableProps}
          ref={paginatorRef}
          paginator={true}
          fetchCallback={(e) => fetchTableData(e)}
          contextMenuArr={contextMenuArr}
          onRedirect={onRedirect}
        />
      </div>

      <CrmModal
        isOpen={isModalOpen}
        tableId={selectedRowId}
        setTableId={setSelectedRowId}
        fetchTableData={(e) => fetchTableData(e)}
        close={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export { CrmTablesList };
