import { useState } from 'react'
import { TableBox, DropContainer } from "@lk-gtcom/ecomlab-components"
import "./ProductCardExtended_AdvertisingCompanies.scss"

const ProductCardExtended_AdvertisingCompanies = ({
        tableData,
        tableCallback,
        paginatorRef
    }) => {

    return (
        <section className='advertising-companies-inside-content'>
            <TableBox
                {...tableData}
                fetchCallback={tableCallback}
                sortingFunc={tableCallback}
                paginator={true}
                ref={paginatorRef}
            />
        </section>
    )
}

export { ProductCardExtended_AdvertisingCompanies }