import React, { useEffect } from 'react';
import './PushNotifications.scss';
import { shallow } from 'zustand/shallow';
import usePushStore from '../../store/toast';

const PushNotifications = () => {
  const pushes = usePushStore((state) => state.pushes, shallow);
  const removePush = usePushStore((state) => state.removePush);

  useEffect(() => {
    const timers = pushes.map((push) =>
      setTimeout(() => removePush(push.id), push.timeout || 5000)
    );
    return () => timers.forEach((timer) => clearTimeout(timer));
  }, [pushes, removePush]);

  return (
    <div className="push-container">
      {pushes.map((push) => (
        <div
          key={push.id}
          className={`push push-${push.type}`}
          onClick={() => push.onClick && push.onClick()}
        >
          <div className="push-content">
            <h4>{push.title}</h4>
            <p>{push.message}</p>
          </div>
          <button
            className="push-close"
            onClick={(e) => {
              e.stopPropagation();
              removePush(push.id);
            }}
          >
            ✖
          </button>
        </div>
      ))}
    </div>
  );
};

export default PushNotifications;
