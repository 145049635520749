import { useEffect, useState, useCallback, useRef } from 'react';
import { Routes, Route, useLocation, useSearchParams } from 'react-router-dom';
import { FilterContainer } from '../../components/FilterContainer/FilterContainer';
import { KonstantinMasterHttps } from '../../fetchUrls';
import { useChartData } from '../../common/hooks';
import { useMemo } from 'react';
import { DropDownSelector } from '@lk-gtcom/ecomlab-components';
import LineChart from '../../charts/LineChart';
import {
  fetchApiReviewsFilter,
  fetchFeedbacksBrandFilter,
  fetchFeedbacksCategoryFilter,
} from '../../common/filtersFunc';
import { Tabs, DropContainer } from '@lk-gtcom/ecomlab-components';
import useGeneralStore from '../../store/general';
import customHeaders from '../../common/headers';
import './Anomalies.scss';
import { OlegHttps } from '../../fetchUrls';
import { WrapperDoughnutChart } from '../../charts/WrapperCharts/WrapperDoughnutChart/WrapperDoughnutChart';
import { WrapperLineChart } from '../../charts/WrapperCharts/WrapperLineChart/WrapperLineChart';
import {
  addFiltersByDataObj,
  addFiltersToUrlQuery,
  getDataObjByFilters,
  getFiltersByUrlQuery,
} from '../../common/utils';

const options_prop = [
  { label: 'Ozon', value: 1 },
  { label: 'Wildberries', value: 3 },
  { label: 'Yandex', value: 2 },
];

const fetchFilterDataForReviews = async ({
  filterName,
  selectedSpace,
  page = 1,
  searchParam = '',
  selectedFilters = {},
  setFilterData,
  setSelectedFilter,
  append = false,
}) => {
  const url = `${KonstantinMasterHttps}/v3/filter/${filterName}`;

  const formatDate = (timestamp) => {
    if (!timestamp || isNaN(timestamp)) {
      return null;
    }
    const date = new Date(timestamp);
    return date.toISOString().split('T')[0];
  };

  const getFilterList = (filterArray) =>
    filterArray?.length > 0 ? filterArray.map((item) => item.value) : ['all'];

  const selectedIds =
    selectedFilters.selected_id && selectedFilters.selected_id.length > 0
      ? selectedFilters.selected_id.flatMap((id) =>
          typeof id === 'string' ? id.split(';') : id
        )
      : [];

  const payload = {
    search_param: searchParam || null,
    page,
    limit: 50,
    api_id_list: getFilterList(selectedFilters.api),
    category_id_list: getFilterList(selectedFilters.category),
    brand_id_list: getFilterList(selectedFilters.brand),
    mp_id_list: getFilterList(selectedFilters.marketplace),
    product_valuation_list: getFilterList(selectedFilters.rating),
    date_from: formatDate(selectedFilters.dateFrom),
    date_to: formatDate(selectedFilters.dateTo),
    selected_id_list: selectedIds || [],
  };

  const headers = {
    ...customHeaders,
    space: selectedSpace,
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      const data = await response.json();

      const formattedData = data.filter_data?.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setFilterData((prevData) =>
        append ? [...prevData, ...formattedData] : formattedData
      );

      if (data.selected?.length) {
        const selectedItems = data.selected.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        setSelectedFilter(selectedItems);
      }
    } else {
      console.log(response.statusText);
    }
  } catch (error) {
    console.log(error);
  }
};

const tabs = [
  {
    label: 'По товарам',
    value: 'by-product',
  },
  {
    label: 'По категории',
    value: 'by-category',
  },
  {
    label: 'По бренду',
    value: 'by_brand',
  },
  {
    label: 'По категоризации',
    value: 'by-categorization',
  },
];

const Anomalies = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const [apiData, setApiData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [actionData, setActionData] = useState([]);
  const [selectedMarketplace, setSelectedMarketplace] = useState([
    { label: 'Все', value: 'all' },
  ]);
  const [selectedApi, setSelectedApi] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedAction, setSelectedAction] = useState([]);

  const [apiPage, setApiPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);
  const [actionPage, setActionPage] = useState(1);

  const [apiQuery, setApiQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');
  const [actionQuery, setActionQuery] = useState('');

  const [metrics, setMetrics] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState(null);

  const [pageValue, setPageValue] = useState(tabs[0]?.value);

  const [setChartData, chartData, setChartFuncs] = useChartData();
  const { setChartLoading, resetChart } = setChartFuncs;
  const [showDropBox, setShowDropBox] = useState(true);

  const [iniitalQuery, setInitialQuery] = useState(true);
  const [defaultDate, setDefaultDate] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);

  const [dateFrom, dateTo] = date;

  useEffect(() => {
    if (iniitalQuery) {
      const dataObj = getFiltersByUrlQuery(searchParams);
      addFiltersByDataObj({
        dataObj,
        setSelectedMarketplace,
        setSelectedApi,
        setSelectedCategory,
        setSelectedBrand,
        setDate: setDefaultDate,
      });
      setInitialQuery(false);
    }
  }, [searchParams]);

  useEffect(() => {
    if (iniitalQuery) return;
    const dataObj = getDataObjByFilters({
      selectedMarketplace,
      selectedApi,
      selectedBrand,
      selectedCategory,
    });
    addFiltersToUrlQuery(dataObj, setSearchParams);
  }, [selectedApi, selectedBrand, selectedCategory]);

  // const actionParamRef = useRef([]);
  // const apiParamRef = useRef([]);

  useEffect(() => setApiPage(1), [selectedMarketplace]);
  useEffect(() => setCategoryPage(1), [selectedApi, selectedMarketplace]);
  useEffect(
    () => setBrandPage(1),
    [selectedApi, selectedCategory, selectedMarketplace]
  );
  useEffect(
    () => setActionPage(1),
    [selectedApi, selectedCategory, selectedBrand, selectedMarketplace]
  );

  //логика для selected

  const mpParamRef = useRef([]);
  const categoryParamRef = useRef([]);
  const apiParamRef = useRef([]);
  const brandParamRef = useRef([]);

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    mpParamRef.current = params.get('mp_id')?.split(',') || [];
    categoryParamRef.current = params.get('category_id')?.split(',') || [];
    apiParamRef.current = params.get('api_id')?.split(',') || [];
    brandParamRef.current = params.get('brand_id')?.split(',') || [];

    if (mpParamRef.current.length > 0) {
      const mappedMarketplace = mpParamRef.current.map((mpId) =>
        options_prop.find((option) => String(option.value) === mpId)
      );
      setSelectedMarketplace(mappedMarketplace);
    }
  }, [location.search]);

  const fetchFilters = ({
    filterName,
    selectedFilters,
    setFilterData,
    setSelectedFilter,
    page = 1,
    searchParam = '',
    append = false,
  }) => {
    fetchFilterDataForReviews({
      filterName,
      selectedSpace: selectedSpace?.value,
      page,
      searchParam,
      selectedFilters,
      setFilterData,
      setSelectedFilter,
      append,
    });
  };

  const apiFilter = () => {
    fetchFilters({
      filterName: 'fq_api_ids',
      selectedFilters: {
        selected_id: apiParamRef.current,
        marketplace: selectedMarketplace,
      },
      setFilterData: setApiData,
      setSelectedFilter: setSelectedApi,
      page: apiPage,
      searchParam: apiQuery,
      append: apiPage > 1,
    });
  };

  const categoryFilter = () => {
    fetchFilters({
      filterName: 'feedbacks_categories',
      selectedFilters: {
        selected_id: categoryParamRef.current,
        api: selectedApi,
        brand: selectedBrand,
        marketplace: selectedMarketplace,
        dateFrom,
        dateTo,
      },
      setFilterData: setCategoryData,
      setSelectedFilter: setSelectedCategory,
      page: categoryPage,
      searchParam: categoryQuery,
      append: categoryPage > 1,
    });
  };

  const brandFilter = () => {
    fetchFilters({
      filterName: 'feedbacks_brands',
      selectedFilters: {
        selected_id: brandParamRef.current,
        api: selectedApi,
        category: selectedCategory,
        marketplace: selectedMarketplace,
        dateFrom,
        dateTo,
      },
      setFilterData: setBrandData,
      setSelectedFilter: setSelectedBrand,
      page: brandPage,
      searchParam: brandQuery,
      append: brandPage > 1,
    });
  };

  useEffect(() => {
    apiFilter();
  }, [apiPage, apiQuery, pageValue]);

  useEffect(() => {
    categoryFilter();
  }, [categoryPage, categoryQuery, pageValue]);

  useEffect(() => {
    brandFilter();
  }, [brandPage, brandQuery, pageValue]);

  const body_obj = () => {
    let api_id_list;
    let mp_id_list;
    let category_id_list;
    let brand_id_list;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi?.map((el) => el.value);
    }

    if (selectedMarketplace?.length > 0) {
      mp_id_list = selectedMarketplace?.map((el) => el.value);
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }

    const body = JSON.stringify({
      mp_id_list,
      api_id_list,
      category_id_list,
      brand_id_list,
    });
    if (selectedApi?.length > 0) {
      return body;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (selectedApi) {
      body_obj();
    }
  }, [selectedBrand, selectedCategory, selectedApi, selectedMarketplace]);

  return (
    <div className="settings__content">
      <div className="promotion_page">
        <h1 className="title_main">Аномалии</h1>
        <FilterContainer
          onReset={(e) => {
            setSelectedApi([]);
            setSelectedCategory([]);
            setSelectedBrand([]);
          }}
        >
          <DropDownSelector
            options_prop={options_prop}
            state={selectedMarketplace}
            setState={(e) => setSelectedMarketplace(e)}
            placeholder="Маркетплейс"
            defaultValue={[{ label: 'Все', value: 'all' }]}
            multi
            onBlur={() => {
              apiFilter();
              categoryFilter();
              brandFilter();
            }}
          />

          <DropDownSelector
            style={{ maxWidth: '210px', minWidth: '210px' }}
            options_prop={apiData}
            state={selectedApi}
            setState={(e) => setSelectedApi(e)}
            placeholder="Магазин"
            fetchCallback={(e) => setApiPage(e)}
            setInputChange={(e) => setApiQuery(e)}
            defaultValue={[{ label: 'Все', value: 'all' }]}
            multi
            onBlur={() => {
              categoryFilter();
              brandFilter();
              // setIsTableRequest(true);
            }}
          />

          <DropDownSelector
            style={{ maxWidth: '210px', minWidth: '210px' }}
            options_prop={categoryData}
            state={selectedCategory}
            setState={(e) => setSelectedCategory(e)}
            placeholder="Категория"
            fetchCallback={(e) => setCategoryPage(e)}
            setInputChange={(e) => setCategoryQuery(e)}
            defaultValue={[{ label: 'Все', value: 'all' }]}
            multi
            onBlur={() => {
              brandFilter();
              // setIsTableRequest(true);
            }}
          />

          <DropDownSelector
            style={{ maxWidth: '210px', minWidth: '210px' }}
            options_prop={brandData}
            state={selectedBrand}
            setState={(e) => setSelectedBrand(e)}
            placeholder="Бренд"
            fetchCallback={(e) => setBrandPage(e)}
            setInputChange={(e) => setBrandQuery(e)}
            defaultValue={[{ label: 'Все', value: 'all' }]}
            multi
            onBlur={() => {
              // setIsTableRequest(true);
            }}
          />
        </FilterContainer>

        <Tabs
          tabs={tabs}
          pageValue={pageValue}
          setPageValue={(e) => setPageValue(e)}
        />

        <div className="anomalies__charts-group">
          <WrapperLineChart
            url={`${KonstantinMasterHttps}/anomaly/${pageValue}/fewer-reviews-than-usual?plot_number=first`}
            body={body_obj()}
            nameChart="Отзывов меньше обычного"
            dependence={pageValue}
          />

          <WrapperLineChart
            url={`${KonstantinMasterHttps}/anomaly/${pageValue}/fewer-reviews-than-usual?plot_number=second`}
            body={body_obj()}
            nameChart="Отзывов меньше обычного"
            dependence={pageValue}
          />

          <WrapperLineChart
            url={`${KonstantinMasterHttps}/anomaly/${pageValue}/fewer-reviews-than-usual?plot_number=third`}
            body={body_obj()}
            nameChart="Отзывов меньше обычного"
            dependence={pageValue}
          />
        </div>

        <div className="anomalies__charts-group">
          <WrapperLineChart
            url={`${KonstantinMasterHttps}/anomaly/${pageValue}/more-reviews-than-usual?plot_number=first`}
            body={body_obj()}
            nameChart="Отзывов больше обычного"
            dependence={pageValue}
          />

          <WrapperLineChart
            url={`${KonstantinMasterHttps}/anomaly/${pageValue}/more-reviews-than-usual?plot_number=second`}
            body={body_obj()}
            nameChart="Отзывов больше обычного"
            dependence={pageValue}
          />

          <WrapperLineChart
            url={`${KonstantinMasterHttps}/anomaly/${pageValue}/more-reviews-than-usual?plot_number=third`}
            body={body_obj()}
            nameChart="Отзывов больше обычного"
            dependence={pageValue}
          />
        </div>

        {pageValue != 'by-categorization' && (
          <div className="anomalies__charts-group">
            <WrapperLineChart
              url={`${KonstantinMasterHttps}/anomaly/${pageValue}/reviews-with-low-ratings?plot_number=first`}
              body={body_obj()}
              nameChart="Отзывов с низкой оценкой (1-3) больше обычного"
              dependence={pageValue}
            />

            <WrapperLineChart
              url={`${KonstantinMasterHttps}/anomaly/${pageValue}/reviews-with-low-ratings?plot_number=second`}
              body={body_obj()}
              nameChart="Отзывов с низкой оценкой (1-3) больше обычного"
              dependence={pageValue}
            />

            <WrapperLineChart
              url={`${KonstantinMasterHttps}/anomaly/${pageValue}/reviews-with-low-ratings?plot_number=third`}
              body={body_obj()}
              nameChart="Отзывов с низкой оценкой (1-3) больше обычного"
              dependence={pageValue}
            />
          </div>
        )}

        {pageValue != 'by-categorization' && (
          <div className="anomalies__charts-group">
            <WrapperLineChart
              url={`${KonstantinMasterHttps}/anomaly/${pageValue}/reviews-with-more-text-than-usual?plot_number=first`}
              body={body_obj()}
              nameChart="Отзывов с текстом больше обычного"
              dependence={pageValue}
            />

            <WrapperLineChart
              url={`${KonstantinMasterHttps}/anomaly/${pageValue}/reviews-with-more-text-than-usual?plot_number=second`}
              body={body_obj()}
              nameChart="Отзывов с текстом больше обычного"
              dependence={pageValue}
            />

            <WrapperLineChart
              url={`${KonstantinMasterHttps}/anomaly/${pageValue}/reviews-with-more-text-than-usual?plot_number=third`}
              body={body_obj()}
              nameChart="Отзывов с текстом больше обычного"
              dependence={pageValue}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { Anomalies };
