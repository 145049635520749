import { useEffect, useState } from 'react';
import { ReactComponent as GrabZone } from '../img/bx-grid-vertical.svg';
import { ReactComponent as PlusGreen } from '../img/plus_green.svg';
import { ButtonBasic } from '@lk-gtcom/ecomlab-components';
import time_icon from '../img/time_icon.svg';
import no_task from '../img/no-task.svg';
import close_icon from '../img/close_icon(members).svg';
import { TaskItem } from '../../../components/TaskItem/TaskItem';
import { RavshanHttps, RavshanHttp } from '../../../fetchUrls';
import { MoonLoader } from 'react-spinners';
import finally_icon from './img/finally.svg'
import { useRef } from 'react';
import { Sceleton } from './Sceleton';
import { SceletonTask } from './SceletonTask';
import './ColumnTask.scss';
import { search } from '../../../common/svg_img';

const ColumnTask = ({
  typeId,
  isShowAuthor,
  dragStartColumn,
  dragOverHandler,
  setIsOver,
  dropCardHandler,
  positionElement,
  projectName,
  col,
  setIsEditCol,
  isEditCol,
  setIsBlockKey,
  changeTaskListName,
  columnNameIsUpdating,
  setCurrentColumn,
  setIsModalStatus,
  taskId,
  setRemovableTaskList,
  setIsModalDeleteCol,
  openModal,
  setIsModal,
  fetchProjectToRedirect,
  author,
  email,
  actual,
  refHeader,
  task_permissible,
  // tasksByStatus,
  // fetchProjects,
  openTask,
  fetchDeleteTask,
  isOpenActiveMenu,
  dragStartHandler,
  setIsOpenActiveMenu,
  selectedExecutor,
  selectedAuthor,
  selectedCoExecutor,
  selectedStatus,
  body,
  headers,
  actualId,
  setActualId,
  updateId,
  setUpdateId,
  setIsUpdateTotal,
  isUpdateTotal,
  refsTotal,
  setSelectedColumn,
  fetchAllColumns,
  refContainer,
  setGeneralTotal,
  setCountCol,
  searchValue,
  columns,
  isVisibleCheckbox,
  setListPrint,
  listPrint
}) => {

  const [taskList, setTaskList] = useState([]);
  const taskRef = useRef();
  const [taskPageHeight, setTaskPageHeight] = useState(0);
  const [taskIsSearchLastPage, setTaskIsSearchLastPage] = useState(false);
  const [taskPageScrollTop, setTaskPageScrollTop] = useState(-1);
  const [taskPage, setTaskPage] = useState(1);
  const [taskTotal, setTaskTotal] = useState(0);
  const [loading, setLoading] = useState(false)
  const [load, setLoad] = useState(false)
  const [draggingTaskId, setDraggingTaskId] = useState(null);

  const onScroll = (listRef, setPageHeight, setScrollTop) => {
    const { scrollTop, scrollHeight, clientHeight } = listRef.current;
    const bottomHeight = scrollHeight - clientHeight;
    if (bottomHeight) {
      setPageHeight(bottomHeight);
    }
    setScrollTop(scrollTop);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      const currentPageHeight = taskPageHeight;
      if (
        !taskIsSearchLastPage &&
        Math.ceil(taskPageScrollTop) >= currentPageHeight &&
        taskList?.length > 0
      ) {
        setTaskPage((prev) => prev + 1);
        return;
      }
    }, 500);
    return () => clearTimeout(timeout);
  }, [taskPageScrollTop, taskPageHeight, taskIsSearchLastPage]);

  useEffect(() => {
    setTaskPage(1);
  }, []);

  useEffect(() => {
    if (taskPage > 1) {
      setLoading(true)
    }
  }, [taskPage]);

  const fetchProjects = (type, abortController = new AbortController()) => {
    if (!taskId) return;
    setLoad(true)

    // setLoading(true)
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/v3/tasks/get_event_by_column`;
    } else {
      url = `${RavshanHttp}/api/v3/tasks/get_event_by_column`;
    }

    const executor =
      selectedExecutor?.length > 0 ? { executor: selectedExecutor } : {};
    const author = selectedAuthor?.length > 0 ? { author: selectedAuthor } : {};
    const co_executor =
      selectedCoExecutor?.length > 0 ? { co_executor: selectedCoExecutor } : {};
    const taskType =
      selectedStatus?.length > 0
        ? { task_type: selectedStatus?.map(({ value }) => value) }
        : {};

    const bodyy = JSON.stringify({
      project_id: +taskId,
      // ...executor,
      ...co_executor,
      ...author,
      ...taskType,
      ...body,
      column_id: type,
      limit: 20,
      page: taskPage,
    });

    // {
    //     "task_type": [
    //       "string"
    //     ],
    //     "task_board_id": 0,

    fetch(url, {
      body: bodyy,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('bad events response');
        }
      })
      .then((json) => {
        const { total, events } = json;

        if (taskPage > 1) {
          setTaskList((prev) => [...prev, ...events]);
        } else {
          setTaskList(events);
        }
        setTaskTotal(total);
        setGeneralTotal(prev => prev + (total ? total : 0))
        setCountCol(prev => prev + 1)
        setTaskIsSearchLastPage(total < taskPage * 20);
        setLoad(false)
        // if (events?.length < 1 && !body?.search) {
        //   fetchAllColumns()
        // };
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false)
        setLoad(false);
      });
  };

  useEffect(() => {
    setIsUpdateTotal(!isUpdateTotal)
  }, [taskTotal]);

  useEffect(() => {
    //  if (typeof col?.typeId == 'number') {
    fetchProjects(col?.typeId);
    // }
  }, []);


  useEffect(() => {
    if (typeof col?.typeId == 'number') {
      fetchProjects(col?.typeId);
    }
  }, [
    taskPage,
    searchValue,
    body?.order_param_direction,
    body?.order_param,
    body?.unviewed,
    body?.on_trash,
    selectedExecutor
  ]);

  useEffect(() => {
    if (updateId == col?.typeId) {
      setTimeout(() => {
        fetchProjects(updateId)
        setUpdateId(null)
      }, 700)
    }
  }, [updateId]);

  useEffect(() => {
    if (actualId == col?.typeId) {
      setTimeout(() => {
        fetchProjects(actualId)
        setActualId(null)
      }, 700)
    }
  }, [actualId]);

  // useEffect(() => {
  //   fetchAllColumns()
  // }, [taskList]);

  console.log()

  return (
    <div
      column='true'
      draggable={author?.login == email}
      className='col-task'
      onDragStart={e => {
        e.stopPropagation()
        dragStartColumn(e, col)
      }}
      onDragOver={e => dragOverHandler(e)}
      onDrop={e => {
        if (actual) {
          setActualId(actual?.taskType?.id)
          setUpdateId(col?.typeId)
          dropCardHandler(e, col)
          setIsOver(false)
        }
      }}
    >
      <div className='col-task__header' tabIndex={5}
      // onClick={(e) => navigate(`../task-list/${col.typeId}`)}
      // сделать переход по клику
      >
        <div
          style={{
            // transform: `translateY(${positionElement}px)`,
            cursor: projectName == 'По сотрудникам' ? 'pointer' : ''
          }}
          ref={refHeader} className='col-task__white'
          onClick={(e) => {
            if (projectName == 'По сотрудникам') {
              fetchProjectToRedirect(col.typeId)
            }
          }}>
          <div className='col-task__header-top'>
            {author?.login == email && <GrabZone
              className='col-task__drop-icon' />}
            {col?.done && <img src={finally_icon} />}
            {isEditCol !== col ?
              <p
                className='col-task__title-col'
                onClick={(e) => {
                  setIsEditCol(col)
                }}
              >{col.typeName}</p>
              :
              <textarea
                className='col-task__input-col'
                placeholder='Название'
                type='text'
                onFocus={(e) => setIsBlockKey(true)}
                defaultValue={col.typeName}
                disabled={columnNameIsUpdating}
                onBlur={e => {
                  setIsBlockKey(false)
                  changeTaskListName(col, e.target.value)
                  refContainer?.current?.focus();
                }}
              />
            }

            <div ref={(el) => (refsTotal.current[col?.typeId] = el)} className="col-task__count">{taskTotal || 0}</div>

            {+taskId !== -1 && (
              <button
                className="btn-state"
                //style={{ marginLeft: 'auto' }}
                onClick={(e) => {
                  setCurrentColumn(col.typeId);
                  setIsModalStatus(true);
                }}
              >
                <img src={time_icon} />
              </button>
            )}

            {(+taskId !== -1 && !col?.done) &&
              author?.login == email &&
              task_permissible.includes(col.typeName) == false && (
                <button
                  className="btn-state"
                  onClick={() => {
                    setRemovableTaskList(col);
                    setIsModalDeleteCol(true);
                  }}
                >
                  <img src={close_icon} />
                </button>
              )}
          </div>
        </div>
      </div>
      <div className="col-task__task-box-btn">
        {
          <div className="btn-box">
            <ButtonBasic
              text={<PlusGreen style={{ display: 'inline' }} />}
              width="100%"
              whiteGreen
              size="32px"
              onClick={() => {
                openModal(col);
              }}
            />
          </div>
        }
        <div
          className="col-task__task-box"
          ref={taskRef}
          onScroll={(e) =>
            onScroll(taskRef, setTaskPageHeight, setTaskPageScrollTop)
          }
        >
          {((taskList?.length < 1 || !taskList) && !load) && (
            <div className="no-tasks" onClick={(e) => openModal(col)}>
              <img src={no_task} />
              <p className="text">Добавьте задачу</p>
            </div>
          )}

          {(load && !loading) ?
            <Sceleton />
            :
            taskList
              ?.map(
                ({
                  event_name,
                  priority,
                  end_time,
                  executor,
                  co_executor,
                  event_id,
                  task_type,
                  author,
                  link,
                  count_unread_comments
                }) => {
                  return {
                    eventName: event_name,
                    priority: priority,
                    endTime: end_time,
                    executor: executor,
                    coExecutor: co_executor,
                    eventId: event_id,
                    taskType: task_type,
                    author: author,
                    link: link,
                    count_unread_comments: count_unread_comments
                  };
                }
              )
              ?.map((task, ind) => {
                const {
                  eventName,
                  priority,
                  endTime,
                  executor,
                  coExecutor,
                  eventId,
                  taskType,
                  author,
                  link,
                  count_unread_comments
                } = task;
                const _taskId = task?.taskId;

                return (
                  <div
                    key={JSON.stringify(task) + ind}
                    draggable={true}
                    onDragStart={(e) => {
                      e.stopPropagation();
                      setDraggingTaskId(task.eventId);
                      dragStartHandler(e, task);
                    }}
                    onDragEnd={() => setDraggingTaskId(null)}
                    onDragOver={(e) => dragOverHandler(e)}
                    onClick={(e) => {
                      let el = e.target;
                      setSelectedColumn(col)
                      if (
                        !el.closest('.context-more') &&
                        !el.closest('.blackout')
                      ) {
                        openTask(e, eventId, _taskId, taskId);
                      }

                    }}
                    className={
                      draggingTaskId === task.eventId ? 'dragging-task' : ''
                    }
                  >
                    <TaskItem
                      isVisibleCheckbox={isVisibleCheckbox} //чекбоксы для печати
                      isShowAuthor={isShowAuthor}
                      link={link}
                      author={author}
                      projectName={projectName}
                      title={eventName}
                      executor={executor}
                      coExecutor={coExecutor}
                      time={new Date(endTime).toLocaleDateString('ru-RU', {
                        day: '2-digit',
                        month: 'numeric',
                        year: '2-digit',
                      })}
                      priority={priority}
                      openTask={(e) => openTask(e)}
                      isOpenActiveMenu={isOpenActiveMenu}
                      setIsOpenActiveMenu={(e) => setIsOpenActiveMenu(e)}
                      fetchProjects={(e) => fetchProjects(col?.typeId)}
                      id={eventId}
                      status={taskType?.name}
                      taskId={taskId}
                      count_unread_comments={count_unread_comments}
                      deleteTask={e => {
                        fetchDeleteTask(e)
                        setUpdateId(col?.typeId)
                      }}
                      setListPrint={setListPrint}
                      listPrint={listPrint}
                    />
                  </div>
                );
              })
          }

          {(loading) && <div className="col-task__loader" >
            <SceletonTask />
          </div>}
        </div>
      </div>
    </div>
  );
};

export { ColumnTask };
